/** @module Attachments */

// Utils
import { humanFileSize } from './numbers';
import { uniq } from 'lodash';

const S3_ATTACHMENTS_KEY = 'fp_attachments_s3';
const COUCH_ATTACHMENTS_KEY = '_attachments';



/**
 * Gets names of attachments this document has
 *
 * @param {object} doc document
 * @returns {string[]}
 */
export function getAttachmentNames(doc) {
    return uniq(Object.keys(doc[COUCH_ATTACHMENTS_KEY] || {}).concat(Object.keys(doc[S3_ATTACHMENTS_KEY] || {})));
}

/**
 * Gets attachment object with given name
 *
 * @param {object} doc document
 * @param {string} name attachment name
 * @returns {object|null}
 */
export function getAttachmentMetadata(doc, name) {
    if (hasS3Attachment(doc, name)) {
        return doc[S3_ATTACHMENTS_KEY][name];
    } else if (hasCouchAttachment(doc, name)) {
        return doc[COUCH_ATTACHMENTS_KEY][name];
    } else {
        return null;
    }
}

/**
 * Test if this document has an attachment object with given name stored in S3
 *
 * @param {object} doc document
 * @param {string} name attachment name
 * @returns {Boolean}
 */
export function hasS3Attachment(doc, name) {
    return Boolean(doc[S3_ATTACHMENTS_KEY] && doc[S3_ATTACHMENTS_KEY][name]);
}

/**
 * Test if this document has an attachment object with given name stored in Couch
 *
 * @param {object} doc document
 * @param {string} name attachment name
 * @returns {Boolean}
 */
export function hasCouchAttachment(doc, name) {
    return Boolean(doc[COUCH_ATTACHMENTS_KEY] && doc[COUCH_ATTACHMENTS_KEY][name]);
}

/**
 * Test if this document has an attachment object with given name
 *
 * @param {object} doc document
 * @param {string} name attachment name
 * @returns {Boolean}
 */
export function hasAttachment(doc, name) {
    return hasS3Attachment(doc, name) || hasCouchAttachment(doc, name);
}

/**
 * Checks if this document has any attachments
 * @param {object} doc document
 * @returns {boolean}
 */
export function hasAnyAttachments(doc) {
    return getAttachmentNames(doc).length > 0;
}

/**
 * Gets the first relevant attachment
 *
 * @param {object} doc the asset to get the file size label from
 * @param {string} [attachmentName] an optional attachment name. If not provided the attachment used will be the first found.
 *
 * @returns {object|undefined} the first relevant attachment
 */
export function getAttachmentFromDoc(doc, attachmentName) {
    const attachments = doc.fp_attachments_s3;
    let attachment;
    if (attachmentName) {
        attachment = attachments[attachmentName] || {};
    } else {
        attachment = Object.values(attachments || {})[0];
    }

    return attachment;
}

/**
 * Gets the file size label
 *
 * @param {object} doc the asset to get the file size label from
 * @param {object} $i18n the i18n framework runtime
 * @param {string} [attachmentName] an optional attachment name. If not provided the attachment used will be the first found.
 *
 * @returns {string} the length or the current attachment status
 */
export function getSize(doc, $i18n, attachmentName) {
    const attachment = getAttachmentFromDoc(doc, attachmentName);

    if (attachment?.length) {
        return humanFileSize(attachment.length);
    } else if (doc.upload_error) {
        return $i18n.t('general.uploads.failed').toString();
    } else {
        return $i18n.t('general.uploads.processing').toString();
    }
}

/**
 * Returns a human readable mime type
 *
 * @param {object} doc the document to get the mime from
 * @param {string} [attachmentName] an optional attachment name. If not provided the attachment used will be the first found.
 *
 * @returns {string} human readable mime type
 */
export function getHumanReadableMime(doc, attachmentName) {
    const attachment = getAttachmentFromDoc(doc, attachmentName);
    const type = attachment?.content_type || doc.original_content_type;
    if (type) {
        const [family, ext] = type.split('/');
        return `${ext.toLocaleUpperCase()} ${family}`;
    }

    return '-';
}
