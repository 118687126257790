import { detect } from 'detect-browser';
import { parameterize, untokenize } from 'libs/utils/string';
import { addDocumentClass } from 'libs/utils/dom';

const browser = detect();

/**
 * This directive checks the browser version and set up a class on the document.
 *
 * @example
 * <div v-browser>...</div>
 *
 * @category ui.utils.directive
 * @module Browsers
 */
export default {
    bind: () => {
        const name = parameterize(browser.name);
        const classes = [
            name,
            `${name}-${parameterize(browser.os)}`,
            `${name}-${untokenize(browser.version, '-')}`
        ];

        // TODO: move this to the `el` argument once we move
        // to a vue.js centric platform.
        addDocumentClass(classes.join(' '));
    }
};
