/** @module OS */

/**
 * Checks whether the Operating System is MacOS or not.
 *
 * It performs this check by parsing the navigator's platform string.
 *
 * @see https://www.w3schools.com/jsref/prop_nav_platform.asp
 *
 * @returns {boolean} whether the current OS is MacOS
 */
export function isMac(platform = navigator.platform) {
    return platform.startsWith('Mac');
}
