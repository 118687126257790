// Utils
import { get } from 'lodash';

// Constants
const DEFAULT_SCOPE = 'unknown';

/**
 * This plugins provide a facility to track custom events that
 * happens on the platform.
 *
 * ```
 * vm.$track('some_event', {some: 'object'})
 * ```
 *
 * This plugin also provides a directive that sets an event listener to the element it is used on, that
 * when triggered will track the desired event to the analytic service.
 *
 * The most simple use case is:
 * ```
 * <div v-track="some_event">...</div>
 * ```
 * This will track "some_event" on the analytic service.
 *
 *
 * Another use case is when when you want to define a scope on your event:
 * ```
 * <div v-track:my_scope="some_event">...</div>
 * ```
 * This will track "my_scope" with `{name: "some_event"}` as payload on the analytic service.
 *
 *
 * A most complex use case is when you want to track an object
 * ```
 * <div v-track:my_scope="{info1: 'a', info2: 'b'}">...</div>
 * ```
 * This will track "my_scope" with `{info1: 'a', info2: 'b'}` as payload on the analytic service.
 *
 * NOTE: in case of complex object without scope, default `unknown` will be used.
 *
 * @global
 * @exports $track
 */
export default {

    /**
     * Tracks an event to analytics service.
     *
     * @param {String} event the occured event to track.
     * @param {Object} [properties] additional properties to append on the tracker.
     *
     * @returns {Promise<Object>} analytics tracking response or undefined.
     */
    track(event, properties) {
        const service = get(window, 'BSTG.services.track');

        if (!service) {
            console.warn('[Tracker] Tracking service has not been initialized yet.');
            return;
        }

        return service.track(event, properties);
    },

    install(Vue) {

        Vue.prototype.$track = this.track;

        Vue.directive('track', {
            bind: (el, binding, vm) => {
                const modifiers = Object.assign(binding.modifiers, { 'click': true });

                for (const onEvent of Object.keys(modifiers)) {
                    el.addEventListener(onEvent, () => {
                        let { arg, value, expression } = binding;

                        // This can happen when:
                        // `v-track:scope="simple_event"`
                        if (!value) {
                            value = expression;
                        }

                        // This can happen when:
                        // `v-track="simple_track"`
                        if (!arg) {
                            arg = DEFAULT_SCOPE;
                            if (typeof value === 'string') {
                                arg = value;
                                value = undefined;
                            }
                        }

                        // We just transform value into a basic object
                        // because the tracking service needs an object.
                        if (typeof value === 'string') {
                            value = { name: value };
                        }

                        vm.context.$track(arg, value);
                    });
                }
            }

        });

        // Mount track plugin when registering a new component.
        Vue.mixin({
            beforeCreate() {
                this.$track = this.$track.bind(this);
            }
        });
    }
};
