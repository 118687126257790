export default {
    props: {
        theme: {
            type: Object,
            required: true
        }
    },

    data() {
        const props = Object.keys(this.theme);
        props.push(...Object.keys(this.$services.theme.V3_THEMES['light']));

        const data = {};
        for (const prop of props) {
            data[prop] = null;
        }

        return data;
    },

    computed: {
        isV3() {
            return ['light', 'dark'].includes(this.theme.appearance);
        },

        brandColor() {
            return this.isV3 ? this.color13 : this.color1;
        }
    },

    watch: {
        theme: {
            immediate: true,
            handler() {
                this.initColors();
            }
        }
    },

    methods: {
        initColors() {
            for (const prop of Object.keys(this.theme)) {
                this[prop] = this.theme[prop];
            }

            const mapping = this.isV3 ? this.theme.appearance : 'light';
            const extraProps = this.$services.theme.V3_THEMES[mapping];
            for (const key of Object.keys(extraProps)) {
                this[key] = this.isV3 ? extraProps[key] : undefined;
            }
        }
    }
};
