// Utils
import { clone } from 'lodash';

import { addDocumentClass, removeDocumentClass } from 'libs/utils/dom';
import { getTopLevelDomain } from 'libs/utils/url';

// Constants
import * as COLORS from 'libs/utils/global-colors';

const MODES = ['light', 'dark'];

/**
 * Retrieves the current theme from local storage
 * or based on the user's preferred color scheme.
 *
 * @returns {string} The current theme ('dark' or 'light').
 */
export function getCurrentTheme($cookies) {
    let storedTheme = $cookies.get('theme');
    if (!storedTheme) {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
        storedTheme = prefersDarkMode.matches ? 'dark' : 'light';
    }

    return storedTheme;
}

/**
 * Sets the current theme in the local storage.
 *
 * @param {string} theme - The theme to set.
 */
export function setCurrentTheme($cookies, theme) {
    $cookies.set('theme', theme, null, '/', getTopLevelDomain());
}

/**
 * Applies the current theme to the document by adding
 * the appropriate CSS class.
 *
 * @param {string} [forceTheme] - The theme to force.
 */
export function applyTheme($cookies, forceTheme = null) {
    const currentMode = forceTheme || getCurrentTheme($cookies);
    MODES.filter(m => m !== currentMode).forEach(mode => {
        removeDocumentClass(`theme-${mode}`);
    });

    console.debug('[UI Theme] Applying theme:', currentMode);

    addDocumentClass(`theme-${currentMode}`);
}

/**
 * Returns a clone of the COLORS array.
 *
 * @returns {Array} A clone of the COLORS array.
 */
export function getAllColors() {
    return clone(COLORS);
}

/**
 * Returns a map of theme colors based on the provided theme.
 *
 * @param {string} theme - The theme name.
 *
 * @returns {Object} - A map of theme colors.
 */
export function getThemeColorsAsMap(theme) {
    const vars = {};
    for (const [key, color] of Object.entries(getAllColors())) {
        let varName = key.replace('COLOR_', '').toLowerCase();
        if (varName.includes(theme)) {
            varName = varName
                .replace(`${theme}_`, '')
                .replace(/_/g, '-');
            vars[varName] = color;
        }
    }

    return vars;
}

/**
 * Returns the theme colors as CSS custom properties.
 *
 * @param {object} theme - The theme object.
 *
 * @returns {string} - The theme colors as CSS custom properties.
 */
export function getColorsAsCSSProps(theme) {
    const vars = getThemeColorsAsMap(theme);
    return Object.keys(vars).map(key => `--${key}: ${vars[key]};`).join('');
}
