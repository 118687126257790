/** @module Charts */

// Utils
import { get, merge } from 'lodash';
import * as COLORS from 'libs/utils/global-colors';
import { imagePath } from 'libs/utils/assets';
import { dateToFormat } from 'libs/utils/time';

// Charts
export * from './charts/area-chart';
export * from './charts/bar-chart';
export * from './charts/line-chart';
export * from './charts/multiline-chart';
export * from './charts/stacked-chart';

const LINE_COLORS = {
    'goal': COLORS.COLOR_LIGHT_SUCCESS_300,
    'verticals': '#E3E6E8', // TODO: build color shades
    'created_users': COLORS.COLOR_LIGHT_ALT_1_300,
    'self_registered_users': COLORS.COLOR_LIGHT_ALERT_300,
    'activated_users': COLORS.COLOR_LIGHT_WARNING_300,
    'active_users': COLORS.COLOR_LIGHT_PRIMARY_300,
    'accepted_rsvp': COLORS.COLOR_LIGHT_ALT_2_300,
};

const ACTIVITY_COLORS = {
    'total_users': COLORS.COLOR_LIGHT_ALT_1_300,
    'activated_users': COLORS.COLOR_LIGHT_WARNING_300,
    'active_users': COLORS.COLOR_LIGHT_PRIMARY_300,
};

const TURNOUTS_COLORS = {
    'activated_users': COLORS.COLOR_LIGHT_WARNING_300,
    'active_users': COLORS.COLOR_LIGHT_PRIMARY_300,
};

const REGISTRATIONS_METRICS = {
    'self_registered_users': { color: COLORS.COLOR_LIGHT_ALERT_300, style: 'warn', description: 'self_registered_users' },
    'self_unregistered_users': { color: COLORS.COLOR_LIGHT_ALERT_300, description: 'self_registered_users' },
    'rsvp_accepted': { color: COLORS.COLOR_LIGHT_ALT_2_300, style: 'alt-2', description: 'rsvp_users' },
    'rsvp_declined': { color: COLORS.COLOR_LIGHT_ALT_2_300, style: 'alt-2', description: 'rsvp_users' },
    'rsvp_pending': { color: COLORS.COLOR_LIGHT_ALT_2_300, style: 'alt-2', description: 'rsvp_users' },
    'rsvp_users': { color: COLORS.COLOR_LIGHT_ALT_2_300, hidden: true } // Used for legend purpose only
};

/** @const {ChartConfig[]} AVAILABLE_CHARTS a list of available charts default configuration */
const AVAILABLE_CHARTS = {
    activations: {
        name: 'activations',
        metrics: ['created_users', 'self_registered_users', 'accepted_rsvp', 'activated_users', 'active_users', 'mailing_count'],
        type: 'line',
        icon: 'gauge',
        xMapper: d => new Date(d.date),
        yMapper: d => get(d, 'count') ? d.count : 0,
        zMapper: d => d.metric,
        verticalMarkers: ['mailing_count'],
        lineColor: metric => LINE_COLORS[metric],
        tooltips: true,
        icons: {
            mailing_count: imagePath('charts/mail.svg')
        }
    },
    turnouts: {
        stages: ['present', 'past'],
        name: 'turnouts',
        metrics: ['activated_users', 'active_users'],
        type: 'stacked',
        icon: 'magnet',
        xMapper: d => dateToFormat(d.date, 'ddd DD'),
        yMapper: d => d.count,
        zMapper: d => d.metric,
        /** @see https://spotme.atlassian.net/browse/EP-11591 */
        // jMapper: calculateTurnoutPercent,
        title: (d, index, data) => {
            const percent = calculateTurnoutPercent(d, index, data);
            return percent ? `${percent}%` : '';
        },
        mainMetric: 'activated_users',
        tooltips: (d, index, data, t) => {
            const metrics = data.filter(p => p.date === d.date);
            let title = dateToFormat(d.date, 'ddd DD');

            const p = metrics.find(p => p.metric === 'active_users');
            const percent = calculateTurnoutPercent(p, index, data);
            if (percent) {
                title += ` - ${percent}%`;
            }

            for (const metric of metrics) {
                const m = t(`analytics.metrics.${metric.metric}.title`);
                title += `\n${m}: |${metric.count}`;
            }

            return title;
        },
        colors: Object.values(TURNOUTS_COLORS),
        lineColor: metric => TURNOUTS_COLORS[metric]
    },
    active_users: {
        name: 'active_users',
        type: 'activity',
        icon: 'users',
        initialData: [], // needs to be defined to prevent unnecessary request
        metrics: ['total_users', 'activated_users', 'active_users'],
        lineColor: metric => ACTIVITY_COLORS[metric],
    },
    registrations: {
        name: 'registrations',
        type: 'registrations',
        icon: 'list',
        metrics: Object.keys(REGISTRATIONS_METRICS).filter(k => !REGISTRATIONS_METRICS[k].hidden),
        lineColor: metric => REGISTRATIONS_METRICS[metric].color,
        accentStyle: metric => REGISTRATIONS_METRICS[metric].style,
        description: metric => REGISTRATIONS_METRICS[metric].description,
    },
};

/**
 * This private method calculates the percentage of the given datapoint against
 * the main turnout metric (activated_users).
 *
 * @param {object} d the datapoint to calculate the percent for
 * @param {object[]} data the set of datapoints to get the main metric from
 *
 * @returns {number} the percentage of the datapoint
 *
 * @private
 */
function calculateTurnoutPercent(d, i, data) {
    if (d.metric === 'activated_users') {
        return;
    }

    const ref = data.find(p => p.date === d.date && p.metric === 'activated_users');
    if (!ref || !ref.count) {
        return;
    }

    return Math.round((d.count / ref.count) * 100);
}

/**
 * Given a chart name, this method returns the corresponding configuration
 * if found, an empty object otherwise
 *
 * @param {string} name the name of a chart configuration
 * @param {object} [overwrites={}] a set of ChartConfig overwrites or chart options
 *
 * @returns {ChartConfig|{}} the chart configuration
 */
export function getChartConfigByName(name, overwrites = {}) {
    const config = AVAILABLE_CHARTS[name] || {};
    return merge(config, overwrites);
}
