export function extractLanguageCode(fileName) {
    if (!fileName) {
        return null;
    }
    const lastUnderscoreIndex = fileName.lastIndexOf('_');
    const vttIndex = fileName.lastIndexOf('.vtt');

    if (lastUnderscoreIndex !== -1 && vttIndex !== -1 && lastUnderscoreIndex < vttIndex) {
        return fileName.substring(lastUnderscoreIndex + 1, vttIndex);
    }
    return null;
}

