// Constants
import { VALID_URL_REGEX, VALID_EMAIL_REGEX } from 'libs/utils/constants';

export function register(extend, i18n) {
    // Validates urls
    extend('url', {
        message: field => i18n.t('validations.url', [field]).toString(),
        validate: value => VALID_URL_REGEX.test(value)
    });

    // Validates secure schemes
    extend('secure_scheme', {
        message: field => i18n.t('validations.secure_scheme', [field]).toString(),
        validate: value => ['https', 'ftps', 'ssh', 'sftp'].some(p => (value || '').startsWith(p))
    });

    // Validate sets of emails
    extend('emails', {
        message: field => i18n.t('validations.email_set', [field]).toString(),
        validate: value => (value || []).every(v => VALID_EMAIL_REGEX.test(v))
    });
}

