import { isFunction } from 'lodash';

export class EventEmitter {
    constructor() {
        this._eventListeners = {};
    }

    /**
     * Adds a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     * @return {Function} Function to unsubscribe from the event. Of shape: () => Void
     */
    addEventListener(eventType, listener) {
        if (!isFunction(listener)) {
            return;
        }

        this._eventListeners[eventType] = this._eventListeners[eventType] || [];
        this._eventListeners[eventType].push(listener);
        return () => this.removeEventListener(eventType, listener);
    }

    /**
     * Removes a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     */
    removeEventListener(eventType, listener) {
        if (this._eventListeners[eventType]) {
            this._eventListeners[eventType] = this._eventListeners[eventType].filter(l => l !== listener);
        }
    }

    /**
     * Calls the event unsubscribe functions
     *
     * @param  {Function[]} unsubscribers Event unsubscribe functions. Of shape: () => Void
     */
    unsubscribe(unsubscribers) {
        for (const unsubscribe of unsubscribers) {
            if (isFunction(unsubscribe)) {
                unsubscribe();
            }
        }
    }

    /**
     * Emits an event to all its listeners
     *
     * @param {String} eventType Name of the event
     * @param {any} [payload] What to send to the listeners alongside the event
     */
    emit(eventType, payload) {
        const listeners = this._eventListeners[eventType] || [];
        listeners.forEach(listener => setTimeout(() => listener(payload)));
    }
}
