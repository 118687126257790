/**
 * Vue plugin for routing that uses our old angular routing.
 * Tries to replicate API of vue-router where possible.
 *
 * @global
 * @exports $router
 *
 * @see {@link https://router.vuejs.org/guide/essentials/navigation.html}
 */
const getAngularRouter = (routeParams, ngRootScope, $location) => ({

    /**
     * Redirect to route using fiven parameters
     *
     * @param {String} path route to redirect to ie. `/events/:eid`
     * @param {Object} [params=routeParams] replacement parameters for route
     * @param {Object} [searchParams={}] query parameters
     * @param {Boolean} [force=false] forces full reload of the page
     */
    push(path = '', params = routeParams, searchParams = {}, force = false) {

        for (const param of Object.keys(params)) {
            path = path.replace(`:${param}`, params[param]);
        }

        console.log('[AngularRouter (Vue)] Navigating', path, searchParams);

        // We force the update of the search parameter here because it doesn't get
        // updated by angular runtime.
        $location.search(searchParams);

        if (force) {
            return ngRootScope.navigateHard(path);
        }

        ngRootScope.navigate(path);
        ngRootScope.$applyAsync();
    },

    /**
     * Same as push, but it causes the browser to navigate to the url
     *
     * @param {String} path route to redirect to ie. `/events/:eid`
     * @param {Object} [params=routeParams] replacement parameters for route
     * @param {Object} [searchParams={}] query parameters
     */
    forcePush(path = '', params = routeParams, searchParams = {}) {
        return this.push(path, params, searchParams, true);
    },

    /**
     * Hard redirect to given url
     *
     * @param {String} url
     * @param {boolean} [newTab=false] Whether to open the url in a new tab
     */
    redirect(url, newTab = false) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    },

    /**
     * Sets query parameters for current route
     *
     * @param {Object} params query parameters to set
     */
    setSearchParams(params) {
        $location.search(params);
        ngRootScope.$applyAsync();
    },

    /**
     * Gets query parameters from current route
     *
     * @returns {Object} query parameters
     */
    getSearchParams() {
        return $location.search();
    },

    /**
     * Get the angular router route parameters
     *
     * @returns {Object} the router parameters
     */
    getRouteParams() {
        return routeParams;
    },

    /**
     * Hack: stub for dev tools
     */
    afterEach() { }
});


export default {
    install(Vue, { routeParams, ngRootScope, $location }) {
        Vue.prototype.$router = getAngularRouter(routeParams, ngRootScope, $location);
    }
};
