// Constants
import { MEDIA_MAX_FILE_SIZE_IN_BYTES } from 'libs/services/media';

export default {
    props: {
        scope: {
            type: String,
            default: 'global'
        },

        context: {
            type: Object,
            default: () => ({})
        },

        acceptedMimes: {
            type: Array,
            default: null
        },

        supportedMimes: {
            type: Array,
            default: null
        },

        fpType: {
            type: String,
            default: ''
        },

        assetId: {
            type: String,
            default: ''
        },

        modalTitle: {
            type: String,
            default: ''
        },

        modalSubtitle: {
            type: String,
            default: ''
        },

        maxSize: {
            type: Number,
            default: MEDIA_MAX_FILE_SIZE_IN_BYTES
        },

        attachmentNamesGenerator: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            _listener: null
        };
    },

    mounted() {
        this._listener = ({ detail }) => {
            console.info('[UploadButton] Event received', detail);
            if (this.scope === detail.scope) {
                this.$emit('uploaded', { context: this.context, ...detail });
            }
        };

        window.addEventListener('uploader-done', this._listener);
    },

    beforeDestroy() {
        if (this._listener) {
            window.removeEventListener('uploader-done', this._listener);
        }
    },

    methods: {
        launchUploader() {
            const { scope, context, acceptedMimes, supportedMimes, modalTitle, modalSubtitle, fpType, maxSize, assetId, attachmentNamesGenerator } = this;
            const detail = { scope, context, acceptedMimes, supportedMimes, modalTitle, modalSubtitle, fpType, maxSize, assetId, attachmentNamesGenerator };
            const event = new CustomEvent('start-uploader', { detail });
            window.dispatchEvent(event);
        }
    }
};
