/**
 * @param {string} url
 */
export function downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', '');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
