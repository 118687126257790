/** @module Collections */

import { cloneDeep, isNumber, isObject } from 'lodash';
import countryList from 'country-list';

/**
 * This method compares the string value of the given members at the given key
 * with the right locale.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function localeCompareByKey(key, a, b) {
    if (!key) throw new Error('key must be specified');
    if (!a[key] && !b[key]) return 0;
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    return a[key].localeCompare(b[key]);
}

/**
 * This method compares the numeric value of the given members at the given key.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 * @param {String} [alt] an optional key to use in case of equality of the two members
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function compareByKey(key, a, b, alt) {
    if (a[key] === b[key]) {
        if (alt) {
            if (isNumber(a[alt])) {
                return compareByKey(alt, a, b);
            } else {
                return localeCompareByKey(alt, a, b);
            }
        } else {
            return 0;
        }
    }
    return a[key] > b[key] ? 1 : -1;
}

/**
 * This method compares the numeric or string value of the given members at the given key.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function compareByKeyTypeWise(key, a, b) {
    if (isNumber(a[key])) {
        return compareByKey(key, a, b);
    } else {
        return localeCompareByKey(key, a, b);
    }
}

/**
 * Given an object or an array of objects this methods will lookup for `order` property, and
 * returns an array of sorted elements. If `order` field is not found, then
 * the `fallbackProperty` key will be used (which by default is `title`).
 *
 * NOTE 1: if `order` field is any type besides number, then the `fallbackProperty` is used.
 * NOTE 2: if `fallbackProperty` is not present on the object, then the element will be put at the end of the collection.
 * NOTE 3: if the given collection is an object and preserveKey is true, the key of the item is stored under `__key`
 *
 * @param {Object|Object[]} collection the collection to sort
 * @param {String} [fallbackProperty='title'] an optional key to use in case of equality of the two members
 * @param {boolean} [preserveKey=false] if true and the collection is an object, the key of the object is stored under `__key`
 *
 * @return {Object[]} array of sorted items.
 */
export function sortByOrder(collection, fallbackProperty = 'title', preserveKey = false) {
    const ordered = [];
    const unordered = [];
    const unsortable = [];

    if (isObject(collection)) {
        if (preserveKey) {
            collection = cloneDeep(collection);
            Object.entries(collection).forEach(([key, value]) => value.__key = key);
        }

        collection = Object.values(collection);
    }

    for (const item of collection) {
        if (isNumber(item.order)) {
            ordered.push(item);
        } else if (item.hasOwnProperty(fallbackProperty)) {
            unordered.push(item);
        } else {
            unsortable.push(item);
        }
    }

    // Sort all modules that declare an `key`, and if they have the same
    // `key`, sort them by `alt`
    ordered.sort((a, b) => compareByKeyTypeWise('order', a, b));

    // Sort all members that do not declare any `key` by `alt`
    unordered.sort((a, b) => compareByKeyTypeWise(fallbackProperty, a, b));

    return [...ordered, ...unordered, ...unsortable];
}

/**
 * Gets the list of supported countries in a format suitable
 * for selection.
 *
 * @returns {object[]} a collection suitable for selection
 */
export function getCountryListForSelect() {
    countryList.overwrite([{
        code: 'TW',
        name: 'Taiwan'
    }]);
    return countryList.getNames()
        .map(c => ({
            identifier: c,
            text: c
        }))
        .sort((a, b) => localeCompareByKey('text', a, b));
}

/**
 * Gets the list of supported countries in a format suitable
 * for selection.
 *
 * @returns {{value:string,label:string}[]} a collection suitable for selection
 */
export function getCountryListWithCodeForSelect() {
    countryList.overwrite([{
        code: 'TW',
        name: 'Taiwan'
    }]);
    return countryList.getData()
        .map(({ code, name }) => ({
            value: code,
            label: name,
        }))
        .sort((a, b) => localeCompareByKey('label', a, b));
}

/**
 * Starting from a set of "select" values, this method
 * transforms it into valid `kind_options`
 *
 * @param {object[]} values the select option values to transform
 *
 * @returns {object[]} a collection suitable for `kind_option` values
 */
export function kindOptionsFromSelect(values) {
    return values.map(v => ({ [v.identifier]: v.text }));
}
