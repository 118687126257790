import BaseService from '../base-service';
import { v4 as uuid } from 'uuid';

// constants
import { API_BASE_PATH } from 'libs/utils/constants';

import { getBackstageURL } from 'libs/utils/url';

/**
 * @const {String} START_BROADCAST_ENDPOINT API path for starting the Studio broadcast. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @private
 */
const START_BROADCAST_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/start-studio-broadcast`;

/**
 * @const {String} STUDIO_BACKGROUND_ENDPOINT API path for changing the broadcast background image. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 */
const STUDIO_BACKGROUND_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/background`;

/**
 * @const {String} STUDIO_BACKGROUND_IMAGE API path for getting the broadcast background image. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{imageId}}`.
 */
const STUDIO_BACKGROUND_IMAGE = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/background/{{imageId}}`;

/**
 * @const {String} GET_STREAM_IDS_IN_SESSION_ENDPOINT API path for getting the list of (Vonage) stream IDs in the session. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 */
const GET_STREAM_IDS_IN_SESSION_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/streams`;

/**
 * @constant {String} STUDIO_SPEAKER_FEEDBACK_URL URL for speaker feedback survey. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @private
 */
const STUDIO_SPEAKER_FEEDBACK_URL = 'http://sl.spotme.com/studio-speaker-feedback#eid={{eventId}}&stream={{liveStreamId}}';

/**
 * API path for getting studio doc. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_DOC_API_ENDPOINT
 * @private
 */
export const STUDIO_DOC_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/studio`;

/**
 * The signal used to send studio doc update signals. Interpolations: `{{liveStreamId}}`
 * @constant {String} INPUT_SWITCH_DOC_UPDATE_SIGNAL
 */
export const STUDIO_DOC_UPDATE_SIGNAL = 'liveStream/{{liveStreamId}}/studio';

/**
 * API path for getting chat doc. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_DOC_API_ENDPOINT
 * @private
 */
export const CHAT_DOC_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/chat`;

/**
 * The signal used to send chat update signals. Interpolations: `{{liveStreamId}}`
 * @constant {String} INPUT_SWITCH_DOC_UPDATE_SIGNAL
 */
export const CHAT_UPDATE_SIGNAL = 'liveStream/{{liveStreamId}}/chat';

export const WEBRTC_PROVIDER_TYPES = {
    VONAGE: 'vonage',
    CHIME: 'chime'
};

/**
 * Provides utils for studio streaming management.
 *
 * @example
 * import StudioService from 'libs/services/studio/studio';
 * ...
 * const studioService = new StudioService();
 *
 * @see
 * For development purpose see: https://tokbox.com/developer/tools/playground
 */
export default class StudioService extends BaseService {

    constructor(speakerIdStorage) {
        super();

        this.speakerIdStorage = speakerIdStorage;
    }

    /**
     * Returns the studio document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getDoc(eventId, liveStreamId) {
        const liveStreamUrl = STUDIO_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        const { data: studioDoc } = await this.get(liveStreamUrl);

        return studioDoc;
    }

    /**
     * Returns the chat document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getChatDoc(eventId, liveStreamId) {
        const liveStreamUrl = CHAT_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        const { data: studioDoc } = await this.get(liveStreamUrl);

        return studioDoc;
    }

    /**
     * send chat message
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async sendChatMessage(eventId, liveStreamId, payload) {
        const liveStreamUrl = CHAT_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        return await this.post(liveStreamUrl, payload);
    }

    /**
     * init studio dependencies
     */
    init() {
        this.studioUserId = this.speakerIdStorage.get();
        if (!this.studioUserId) {
            this.studioUserId = uuid();
            this.speakerIdStorage.set(this.studioUserId);
        }
    }

    getUserId() {
        return this.studioUserId;
    }

    async getStreamIdsInSession(eventId, liveStreamId) {
        const url = GET_STREAM_IDS_IN_SESSION_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.get(url);
        return data.streamIds;
    }

    async startBroadcast(eventId, liveStreamId) {
        console.debug('[StudioService] Start broadcast');

        const url = START_BROADCAST_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url);
        return data;
    }

    /**
     * Gets the API url for requesting the background image
     *
     * @param {string} eventId
     * @param {Object} liveStream
     * @returns {string|void} the API url, if the bg image exists
     */
    getBackgroundImageUrl(eventId, liveStream) {
        const backgroundImageId = liveStream.backgroundImageId;
        if (!backgroundImageId) {
            return;
        }
        return STUDIO_BACKGROUND_IMAGE
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStream._id)
            .replace('{{imageId}}', backgroundImageId);
    }

    /**
     * Updates the background image for the given live stream
     * (uploads a new one or deletes the current one)
     *
     * @param {string} eventId
     * @param {String} liveStreamId
     * @param {PickedFile|null|string} image the image to upload
     * @param {String} [rootNode] optiomally the root node to use for the upload
     * @returns {Promise<string|void>} the server id for the new image, or nothing
     */
    async updateBackgroundImage(eventId, liveStreamId, image, rootNode) {
        if (typeof image === 'string') {
            // It is the URL of the existing image
            return;
        }

        let url = STUDIO_BACKGROUND_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        if (rootNode) {
            url = getBackstageURL(rootNode, url);
        }

        if (image && image.size > 0) {
            const token = await this.getCsrfToken(rootNode ? getBackstageURL(rootNode) : '');
            const bodyFormData = new FormData();

            bodyFormData.set('file', image.file);
            bodyFormData.set('_csrf', token);

            const { data } = await this.post(url, bodyFormData, { withCredentials: true });
            return data.id;
        } else {
            await this.delete(url);
        }
    }

    /**
     * Updates the background image for the given live stream from the webinar template
     *
     * @param {string} eventId
     * @param {String} liveStreamId
     * @param {String} templateId the webinar temaplte from which to take the default background
     * @returns {Promise<string|void>} the server id for the new image, or nothing
     */
    async updateBackgroundImageFromWebinarTemplate(eventId, liveStreamId, templateId) {
        const url = STUDIO_BACKGROUND_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url, { templateId }, { withCredentials: true });
        return data.id;
    }

    /**
     * Checks if the given stream is a screenshare stream
     *
     * @param {Object} stream the stream to check
     *
     * @returns {Boolean} true if the stream is casted from a screen
     */
    isScreenshare(stream) {
        return stream.videoType === 'screen';
    }

    /**
     * Checks if the given stream is a camera stream
     *
     * @param {Object} stream the stream to check
     *
     * @returns {Boolean} true if the stream is casted from a camera
     */
    isCamera(stream) {
        return !this.isScreenshare(stream);
    }

    /**
     * Checks if the given stream has audio capabilities.
     * Cameras have it enabled by default
     *
     * @param {Object} stream the stream to check
     *
     * @returns {Boolean} whether the stream has audio capabilities
     */
    hasAudioCapabilities(stream) {
        if (!stream) {
            return false;
        }

        if (this.isCamera(stream)) {
            return true;
        }

        return !!(stream.publisher && stream.publisher.getAudioSource());
    }

    /**
     * Checks if the broadcast is casting a screen or not.
     *
     * @param {Object[]} liveStreams the live broadcasted streams
     *
     * @returns {Boolean} true if the broadcast contains a screen share
     */
    isCastingScreen(liveStreams) {
        return liveStreams.some(s => this.isScreenshare(s));
    }

    /**
     * Checks if the broadcast is casting a camera or not.
     *
     * @param {Object[]} liveStreams the live broadcasted streams
     *
     * @returns {Boolean} true if the broadcast contains a camera
     */
    isCastingCams(liveStreams) {
        return liveStreams.some(s => this.isCamera(s));
    }

    /**
     * Get speaker feedback survey url for live stream
     *
     * @param {String} eventId
     * @param {String} liveStreamId
     * @returns {String} feedback form URL
     */
    getSpeakerFeedbackUrl(eventId, liveStreamId) {
        return STUDIO_SPEAKER_FEEDBACK_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
    }

    extractStreamInfo(stream) {
        try {
            const fromName = JSON.parse(stream.name);
            const fromConnection = JSON.parse(stream.connection.data);

            return {
                screen: fromName.screen,
                userId: fromConnection.userId,
                role: fromConnection.userRole
            };
        } catch (error) {
            console.error('[Studio] unknown stream info', { stream, error });
            return {};
        }
    }
}
