// Utils
import { identity } from 'lodash';

export default {
    props: {
        eventId: {
            type: String,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        metrics: {
            type: Array,
            required: true
        },

        begin: {
            type: Number,
            default: undefined
        },

        cease: {
            type: Number,
            default: undefined
        },

        icon: {
            type: String,
            default: null
        },

        initialData: {
            type: [Object, Array],
            default: null
        },

        lineColor: {
            type: [String, Function],
            default: '#3687FA'
        },

        xMapper: {
            type: Function,
            default: identity
        },

        yMapper: {
            type: Function,
            default: identity
        },

        zMapper: {
            type: Function,
            default: null
        },

        jMapper: {
            type: Function,
            default: null
        },

        tooltips: {
            type: [Boolean, Function],
            default: false
        },

        markerLine: {
            type: Number,
            default: null
        },

        verticalMarkers: {
            type: Array,
            default: null
        },

        icons: {
            type: Object,
            default: null
        },

        colors: {
            type: Array,
            default: undefined
        },

        mainMetric: {
            type: String,
            default: undefined
        },

        title: {
            type: Function,
            default: undefined
        }
    },

    data() {
        return {
            currentTimespan: 'all',
            data: null,
            end: this.cease,
            loading: true,
            ready: false,
            start: this.begin
        };
    },

    watch: {
        ready(value) {
            if (value) {
                this.initChart();
            }
        }
    },

    async mounted() {
        if (this.initialData) {
            console.debug(`[${this.$options.name}] Chart will use provided data`);
            this.data = this.initialData;
            this.loading = false;
            this.ready = true;
        } else {
            await this.loadChartData();
        }
    },

    methods: {
        async loadChartData() {
            try {
                this.loading = true;
                this.data = await this.$services.analytics.loadChartData(this.eventId, {
                    start: this.start,
                    end: this.end,
                    metrics: this.metrics
                });

                this.ready = true;

            } catch (error) {
                console.error(`[${this.$options.name}] Could not load chart data`, error.message);

            } finally {
                this.loading = false;
            }
        },

        initChart() {
            console.warn(`[${this.$options.name}] initChart should be overwritten in the receiving component`);
        },

        /**
         * Changes the time span
         *
         * @param {'all'|'week'|'day'} timespan the timespan to set
         */
        async setTimespan(timespan) {
            if (timespan === this.currentTimespan) {
                return;
            }

            if (timespan === 'all') {
                this.start = undefined;
                this.end = undefined;
            }

            if (timespan === 'week') {
                const start = new Date();
                start.setHours(0, 0, 0, 0);
                start.setDate(start.getDate() - 7);
                this.start = Math.round(start.getTime() / 1000);
            }

            if (timespan === 'day') {
                const start = new Date();
                start.setHours(0, 0, 0, 0);
                this.start = Math.round(start.getTime() / 1000);
            }

            this.currentTimespan = timespan;
            await this.loadChartData();

            this.initChart();
        }
    }
};
