export default {
    data() {
        return {
            dateFormat: this.$const.DEFAULT_DATETIME_FORMAT,
            startDateValidation: `date_format:${this.$const.DEFAULT_DATETIME_FORMAT}|before:end_date,true|required`,
            endDateValidation: `date_format:${this.$const.DEFAULT_DATETIME_FORMAT}|after:start_date,true|required`,
            form: {
                name: null,
                start_date: null,
                end_date: null,
                city: null,
                test_workspace: false,
                anonymization: false,
            }
        };
    }
};
