/** @module Serialization */

export function sanitizeSerializablePayload(payload) {
    if (payload instanceof Error) {
        return JSON.parse(JSON.stringify(payload, Object.getOwnPropertyNames(payload)));
    }

    if (typeof payload === 'function') {
        return;
    }

    if (Array.isArray(payload)) {
        return payload.map(sanitizeSerializablePayload)
            .filter(v => v !== undefined);
    }

    if (payload === null) {
        return payload;
    }

    if (typeof payload === 'object') {
        const sanitizedEntries = Object.entries(payload)
            .map(([k, v]) => [k, sanitizeSerializablePayload(v)])
            .filter(entry => entry[1] !== undefined);
        return Object.fromEntries(sanitizedEntries);
    }

    return payload;
}
