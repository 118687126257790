/** @module Timer */

const AudioContext = window.AudioContext || window.webkitAudioContext;
/**
 * Creates a timer that still works in a hidden tab
 *
 * @param {Function} callback
 * @param {Number} frequency in ms, how often we need to call this
 * @return {Function} the cancel function
 */
export const timer = AudioContext ? function audioTimerLoop(callback, frequency) {
    // AudioContext time parameters are in seconds
    const freq = frequency / 1000;

    const aCtx = new AudioContext();
    // Chrome needs our oscillator node to be attached to the destination
    // So we create a silent Gain Node
    const silence = aCtx.createGain();
    silence.gain.value = 0;
    silence.connect(aCtx.destination);

    let stopped = false;

    function onOSCend() {
        if (stopped) {
            return;
        }
        const osc = aCtx.createOscillator();
        osc.onended = onOSCend;
        osc.connect(silence);
        osc.start(0);
        osc.stop(aCtx.currentTime + freq);
        callback(aCtx.currentTime);
    }

    // to start the timer after having returned the stop function
    setTimeout(onOSCend);

    // return a function to stop our loop
    return function() {
        stopped = true;
        if (aCtx.state === 'running') {
            aCtx.close();
        }
    };
} : function(callback, frequency) {
    // fallback if no audioContext is available
    const interval = setInterval(callback, frequency);
    return () => clearInterval(interval);
};
