import VueI18n from 'vue-i18n';
import { localize } from 'vee-validate';
import * as locales from 'locales/index';

function loadLocaleMessages() {
    /** @type {import('vue-i18n').LocaleMessages} */
    const messages = {};
    for (const locale of Object.keys(locales)) {
        const matched = locale.match(/([A-Za-z0-9-_]+)/i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales[locale];
        }
    }
    return messages;
}

/** @param {import('vue').VueConstructor} app */
export function createLocales(app) {
    const { getFirstEligibleLocale } = app.prototype.$utils.locales;
    const messages = loadLocaleMessages();
    const LOCALE = getFirstEligibleLocale(Object.keys(messages));

    app.use(VueI18n);

    // Plugins configuration
    const i18n = new VueI18n({
        locale: LOCALE,
        fallbackLocale: 'en',
        messages
    });

    localize(LOCALE, i18n.messages[i18n.locale].validations.overwrites);

    return i18n;
}
