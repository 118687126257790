// Utils

import * as fabric from 'fabric';
import { inRange } from 'libs/utils/numbers';
import { memoize } from 'lodash';

// Constants

const GUIDE_LINE_PROPS = {
    left: 0,
    top: 0,
    evented: true,
    stroke: 'rgb(255, 0, 0)',
    selectable: false,
    opacity: 0
};

/**
 * A mixin that provides snapping functionality for fabric.js objects.
 *
 * @mixin
 *
 * @param {Function} superclass - The superclass to extend from.
 *
 * @returns {Function} - The extended class.
 */
export const SnappableMixin = superclass => class extends superclass {

    /**
     * Constructs a new instance of the Snappable class.
     *
     * @constructor
     *
     * @param {...any} args - The arguments to be passed to the parent constructor.
     */
    constructor(...args) {
        super(...args);

        /**
         * @type {Object.<string, fabric.Line>}
         */
        this.guides = {};

        /**
         * @type {boolean}
         */
        this.eventBound = false;

        this.originalCanvas = null;

        this.on('added', () => {
            this.originalCanvas = this.canvas;
            this.bindEvents();
            this.drawObjectGuides();
        });

        this._cachedDrawLine = memoize(this.drawLine, (h, w, p) => `${h}|${w}|${p}`);
        this._cachedGetSnappableObjects = memoize(this.getSnappableObjects);
    }

    /**
     * Binds the snapping events.
     *
     * @private
     */
    bindEvents() {
        this.eventBound = true;

        this.canvas.on('object:moving', e => this.snappingHandler(e));
        this.canvas.on('object:scaling', e => this.snappingHandler(e));
        this.canvas.on('object:resizing', e => this.snappingHandler(e));

        this.on('mouseup', (e) => this.hideGuides(e.target));
    }

    /**
     * Draws the object guides.
     *
     * @private
     */
    drawObjectGuides() {
        const w = this.getScaledWidth();
        const h = this.getScaledHeight();
        this.drawGuide('top', this.top);
        this.drawGuide('left', this.left);
        this.drawGuide('centerX', this.left + w / 2);
        this.drawGuide('centerY', this.top + h / 2);
        this.drawGuide('right', this.left + w);
        this.drawGuide('bottom', this.top + h);
        this.setCoords();
    }

    /**
     * Draws a guide line.
     *
     * @param {string} side - The side of the guide line.
     * @param {number} pos - The position of the guide line.
     *
     * @private
     */
    drawGuide(side, pos) {
        let ln;

        if (['top', 'bottom', 'centerY'].includes(side)) {
            const top = this.canvas.width * (1 / this.canvas.getZoom());
            ln = this._cachedDrawLine(0, top, pos);
        }

        if (['left', 'right', 'centerX'].includes(side)) {
            const left = this.canvas.height * (1 / this.canvas.getZoom());
            ln = this._cachedDrawLine(left, 0, pos);
        }

        if (this.guides[side] instanceof fabric.Line) {
            this.canvas.remove(this.guides[side]);
            delete this.guides[side];
        }

        this.guides[side] = ln;
        this.canvas.add(ln);
    }

    /**
     * Draws a line.
     *
     * @param {number} height - The height of the line.
     * @param {number} width - The width of the line.
     * @param {number} position - The position of the line.
     *
     * @returns {fabric.Line} - The created line object.
     *
     * @private
     */
    drawLine(height, width, position) {
        const matrix = [0, height, width, 0];
        const opts = {
            top: width > 0 ? position : 0,
            left: height > 0 ? position : 0
        };

        return new fabric.Line(matrix, Object.assign(GUIDE_LINE_PROPS, opts));
    }

    /**
     * Handles the snapping logic for an object.
     *
     * @param {fabric.Event} e - The fabric event object.
     *
     * @private
     */
    snappingHandler(e) {
        const obj = e.target;
        if (!obj.guides) return false;

        this.drawObjectGuides.apply(obj);

        const objects = this._cachedGetSnappableObjects(obj);
        const matches = new Set();

        for (const i of objects) {
            this.handleObjectGuides(obj, i, matches);
        }

        this.showGuides(obj, matches);
        obj.setCoords();
    }

    /**
     * Handles the snapping logic for an object's guides.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {fabric.Object} i - The object to snap to.
     * @param {Set} matches - The set to store the matched sides.
     *
     * @private
     */
    handleObjectGuides(obj, i, matches) {
        for (const side in obj.guides) {
            const { axis, newPos } = this.calculateNewPosition(obj, i, side);

            if (inRange(obj.guides[side][axis], i.guides[side][axis])) {
                this.matchAndSnap(matches, side, obj, axis, newPos);
            }

            this.handleSideCases(obj, i, matches, side, axis);
        }
    }

    /**
     * Calculates the new position for snapping.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {fabric.Object} i - The object to snap to.
     * @param {string} side - The side to snap to.
     *
     * @returns {Object} - The calculated axis and new position.
     *
     * @private
     */
    calculateNewPosition(obj, i, side) {
        let axis, newPos;

        switch (side) {
            case 'right':
                axis = 'left';
                newPos = i.guides[side][axis] - obj.getScaledWidth();
                break;
            case 'bottom':
                axis = 'top';
                newPos = i.guides[side][axis] - obj.getScaledHeight();
                break;
            case 'centerX':
                axis = 'left';
                newPos = i.guides[side][axis] - obj.getScaledWidth() / 2;
                break;
            case 'centerY':
                axis = 'top';
                newPos = i.guides[side][axis] - obj.getScaledHeight() / 2;
                break;
            default:
                axis = side;
                newPos = i.guides[side][axis];
                break;
        }

        return { axis, newPos };
    }

    /**
     * Handles the side cases for snapping.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {fabric.Object} i - The object to snap to.
     * @param {Set} matches - The set to store the matched sides.
     * @param {string} side - The side to snap to.
     * @param {string} axis - The axis to snap to.
     *
     * @private
     */
    handleSideCases(obj, i, matches, side, axis) {
        const sideCases = {
            'left': () => inRange(obj.guides['left'][axis], i.guides['right'][axis]),
            'right': () => inRange(obj.guides['right'][axis], i.guides['left'][axis]),
            'top': () => inRange(obj.guides['top'][axis], i.guides['bottom'][axis]),
            'bottom': () => inRange(obj.guides['bottom'][axis], i.guides['top'][axis]),
            'centerX': () => inRange(obj.guides['centerX'][axis], i.guides['left'][axis]) || inRange(obj.guides['centerX'][axis], i.guides['right'][axis]),
            'centerY': () => inRange(obj.guides['centerY'][axis], i.guides['top'][axis]) || inRange(obj.guides['centerY'][axis], i.guides['bottom'][axis])
        };

        if (sideCases[side]()) {
            this.matchAndSnap(matches, side, obj, axis, this.calculateSideCasePosition(obj, i, side, axis));
        }
    }

    /**
     * Calculates the position for side cases snapping.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {fabric.Object} i - The object to snap to.
     * @param {string} side - The side to snap to.
     * @param {string} axis - The axis to snap to.
     *
     * @returns {number} - The calculated position.
     *
     * @private
     */
    calculateSideCasePosition(obj, i, side, axis) {
        const sideCasePositions = {
            'left': () => i.guides['right'][axis],
            'right': () => i.guides['left'][axis] - obj.getScaledWidth(),
            'top': () => i.guides['bottom'][axis],
            'bottom': () => i.guides['top'][axis] - obj.getScaledHeight(),
            'centerX': () => inRange(obj.guides['centerX'][axis], i.guides['left'][axis]) ? i.guides['left'][axis] - obj.getScaledWidth() / 2 : i.guides['right'][axis] - obj.getScaledWidth() / 2,
            'centerY': () => inRange(obj.guides['centerY'][axis], i.guides['top'][axis]) ? i.guides['top'][axis] - obj.getScaledHeight() / 2 : i.guides['bottom'][axis] - obj.getScaledHeight() / 2
        };

        return sideCasePositions[side]();
    }

    /**
     * Matches and snaps an object to a specific side and position.
     *
     * @param {Set} matches - The set to store the matched sides.
     * @param {string} side - The side to snap to.
     * @param {fabric.Object} obj - The object to snap.
     * @param {string} axis - The axis to snap to.
     * @param {number} position - The position to snap to.
     *
     * @private
     */
    matchAndSnap(matches, side, obj, axis, position) {
        matches.add(side);
        this.snapObject(obj, axis, position);
    }

    /**
     * Snaps an object to a specific side and position.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {string} side - The side to snap to.
     * @param {number} pos - The position to snap to.
     */
    snapObject(obj, side, pos) {
        obj.set(side, pos);
        obj.setCoords();
        this.drawObjectGuides.apply(obj);
    }

    /**
     * Retrieves the snappable objects in the canvas.
     *
     * @param {fabric.Object} obj - The object to snap.
     *
     * @returns {fabric.Object[]} - The snappable objects.
     *
     * @private
     */
    getSnappableObjects(obj) {
        return this.getCanvas(obj)
            .getObjects()
            .filter((o) => o.type !== 'line' && o !== obj && o.guides);
    }

    /**
     * Shows the guides for snapping.
     *
     * @param {fabric.Object} obj - The object to snap.
     * @param {Set} matches - The set of matched sides.
     *
     * @private
     */
    showGuides(obj, matches) {
        for (const k of matches) {
            obj.guides[k].set('opacity', 1);
        }
    }

    /**
     * Hides the guides for snapping.
     *
     * @param {fabric.Object} obj - The object to snap.
     *
     * @private
     */
    hideGuides(obj) {
        const objects = this._cachedGetSnappableObjects(obj);
        for (const o of objects) {
            for (const guide of Object.values(o.guides)) {
                guide.set('opacity', 0);
            }
        }

        this.getCanvas(obj).renderAll();
    }

    getCanvas(obj) {
        return obj.canvas || obj.originalCanvas || this.originalCanvas;
    }
};
