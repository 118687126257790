export default {

    data() {
        return {
            searchTerm: '',
            visibleEvents: {},
        };
    },

    computed: {
        /** @returns {boolean} */
        hasResults() {
            const { latest = [], future = [], live = [], past = [] } = this.visibleEvents;
            return (this.searchTerm || '').length > 0 &&
                (latest.length + future.length + live.length + past.length) !== 0;
        }
    },

    watch: {
        searchTerm() {
            this.filterSearch();
        }
    },

    methods: {
        /**
         * Reset the filtered search result to the inital dataset
         */
        resetSearch() {
            this.visibleEvents = { ...this.events };
        },

        /**
         * Filter the events by the search input
         */
        filterSearch() {
            if (!this.searchTerm || !this.searchTerm.length) {
                this.resetSearch();
                return;
            }

            const locale = this.$i18n.locale;
            const searchTerms = this.searchTerm.toLocaleLowerCase(locale).trim().split(' ');

            for (const [stage, events] of Object.entries(this.events)) {
                this.visibleEvents[stage] = events.filter(e => {
                    const lookupString = this.$services.event.getSearchString(e, locale);

                    return searchTerms.every(term => lookupString.includes(term));
                });
            }
        },
    }
};
