class Queue {
    constructor() {
        this._QUEUE = [];
        this.running = false;
    }

    /**
     * @param {function} task function to add to the queue
     */
    push(task) {
        if (typeof task !== 'function') {
            throw new Error('task must be a function');
        }

        this._QUEUE.push(task);

        if (!this.running) {
            this._doTask();
        }
    }

    async _doTask() {
        this.running = true;

        const next = this._QUEUE.length > 0
            ? this._QUEUE.shift()
            : null;

        if (next) {
            try {
                await next();
            } catch (e) {
                console.error(e);
            }
            return this._doTask();
        }

        this.running = false;
    }
}

export {
    Queue
};
