// Components
import WorkspacesList from 'ui/components/WorkspacesList.vue';

/**
 * This is an abstract component for workspaces listing.
 *
 * @vue-prop {Object} params the initial page parameters
 *
 * @category ui.mixins.workspaces
 * @exports Listing
 */
export default {
    components: { WorkspacesList },

    props: {
        params: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            searchTerm: '',
            gettingStartedUrl: this.$const.GETTING_STARTED_URL
        };
    },

    watch: {
        searchTerm() {
            this.filterSearch();
        }
    },

    methods: {
        /**
         * Resets the search input
         */
        clearSearch() {
            this.searchTerm = '';
            this.resetSearch();
        },

        /**
         * Reset the filtered search result to the inital dataset
         *
         * @abstract
         */
        resetSearch() {
            console.warn('[mixin/workspaces/listing] overwrite this method in extending component');
        },

        /**
         * Filter the workspaces by the search input
         *
         * @abstract
         */
        filterSearch() {
            console.warn('[mixin/workspaces/listing] overwrite this method in extending component');
        }
    }
};
