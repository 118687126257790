// Utils
import { isArray } from 'lodash';

// Components
import SendEmailsModal from 'shared/ui/components/modals/SendEmailsModal.vue';

export default {

    methods: {
        /**
         * Open modal to send emails to the specified users
         * @param {Object} [event]
         * @param {Pax|Pax[]} [users=[]]
         */
        openEmailModal(event, users = []) {
            if (!isArray(users)) {
                users = [users];
            }

            const recipientsExtIds = users.map(u => u.fp_ext_id);

            this.$modal.show(SendEmailsModal, {
                event,
                recipientsExtIds,
                onSuccess: this.onSendEmailSuccess.bind(this)
            }, {
                clickToClose: false,
                height: 'auto'
            });
        },

        onSendEmailSuccess() {
            // Optionally overwrite on including component
        }
    }
};
