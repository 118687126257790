/** @module Assets */
// Utils
import mimeDb from 'mime-db';

// Constants
import {
    BASE_ASSETS_PATH,
    API_BASE_PATH,
    IMAGE_PATH,
    WORKSPACE_APP_ICON_NAME,
    WEBINAR_ICON_ENDPOINT,
    WEBINAR_BANNER_ENDPOINT
} from 'libs/utils/constants';

const TYPE_ICONS = {
    '4p_event': 'ic-star',
    'act': 'ic-user-check',
    'background_job': 'ic-settings',
    'content-document': 'ic-file-text',
    'content-video': 'ic-video',
    'diff': 'ic-shuffle',
    'editorial': 'ic-file-text',
    'fstg-page': 'ic-globe',
    'scheduled-job': 'ic-clock',
    'location': 'ic-pin',
    'mail_template': 'ic-at-sign',
    'message': 'ic-message-circle',
    'nav': 'ic-layout',
    'package_install_report': 'ic-grid',
    'person': 'ic-user-outline',
    'presenter': 'ic-mic-outline',
    'questionnaire': 'ic-help',
    'session': 'ic-calendar-agenda',
    'sponsor': 'ic-in-person',
    'unknown': 'ic-file'
};

/**
 * The asset path. Interpolation: `{{eventId}}`, `{{filename}}`
 *
 * @constant {String} EVENT_ASSET_PATH
 *
 * @private
 */
const EVENT_ASSET_PATH = `${API_BASE_PATH}/events/{{eventId}}/assets/{{filename}}`;

/**
 * The template image path. Interpolation: `{{templateId}}`, `{{filename}}`
 *
 * @constant {String} TEMPLATE_IMAGE_PATH
 *
 * @private
 */
const TEMPLATE_IMAGE_PATH = `${API_BASE_PATH}/backstage/templates/{{templateId}}/image/{{filename}}`;

/**
 * Webinar template asset path. Interpolation: `{{templateId}}`, `{{asset}}`
 *
 * @constant {String} WEBINAR_TEMPLATE_ASSET_PATH
 *
 * @private
 */
const WEBINAR_TEMPLATE_ASSET_PATH = `${API_BASE_PATH}/webinar/template/{{templateId}}/asset/{{asset}}`;

/**
 * The asset path. Interpolation: `{{eventId}}`, `{{docId}}`, `{{attachment}}`
 *
 * @constant {String} EVENT_ATTACHMENT_PATH
 *
 * @private
 */
const EVENT_ATTACHMENT_PATH = `${API_BASE_PATH}/events/{{eventId}}/assets/{{docId}}/{{attachment}}`;

/** *
 * @constant {String} EVENT_ICON
 *
 * @private
 */
const EVENT_ICON = `${API_BASE_PATH}/eid/{{eventId}}/event-icon`;

/**
 * @constant {string} THEME_ASSET_PATH The path for getting theme assets
 * @private
 */
const THEME_ASSET_PATH = `${API_BASE_PATH}/events/{{eventId}}/assets/theme/{{asset}}`;

/**
 * @const {string[]} SUPPORTED_AVATAR_TYPES supported avatar mime types
 */
export const SUPPORTED_AVATAR_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

/**
 * Given a relative path, this method returns the full static asset path
 *
 * @param {String} filePath the relative file path
 *
 * @return {String} the asset's path
 */
export function baseAssetsPath(filePath) {
    const path = [];

    if (window.BSTG.env !== 'test') {
        path.push(window.BSTG.env);
        path.push(window.BSTG.version);
    } else {
        filePath = filePath.substring(1, filePath.length);
    }

    return `/${path.join('/')}${filePath}`;
}

/**
 * Given a relative image path, this method returns the full static image path
 *
 * @param {String} path the relative image path
 * @param {String} [size] the image size as per `srcset` specification
 *
 * @example
 *
 * imagePath('logo.svg'); // => "/path/to/logo.svg"
 * imagePath('logo.svg', '2x'); // => "/path/to/logo.svg 2x"
 *
 * @return {String} the image's path
 */
export function imagePath(path, size = '') {
    if (size?.length) {
        size = ` ${size}`;
    }

    return baseAssetsPath(`${IMAGE_PATH}/${path}${size}`);
}

/**
 * NOTE: this method is used in the api docs api because the mapping of
 * static images is slightly different from backstage's.
 *
 * Given a relative image path, this method returns the full static image path
 *
 * @param {String} path the relative image path
 * @param {String} [size] the image size as per `srcset` specification
 *
 * @example
 *
 * imagePath('logo.svg'); // => "/path/to/logo.svg"
 * imagePath('logo.svg', '2x'); // => "/path/to/logo.svg 2x"
 *
 * @return {String} the image's path
 */
export function docsImagePath(path, size = '') {
    if (size?.length) {
        size = ` ${size}`;
    }

    const base = baseAssetsPath(`/img/${path}${size}`);
    return `${BASE_ASSETS_PATH}${base}`;
}

/**
 * Given an event ID and an optional filename this method returns a proper assets URL prefix.
 *
 * @param {String} eventId the ID of the event
 * @param {String} [filename] the name of the asset
 *
 * @returns {String} the event's asset path
 */
export function getEventAssetPath(eventId, filename = '') {
    const path = EVENT_ASSET_PATH
        .replace('{{eventId}}', eventId)
        .replace('{{filename}}', filename);

    return path.endsWith('/') ? path.slice(0, -1) : path;
}

/**
 * Given a template ID and a filename this method returns a proper image path.
 *
 * @param {String} templateId the ID of the event
 * @param {String} filename the name of the image
 *
 * @returns {String} the template's image path
 */
export function getTemplateAssetPath(templateId, filename) {
    const path = TEMPLATE_IMAGE_PATH
        .replace('{{templateId}}', templateId)
        .replace('{{filename}}', filename);

    return path.endsWith('/') ? path.slice(0, -1) : path;
}

/**
 * Given a webinar template ID and a asset name this method returns a proper asset path.
 *
 * @param {String} templateId the ID of the webinar template
 * @param {String} asset the name of the asset
 *
 * @returns {String} the template's asset path
 */
export function getWebinarTemplateAssetPath(templateId, asset) {
    const path = WEBINAR_TEMPLATE_ASSET_PATH
        .replace('{{templateId}}', templateId)
        .replace('{{asset}}', asset)
        .concat(`?_=${Date.now()}`);

    return path;
}


/**
 * Given an event ID and a attachment name this method returns the event attachment URL prefix.
 *
 * @param {String} eventId the ID of the event
 * @param {String} attachment the name of the attachment
 * @param {String} [docId] the document to get the attachment of
 *
 * @returns {String} the event's attachment path
 */
export function getEventAttachmentPath(eventId, attachment, docId) {
    const path = EVENT_ATTACHMENT_PATH
        .replace('{{eventId}}', eventId)
        .replace('{{docId}}', docId || eventId)
        .replace('{{attachment}}', attachment);

    return path;
}

/**
 * Gets the standard app icon file name
 *
 * @returns {String} the app icon filename
 */
export function getAppIconFilename() {
    return WORKSPACE_APP_ICON_NAME;
}

/**
 * get the event's icon path
 *
 * @param {String} eventId event ID
 * @returns {string} path
 */
export function getEventIconImagePath(eventId) {
    return getImagePath(eventId, EVENT_ICON);
}

/**
 * get the webinar's icon path
 *
 * @param {String} eventId event ID
 * @returns {string} path
 */
export function getWebinarIconImagePath(eventId) {
    return getImagePath(eventId, WEBINAR_ICON_ENDPOINT);
}

/**
 * get the webinar's banner path
 *
 * @param {String} eventId event ID
 * @returns {string} path
 */
export function getWebinarBannerImagePath(eventId) {
    return getImagePath(eventId, WEBINAR_BANNER_ENDPOINT);
}

/**
 * get the image path
 *
 * @param {String} eventId event ID
 * @param {String} endpoint the proper endpoint to ask for the image
 * @returns {string} path
 */
export function getImagePath(eventId, endpoint) {
    let path = endpoint.replace('{{eventId}}', eventId);
    path += `?_=${Date.now()}`;
    return path;
}

/**
 * Given an fp_type this method will return the proper icon
 *
 * @param {string} type the fp type of the document
 *
 * @returns {string} the icon for the given type
 */
export function getIconForType(type) {
    return TYPE_ICONS[type] || TYPE_ICONS['unknown'];
}

/**
 * Gets the path to the requeste theme asset
 *
 * @param {string} eventId the ID of the event
 * @param {string} asset the name of the asset to load
 *
 * @returns {string} the path to the theme asset
 */
export function getThemeAsset(eventId, asset) {
    return THEME_ASSET_PATH
        .replace('{{eventId}}', eventId)
        .replace('{{asset}}', asset);
}

/**
 * Returns the path for the person's picture.
 *
 * @param {string} eventId - The ID of the event.
 * @param {string} paxId - The ID of the person.
 *
 * @returns {string} The path for the person's picture.
 */
export function getPaxPicture(eventId, paxId) {
    return getEventAttachmentPath(eventId, 'photo', `${paxId}-photo`);
}

/**
 * Converts an array of MIME types to a human-readable format.
 *
 * @param {string|string[]} mimes - A MIME type or an array of MIME types.
 *
 * @returns {string[]} An array of human-readable MIME type extensions in uppercase.
 */
export function humanReadableMime(mimes) {
    if (!Array.isArray(mimes)) {
        mimes = [mimes];
    }

    const allTypes = [];

    for (const mime of mimes) {
        let extensions = mimeDb[mime]?.extensions;
        if (!extensions) {
            extensions = [mime.split('/')[1] ?? mime];
        }
        const uppercased = extensions.map(e => e.toLocaleUpperCase());
        allTypes.push(...uppercased);
    }

    return allTypes;
}
