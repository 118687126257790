/**
 * Recursively removes all undefined values from the given object
 *
 * @param {object|null} [object={}] the object to clean
 *
 * @returns {object} the object without undefined values
 */
export function clean(object = {}) {
    if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
        Object.keys(object).forEach(k => {
            if (typeof object[k] === 'undefined') {
                delete object[k];
            }

            if (typeof object[k] === 'object') {
                object[k] = clean(object[k]);
            }
        });

        return object;
    }

    return {};
}

/**
 * Deeply extends an object with properties from additional objects.
 *
 * This function recursively merges properties from source objects into the target object.
 * It handles various data types including arrays, objects, strings, dates, and functions.
 *
 * @param {Object} obj - The target object to extend.
 * @param {...Object} others - One or more source objects to extend the target object with.
 *
 * @returns {Object} - The extended target object.
 *
 * @see https://gist.github.com/kurtmilam/1868955
 * @note but modified (with forceful array/object overwrite)
 */
export function deepExtend(obj, ...others) {
    const parentRE = /#{\s*?_\s*?}/;

    others.forEach(source => {
        Object.keys(source).forEach(prop => {
            const sourceProp = source[prop];
            const objProp = obj[prop];

            if (sourceProp === undefined || typeof objProp === 'function' || sourceProp === null || sourceProp instanceof Date) {
                obj[prop] = sourceProp;
            } else if (typeof sourceProp === 'string' && parentRE.test(sourceProp)) {
                obj[prop] = typeof objProp === 'string' ? sourceProp.replace(parentRE, objProp) : sourceProp;
            } else if (Array.isArray(sourceProp) || Array.isArray(objProp)) {
                obj[prop] = Array.isArray(sourceProp) && Array.isArray(objProp)
                    ? deepExtend([...objProp], sourceProp).filter(item => item !== null)
                    : sourceProp;
            } else if (typeof sourceProp === 'object' || typeof objProp === 'object') {
                obj[prop] = typeof sourceProp === 'object' && typeof objProp === 'object'
                    ? deepExtend({ ...objProp }, sourceProp)
                    : sourceProp;
            } else {
                obj[prop] = sourceProp;
            }
        });
    });

    return obj;
}
