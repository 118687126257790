// Utils
import { unixToDate, addToDate, subtractFromDate, dateToFormat } from 'libs/utils/time';
import { getSearcheable } from 'libs/utils/pax';
import { DEFAULT_DATE_FORMAT } from 'libs/utils/constants';

// Constants
import * as COLORS from 'libs/utils/global-colors';

/**
 * @typedef {Object} TimeSlots
 * @property {String} start_time
 * @property {String} end_time
 *
 * @typedef {Object} ValiditySlot
 * @property {String} start_date
 * @property {String} end_date
 * @property {TimeSlots[]} time_slots
 *
 * @example
 * [
 *      {
 *          "start_date": "2024-06-01",
 *          "end_date": "2024-06-16",
 *          "time_slots": [
 *              {
 *                  "start_time": "07:00",
 *                  "end_time": "12:30"
 *              },
 *              {
 *                  "start_time": "14:00",
 *                  "end_time": "20:00"
 *              }
 *          ]
 *      },
 *      {
 *          "start_date": "2024-06-17",
 *          "end_date": "2024-06-30",
 *          "time_slots": [
 *              {
 *                  "start_time": "00:00",
 *                  "end_time": "00:00"
 *              }
 *          ]
 *      }
 *  ]
 */

/**
 * @typedef {Object} InvaliditySlot see https://mobiscroll.com/docs/javascript/eventcalendar/api#opt-invalid
 * @property {Date | string | object} start
 * @property {Date | string | object} end
 * @property {Boolean} [allDay]
 * @property {String} [resource]
 * @property {object} [recurring] see https://mobiscroll.com/docs/javascript/core-concepts/recurrence
 */

/**
 * Builds an invalidity matrix from a validity matrix
 *
 * @param {String} resource the ID of the resource for which the invalidity matrix is built
 * @param {ValiditySlot[]} validitySlots the validity matrix
 *
 * @returns {InvaliditySlot[]} the invalidity matrix
 */
export function invalidFromValid(resource, validitySlots = []) {
    if (validitySlots.length === 0) {
        return [];
    }

    const invaliditySlots = [{
        allDay: true,
        resource,
        recurring: {
            repeat: 'daily',
            from: '1970-01-01',
            until: dateToFormat(subtractFromDate(validitySlots[0].start_date, 1, 'days'), DEFAULT_DATE_FORMAT)
        }
    }];

    for (const [index, validity] of validitySlots.entries()) {
        buildInvalidTimeSlots(invaliditySlots, validity, resource);

        const nextValiditySlot = validitySlots[index + 1];
        if (nextValiditySlot && validity.end_date !== nextValiditySlot.start_date) {
            invaliditySlots.push({
                allDay: true,
                resource,
                recurring: {
                    repeat: 'daily',
                    from: dateToFormat(addToDate(validity.end_date, 1, 'days'), DEFAULT_DATE_FORMAT),
                    until: dateToFormat(subtractFromDate(nextValiditySlot.start_date, 1, 'days'), DEFAULT_DATE_FORMAT)
                }
            });
        }
    }

    invaliditySlots.push({
        allDay: true,
        resource,
        recurring: {
            repeat: 'daily',
            from: dateToFormat(addToDate(validitySlots[validitySlots.length - 1].end_date, 1, 'days'), DEFAULT_DATE_FORMAT),
            until: '2100-01-01',
        }
    });

    return invaliditySlots;
}

/**
 * Builds invalidity slots for a single validity slot
 *
 * @param {InvaliditySlot[]} invaliditySlots the invalidity matrix
 * @param {ValiditySlot} validity the validity slot
 * @param {string} resource the ID of the resource for which the invalidity matrix is built
 *
 * @private
 */
function buildInvalidTimeSlots(invaliditySlots, validity, resource) {
    const recurring = {
        repeat: 'daily',
        from: `${validity.start_date} 00:00`,
        until: `${validity.end_date} 23:59`
    };

    // Add initial invalidity slot between the start of the day
    // and the initial valid start time.
    if (validity.time_slots[0].start_time !== '00:00') {
        invaliditySlots.push({
            start: '00:00',
            end: validity.time_slots[0].start_time,
            resource,
            recurring
        });
    }

    for (const [index, timeSlot] of validity.time_slots.entries()) {
        const hasNext = Boolean(validity.time_slots[index + 1]);
        if (timeSlot.end_time !== '00:00') {
            invaliditySlots.push({
                start: timeSlot.end_time,
                end: hasNext ? validity.time_slots[index + 1].start_time : '23:59',
                resource,
                recurring
            });
        }
    }
}

/**
 * Computes the status of a meeting or a session.
 *
 * @param {Meeting|Session} event the meeting or session to compute the status from.
 * @param {object} resource the resource to compute the status for.
 * @param {string} resource.type the type of the resource.
 * @param {object} resource.acceptance the type of the resource.
 *
 * @returns {string} the meeting or session computed status.
 */
export function computeEventStatus(event, resource) {
    if (!event.acceptance) {
        if (resource.is_agenda) {
            return 'agenda';
        }
        return '';
    }

    const organizerAcceptance = event.acceptance[event.organizer._id];
    const organizerAccepted = organizerAcceptance === 'yes';
    const organizerDeclined = organizerAcceptance === 'no';
    const somePaxAccepted = event.participants.some(p => event.acceptance[p._id] === 'yes');
    const everyPaxDeclined = event.participants.length && event.participants.every(p => event.acceptance[p._id] === 'no');
    const hasExternalPax = event.externals?.length > 0;

    /**
     * Statuses definitions as per https://spotme.atlassian.net/browse/PB-3211:
     *
     * Confirmed → Meeting organizer has accepted + at least 1 invitee accepted
     * Declined → Meeting got declined by the organizer, or by all invitees
     * Invited → all the rest
     */
    if (organizerAccepted && (somePaxAccepted || hasExternalPax)) {
        return 'yes';
    } else if (organizerDeclined || (everyPaxDeclined && !hasExternalPax)) {
        return 'no';
    }
    return 'maybe';
}

/**
 * Reconciles the meeting organizer and participants with the provided participants array,
 * transforming the string IDs into the actual person objects.
 *
 * @param {Meeting[] & LoadedMeeting[]} meetings - The array of meetings object to reconcile.
 * @param {Person[]} participants - The array of participants to reconcile with.
 */
export function reconcileParticipants(meetings, participants) {
    for (const meeting of meetings) {
        if (typeof meeting.organizer === 'string') {
            meeting.organizer = participants.find(p => p._id === meeting.organizer);

            if (!meeting.organizer) {
                console.error('[utils/calendar] Organizer not found', meeting);
                meeting.organizer = {};
            }
        }

        if (meeting.participants.some(p => typeof p === 'string')) {
            meeting.participants = participants.filter(p => meeting.participants.includes(p._id) && p._id !== meeting.organizer._id);
        }
    }
}

/**
 * Converts a meeting object to a calendar event object.
 *
 * @param {Meeting} meeting - The meeting object.
 *
 * @returns {Object} The calendar event object.
 */
export function meetingToCalendarEvent(meeting = []) {
    try {
        const resources = [meeting.organizer._id, ...meeting.participants.map(p => p._id), meeting.meeting_location];

        // See
        // - https://mobiscroll.com/docs/javascript/eventcalendar/calendar#opt-data
        // - https://mobiscroll.com/docs/vue/eventcalendar/data-binding
        return {
            id: meeting._id,
            title: meeting.name,
            color: COLORS.COLOR_LIGHT_PRIMARY_300,
            resource: resources,
            start: unixToDate(meeting.start).toDate(),
            end: unixToDate(meeting.end).toDate(),
            origin: meeting,
            __search: getMeetingSearcheable(meeting)
        };
    } catch (error) {
        console.error('Error converting meeting to calendar event', error, meeting);
    }
}

/**
 * Converts a session object to a calendar event object.
 *
 * @param {Object} session - The session object to convert.
 *
 * @returns {Object} The calendar event object.
 */
export function sessionToCalendarEvent(session) {
    return {
        id: session._id,
        title: session.name,
        color: COLORS.COLOR_LIGHT_PRIMARY_300,
        resource: 'sessions',
        start: unixToDate(session.start),
        end: unixToDate(session.end),
        origin: session,
        is_session: true,
        __search: getMeetingSearcheable(session)
    };
}

/**
 * Returns a searchable string representation of a meeting.
 *
 * @param {Meeting} meeting - The meeting object.
 *
 * @returns {string} - The searchable string representation of the meeting.
 */
function getMeetingSearcheable(meeting) {
    const attendees = [meeting.organizer, ...(meeting.participants || [])];
    const attendeesSearch = attendees.map(a => getSearcheable(a)).join(' ').trim();
    const meetingSearch = meeting.name?.toLowerCase().trim() || '';
    return [meetingSearch, attendeesSearch].join(' ');
}

/**
 * Converts a pax or location object to a calendar resource object.
 *
 * @param {Person|Object} paxOrLocation - The pax or location object to convert.
 * @param {string} [color=COLORS.COLOR_LIGHT_PRIMARY_300] - The color of the calendar resource.
 *
 * @returns {Object} The converted calendar resource object.
 */
export function paxOrLocationToCalendarResource(paxOrLocation, color = COLORS.COLOR_LIGHT_PRIMARY_300) {
    const res = {
        id: paxOrLocation._id,
        color,
        origin: paxOrLocation,
        title: paxOrLocation.title || paxOrLocation.label,
        type: paxOrLocation.fp_type,
        is_agenda: false,
        __search: getSearcheable(paxOrLocation)
    };

    if (paxOrLocation.fp_type === 'person') {
        res.is_person = true;
    }

    if (paxOrLocation.fp_type === 'prospect') {
        res.is_prospect = true;
    }

    return res;
}
