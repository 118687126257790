export default {
    state: {
        lastUploadedAt: 0,

        isUploading: false
    },

    mutations: {
        LAST_UPLOADED_AT: (state, timestamp) => state.lastUploadedAt = timestamp,

        SET_IS_UPLOADING: (state, isUploading) => state.isUploading = isUploading
    },

    actions: {
        updateLastUpload: ({ commit }, timestamp) => commit('LAST_UPLOADED_AT', timestamp),

        updateUploadStatus: ({ commit }, isUploading) => commit('SET_IS_UPLOADING', isUploading)
    },

    getters: {
        isUploading: (state) => state.isUploading
    }
};
