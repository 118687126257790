
const getVersion = (doc) => doc?.version ?? 0;

export function isNewDocNewer(newDoc, oldDoc) {
    return getVersion(oldDoc) < getVersion(newDoc);
}

export function hasNewDocSameVersion(newDoc, oldDoc) {
    return getVersion(oldDoc) === getVersion(newDoc);
}
