// Utils
import { isDate, isEmpty, isString } from 'lodash';
import { getBrowserDateFormat } from 'libs/utils/time';

// Components
import DatePicker from 'vue2-datepicker';

export default {

    components: { DatePicker },

    inheritAttrs: false,

    props: {
        firstDayOfWeek: {
            type: Number,
            default: 1
        },

        format: {
            type: String,
            default: getBrowserDateFormat()
        },

        notAfter: {
            type: [Date, String],
            default: null
        },

        notBefore: {
            type: [Date, String],
            default: null
        },

        value: {
            type: [Date, Array, Number, String],
            default: null
        },

        valueType: {
            type: String,
            default: 'timestamp'
        },

        timezone: {
            type: String,
            default: 'local'
        },

        timePickerOptions: {
            type: [Object, Function],
            default: () => null,
        },

        invalidDates: {
            type: Function,
            default: null
        },

        invalidTimes: {
            type: Function,
            default: null
        }
    },

    computed: {
        /** @returns {any} */
        innerValue: {
            get() {
                return this.eventTzToLocalTz(this.value);
            },

            set(value) {
                if (['x', 'X'].includes(this.valueType)) {
                    value = this.localTzToEventTz(value);

                } else if (this.onlyUpdateTime) {
                    const nextValue = new Date(this.value.getTime());
                    nextValue.setHours(value.getHours());
                    nextValue.setMinutes(value.getMinutes());
                    this.$emit('input', nextValue);
                    return true;
                }

                this.$emit('input', value);
            }
        },

        /**
         * @returns {Date|null}
         */
        notAfterDate() {
            let date = null;

            if (isDate(this.notAfter)) {
                date = this.notAfter;
            }

            if (!isEmpty(this.notAfter) && isString(this.notAfter)) {
                date = new Date(this.notAfter);
            }

            if (this.onlyUpdateTime && date && this.value.getTime() <= date.getTime()) {
                date = null;
            }

            return date;
        },

        /**
         * @returns {Date|null}
         */
        notBeforeDate() {
            let date = null;

            if (isDate(this.notBefore)) {
                date = this.notBefore;
            }

            if (!isEmpty(this.notBefore) && isString(this.notBefore)) {
                date = new Date(this.notBefore);
            }

            if (this.onlyUpdateTime && date && this.value.getTime() >= date.getTime()) {
                date = null;
            }

            return date;
        },

        /**
         * @returns {Date|null}
         */
        notAfterEndOfDay() {
            if (isDate(this.notAfterDate)) {
                const date = new Date(this.notAfterDate);
                date.setHours(23, 59, 59, 999);
                return date;
            }

            return null;
        },

        /**
         * @returns {Date|null}
         */
        notBeforeBeginOfDay() {
            if (isDate(this.notBeforeDate)) {
                const date = new Date(this.notBeforeDate);
                date.setHours(0, 0, 0, 0);
                return date;
            }

            return null;
        },

        /**
         * Pass-through listeners
         * @returns {object}
         */
        listeners() {
            // eslint-disable-next-line
            const { input, change, ...listeners } = this.$listeners;

            return listeners;
        },

        /**
         * Pass-through attributes
         * @returns {object}
         */
        attrs() {

            /* eslint-disable no-unused-vars */
            const {
                label,
                hint,
                focused,
                value,
                ...attrs
            } = Object.assign(this.$options.propsData, this.$attrs);
            /* eslint-enable no-unused-vars */

            return attrs;
        }
    },

    methods: {
        /**
         * Checks if the given date is within
         * the before and after range
         *
         * @param {Date} date the date to check against the given range
         *
         * @returns {Boolean} whether the given date is disabled or not
         */
        disabledDate(date) {
            if (typeof this.invalidDates === 'function') {
                return this.invalidDates(date);
            }

            const notBefore = this.notBeforeBeginOfDay;
            const notAfter = this.notAfterEndOfDay;
            const { dateIsBefore, dateIsAfter } = this.$utils.time;

            let isBefore, isAfter = false;

            if (isDate(notBefore)) isBefore = dateIsBefore(date, notBefore);
            if (isDate(notAfter)) isAfter = dateIsAfter(date, notAfter);

            return isBefore || isAfter;
        },

        /**
         * Checks if the given time is within
         * the before and after range
         *
         * @param {Date} time the time to check against the given range
         *
         * @returns {Boolean} whether the given time is disabled or not
         */
        disabledTime(time) {
            if (typeof this.invalidTimes === 'function') {
                return this.invalidTimes(time);
            }
            const notBefore = this.notBeforeDate;
            const notAfter = this.notAfterDate;
            const { dateIsBefore, dateIsAfter, timeDiff } = this.$utils.time;
            let changed;

            if (this.clonedTime && this.clonedTime.getTime() !== time.getTime()) {
                const diff = timeDiff(time, this.clonedTime, 'minutes');
                changed = diff < 60 ? 'minute' : 'hours';
            }

            this.clonedTime = new Date(time.getTime()); // Clone date in order not to mess with plugin references

            let isBefore = false;
            let isAfter = false;

            if (isDate(notBefore)) {
                // Floor insignificant (for the view) measure down
                notBefore.setSeconds(0);
                notBefore.setMilliseconds(0);

                if (changed === 'hours') {
                    // This will be true only if we're evaluating whether to
                    // enable a specific *Hour* (not minute) in the picker
                    // and the user did not provided a default value on the
                    // input date picker

                    // When no default value is given, but not-before or
                    // not-after values are set, the date picker will call
                    // this callback with a date set with
                    // time: X:00 00.000, but we are comparing with a X:35
                    // (for example), resulting with a positive `isBefore`
                    // check, disabling the hour picker.

                    // To prevent this to happen, we artificially set the minutes
                    // of the given date to the same value of the `not-before`
                    // and `not-after` so that the date comparison could return
                    // a valid comparison check.
                    if (notBefore.getHours() === this.clonedTime.getHours()) {
                        this.clonedTime.setMinutes(notBefore.getMinutes());
                    }
                }

                isBefore = dateIsBefore(this.clonedTime, notBefore);
            }

            if (isDate(notAfter)) {
                // Ceil insignificant (for the view) measure up
                notAfter.setSeconds(59);
                notAfter.setMilliseconds(59);

                if (changed === 'hours') {
                    if (notAfter.getHours() === this.clonedTime.getHours()) {
                        this.clonedTime.setMinutes(notAfter.getMinutes());
                    }
                }

                isAfter = dateIsAfter(this.clonedTime, notAfter);
            }

            return isBefore || isAfter;
        },

        localTzToEventTz(value) {
            return this.convertTimezone(value, 'tz');
        },

        eventTzToLocalTz(value) {
            return this.convertTimezone(value, 'local');
        },

        convertTimezone(value, direction = 'local') {
            if (['x', 'X'].includes(this.valueType)) {
                if (typeof value === 'string') {
                    value = Number.parseInt(value);
                }

                if (Number.isFinite(value)) {
                    const ts = this.valueType === 'X' ? value * 1000 : value;
                    const util = direction === 'local' ? 'transformTzTimeToLocal' : 'transformLocalTimeToTz';
                    const date = this.$utils.time[util](ts, this.timezone);
                    value = date.format(this.valueType).toString();
                }
            }

            return value;
        }

    }
};
