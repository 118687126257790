/** @module Validators:Common */

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import { register as registerAssets } from './assets';
import { register as registerCrossFields } from './cross-fields';
import { register as registerNetwork } from './network';
import { register as registerTime } from './time';
import { register as registerUniques } from './uniques';

/**
 * Registers custom validations
 *
 * @param {import('vue-i18n').default} i18n
 * @param {Services} services
 */
export function registerValidations(i18n, services) {
    Object.keys(rules).forEach(rule => {
        extend(rule, {
            ...rules[rule], // copies rule configuration
            message: (field, values) => {
                const tKey = `validations.overwrites.names.${field}`;
                values._field_ = i18n.te(tKey) ? i18n.t(tKey) : field;
                return messages[rule].replace(/{([^}]{1,9})}/g, (_, p) => {
                    return p in values ? values[p] : `{${p}}`;
                });
            }
        });
    });

    registerAssets(extend, i18n, services);
    registerCrossFields(extend, i18n);
    registerNetwork(extend, i18n, services);
    registerTime(extend, i18n, services);
    registerUniques(extend, i18n, services);
}
