// Utils
import * as d3 from 'd3';

/**
 * Builds an area chart with the given data
 *
 * @param {object[]} data the data for the chart
 * @param {object} options
 * @param {function} [options.x] given d in data, returns the (temporal) x-value
 * @param {function} [options.y] given d in data, returns the (quantitative) y-value
 * @param {function} [options.title] given d in data, returns the title text
 * @param {function} [options.defined] for gaps in data
 * @param {function} [options.curve] method of interpolation between points
 * @param {number} [options.marginTop = 0] top margin, in pixels
 * @param {number} [options.marginRight=16] right margin, in pixels
 * @param {number} [options.marginBottom=24] bottom margin, in pixels
 * @param {number} [options.marginLeft=24] left margin, in pixels
 * @param {number} [options.width=640] outer width, in pixels
 * @param {number} [options.height=400] outer height, in pixels
 * @param {function} [options.xType] the x-scale type
 * @param {array} [options.xDomain] [xmin, xmax]
 * @param {number[]} [options.xRange] [left, right]
 * @param {function} [options.yType] the y-scale type
 * @param {array} [options.yDomain] [ymin, ymax]
 * @param {number[]} [options.yRange] [bottom, top]
 * @param {function} [options.yFormat] a format specifier string for the y-axis
 * @param {string} [options.yLabel] a label for the y-axis
 * @param {string} [options.color=currentColor] stroke color of line
 * @param {string} [options.strokeLinecap=round] stroke line cap of the line
 * @param {string} [options.strokeLinejoin=round] stroke line join of the line
 * @param {number} [options.strokeWidth=4] stroke width of line, in pixels
 * @param {boolean} [options.withTooltips=false] whether to display a tooltip on the line
 *
 * @returns {SVGElement} the chart in SVG format
 *
 * @copyright 2021 Observable, Inc.
 * @license ISC
 * @see https://observablehq.com/@d3/area-chart
 * @modified SpotMe 12.2022
 */
export function AreaChart(data, {
    x = ([x]) => x, // given d in data, returns the (temporal) x-value
    y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
    title, // given d in data, returns the title text
    defined, // given d in data, returns true if defined (for gaps)
    curve = d3.curveLinear, // method of interpolation between points
    marginTop = 0, // top margin, in pixels
    marginRight = 0, // right margin, in pixels
    marginBottom = 24, // bottom margin, in pixels
    marginLeft = 24, // left margin, in pixels
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    xType = d3.scaleUtc, // type of x-scale
    xDomain, // [xmin, xmax]
    xRange = [marginLeft, width - marginRight], // [left, right]
    yType = d3.scaleLinear, // type of y-scale
    yDomain, // [ymin, ymax]
    yRange = [height - marginBottom, marginTop], // [bottom, top]
    yFormat, // a format specifier string for the y-axis
    yLabel, // a label for the y-axis
    color = 'currentColor', // fill color of area
    strokeLinecap = 'round', // stroke line cap of line
    strokeLinejoin = 'round', // stroke line join of line
    strokeWidth = 4, // stroke width of line, in pixels
    withTooltips = false
} = {}) {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const O = d3.map(data, d => d);
    const I = d3.range(X.length);

    // Compute which data points are considered defined.
    if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(data, defined);

    // Compute default domains.
    if (xDomain === undefined) xDomain = d3.extent(X);
    if (yDomain === undefined) yDomain = [0, d3.max(Y)];

    // Construct scales and axes.
    const xScale = xType(xDomain, xRange);
    const yScale = yType(yDomain, yRange);
    const xAxis = d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);
    const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);

    // Compute titles.
    if (title === undefined) {
        const formatDate = xScale.tickFormat(null, '%b %-d, %Y');
        title = i => `${formatDate(X[i])}\n${Y[i]}`;
    } else {
        const O = d3.map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
    }

    // Construct an area generator.
    const area = d3.area()
        .defined(i => D[i])
        .curve(curve)
        .x(i => xScale(X[i]))
        .y0(yScale(0))
        .y1(i => yScale(Y[i]));

    const line = d3.line()
        .defined(i => D[i])
        .curve(curve)
        .x(i => xScale(X[i]))
        .y(i => yScale(Y[i]));

    const svg = d3.create('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 12)
        .style('-webkit-tap-highlight-color', 'transparent')
        .style('overflow', 'visible');

    const defs = svg.append('defs');
    const gradient = defs.append('linearGradient')
        .attr('id', 'svgGradient')
        .attr('x1', '100%')
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%');

    gradient.append('stop')
        .attr('class', 'start')
        .attr('offset', '0%')
        .attr('stop-color', color)
        .attr('stop-opacity', .2);

    gradient.append('stop')
        .attr('class', 'end')
        .attr('offset', '100%')
        .attr('stop-color', color)
        .attr('stop-opacity', 0);

    svg.append('g')
        .attr('transform', `translate(${marginLeft},0)`)
        .call(yAxis)
        .call(g => g.select('.domain').remove())
        .call(g => g.selectAll('.tick line').clone()
            .attr('x2', width - marginLeft - marginRight)
            .attr('stroke-opacity', 0.1))
        .call(g => g.append('text')
            .attr('x', -marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel));

    svg.append('path')
        .attr('fill', 'url(#svgGradient)')
        .attr('d', area(I));

    svg.append('path')
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', strokeWidth)
        .attr('stroke-linejoin', strokeLinejoin)
        .attr('stroke-linecap', strokeLinecap)
        .attr('d', line(I));

    svg.append('g')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .call(xAxis);

    let tooltip;
    if (withTooltips) {
        svg
            .on('pointerenter pointermove', pointermoved)
            .on('pointerleave', pointerleft)
            .on('touchstart', event => event.preventDefault(), { passive: true });

        tooltip = svg.append('g')
            .attr('class', 'point-tooltip')
            .style('pointer-events', 'none')
            .attr('opacity', 0);

        tooltip.append('circle')
            .attr('fill', color)
            .attr('r', 6)
            .attr('cx', 0)
            .attr('cy', 0);
    }

    function pointermoved(event) {
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0]));
        tooltip.style('opacity', 1);
        tooltip.attr('transform', `translate(${xScale(X[i])},${yScale(Y[i])})`);

        const rect = tooltip.selectAll('rect')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('fill', '#2d3339');

        const text = tooltip.selectAll('text')
            .attr('fill', 'white')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('text')
            .call(text => text
                .selectAll('tspan')
                .data(`${title(i)}`.split(/\n/))
                .join('tspan')
                .attr('x', 0)
                .attr('y', (_, i) => `${i * 1.1}em`)
                .attr('font-weight', (_, i) => i ? null : 'bold')
                .text(d => d));

        const { y, width: w, height: h } = text.node().getBBox();
        text.attr('transform', `translate(${-w / 2},${15 - y})`);
        rect.attr('x', w / -2 - 8)
            .attr('y', 8)
            .attr('height', h + 16)
            .attr('width', w + 16);
        svg.property('value', O[i]).dispatch('input', { bubbles: true });
    }

    function pointerleft() {
        tooltip.style('opacity', null);
        svg.node().value = null;
        svg.dispatch('input', { bubbles: true });
    }

    return svg.node();
}
