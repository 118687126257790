
// Components
import RestoreEvent from 'ui/components/modals/RestoreEvent.vue';

// Utils
import { get } from 'lodash';

const EVENT_PROGRESS_MODAL_NAME = 'event-progress-modal';

export default {

    data() {
        return {
            canSeeArchived: this.$services.user.hasPermission('global_actions.archive_workspace')
        };
    },

    methods: {
        /**
         * Handler for event card click.
         * It checks the clicked event lifecycle and shows a modal in case
         * the event was archived.
         *
         * @param {Object} workspace the workspace to check the lifecycle of
         * @param {MouseEvent} $event the original mouse event
         */
        checkLifecycle(workspace, $event) {
            const lifecycleStage = get(workspace, 'lifecycle.stage');

            if (lifecycleStage !== 'archived') {
                return;
            }

            console.info('[EventsList] The clicked event was archived, notify the user.');

            $event.stopPropagation();
            $event.preventDefault();

            this.openRestoreModal(workspace);
        },

        /**
         * Shows progress modal for restoring an event
         */
        openProgressModal(workspace) {
            this.$modal.show(RestoreEvent,
                {
                    title: this.$i18n.t('events.archived.restore_title'),
                    event: workspace
                },
                {
                    name: EVENT_PROGRESS_MODAL_NAME,
                    classes: 'large',
                    height: 'auto',
                    reset: true,
                    clickToClose: false
                }
            );
        },

        /**
         * Shows modal for restoring an event
         */
        openRestoreModal(workspace) {
            this.$modal.show('dialog', {
                text: this.$i18n.t('events.archived.prompt'),
                buttons: [
                    {
                        title: this.$i18n.t('general.cancel'),
                        default: true,
                        class: 'btn'
                    },
                    {
                        title: this.$i18n.t('events.archived.restore'),
                        handler: () => {
                            this.$modal.hide('dialog');
                            this.openProgressModal(workspace);
                        },
                        class: 'btn btn-primary'
                    }
                ]
            });
        }
    }
};
