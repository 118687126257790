export default {
    methods: {
        async copyLink(url) {
            try {
                await navigator.clipboard.writeText(url);

                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.copy_success').toString(),
                    type: 'success'
                });
            } catch (error) {
                console.error('[copy-link] Could not copy URL:', error);
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.copy_error', [url]).toString(),
                    type: 'error',
                    duration: 15000
                });
            }
        }
    }
};
