/** @module Store */
import { capitalize } from 'libs/utils/string';

/**
 * Generate transparent getters of type prop: s => s.prop
 *
 * @param {Record<string, any>} initialState Vuex state intialization object
 * @returns {Record<string, any>} map of getter functions
 */
export function generateGetters(initialState) {

    const getterMap = {};
    const names = Object.keys(initialState);

    names.forEach(name => {
        getterMap[name] = state => state[name];
    });

    return getterMap;
}

/**
 * Generate transparent setter mutations of type
 *    setPropName: (state, payload) => state.propName = payload
 *
 * @param {Record<string, any>} initialState Vuex state intialization object
 * @returns {Record<string, any>} map of mutation functions
 */
export function generateSetters(initialState) {

    const setterMap = {};
    const names = Object.keys(initialState);

    names.forEach(name => {
        setterMap[`set${capitalize(name)}`] = (state, payload) => state[name] = payload;
    });

    return setterMap;
}
