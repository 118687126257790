// Constants
import { SESSION_CHECK_IN_METADATA_FIELDS } from 'libs/utils/constants';

/**
 * This is an abstract component for saving settings.
 * *
 * @category ui.mixins.workspaces
 * @exports SaveSettings
 */
export default {
    methods: {
        metadataFieldsToKeys(fields) {
            return fields.map(f => typeof f === 'string' ? f : f.value);
        },
        async save(eid, scope, valueToSave) {
            for (const field of SESSION_CHECK_IN_METADATA_FIELDS) {
                if (valueToSave[field]) {
                    valueToSave[field] = this.metadataFieldsToKeys(valueToSave[field]);
                }
            }

            try {
                console.info('[save-settings] Saving settings', scope, valueToSave);
                await this.$services.settings.saveSetting(eid, scope, valueToSave);

                this.$emit('saved');
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('session_check_in.messages.save_success').toString(),
                    type: 'success',
                    duration: 5000
                });
            } catch (error) {
                this.$notify({
                    group: 'flashes',
                    title: this.$i18n.t('general.error_occurred').toString(),
                    text: this.$i18n.t('session_check_in.messages.save_failure').toString(),
                    type: 'error',
                    duration: 5000
                });
            }
        }
    }
};
