// Utils
import Quill from 'quill';
import { isObject, isString, isEmpty } from 'lodash';

// Constants
const Image = Quill.import('formats/image');
const ASSET_PROTOCOL = 'fp-asset://';
const ASSET_CLASS_NAME = 'fp-asset-src';
const ASSETS_ALIGNMENTS = {
    'left-text': 'Left',
    'pull-left': 'Left (wrap around text)',
    'center-block': 'Center',
    'pull-right': 'Right (wrap around text)'
};
const ASSET_ALIGNMENT_CLASSES = Object.keys(ASSETS_ALIGNMENTS);
const ADDITIONAL_ATTRIBUTES = ['class', 'srcset', 'width', 'height'];

/**
 * This class is used to link backstage defined images
 * or external images.
 */
export default class BstgImage extends Image {
    /** @override */
    static create(value) {
        const node = super.create(value);
        let assetType = 'internal';

        if (isObject(value) && value.hasOwnProperty('srcset')) {
            value = value.srcset;
        }

        if (isString(value)) {
            let asset = value
                .replace(BstgImage.baseAssetUrl, '')
                .replace(ASSET_PROTOCOL, '');

            if (/^(http|https|\/\/)/.test(value) || value.startsWith('data:image')) {
                assetType = 'external';
            }

            if (asset.startsWith('/')) {
                asset = asset.substring(1, asset.length);
            }

            let srcset;

            if (asset.endsWith(' 2x')) {
                srcset = asset;
                asset = asset.split(', ').at(0);
            }
            value = {
                asset,
                assetType,
                align: 'left-text',
                srcset
            };
        }
        return BstgImage.setAssetAttributes(node, value);
    }

    /** @override */
    static formats(domNode) {
        const defaultFormats = super.formats(domNode);
        const additionalFormats = ADDITIONAL_ATTRIBUTES.reduce((formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});

        return Object.assign(defaultFormats, additionalFormats);
    }

    /** @override */
    format(name, value) {
        if (ADDITIONAL_ATTRIBUTES.includes(name)) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }

    /**
     * Given an HTML image node and a set of options, this method sets the proper HTML
     * attributes and returns the given node for chaining.
     *
     * @param {HTMLImageElement} node the element to set the attributes to
     * @param {Object} options the options to apply the attributes from
     * @param {String} options.asset the asset path
     * @param {String} options.assetType the type of the asset (internal or external)
     * @param {String} options.align the asset alignment in the text
     *
     * @returns {HTMLElement} the given node with the new attributes set
     */
    static setAssetAttributes(node, { assetType, asset, align, src, srcset }) {
        const prefix = assetType === 'internal' ? `${BstgImage.baseAssetUrl}/` : '';

        if (srcset) {
            node.setAttribute('srcset', srcset);
        }

        node.setAttribute('src', src || `${prefix}${asset}`);
        node.setAttribute('class', `${ASSET_CLASS_NAME} ${align}`);

        try {
            const img = new window.Image();
            img.onload = (event) => {
                node.setAttribute('width', event.target.width / 2);
                node.setAttribute('height', event.target.height / 2);
            };

            img.src = node.src;
        } catch (error) {
            console.error('[BstgImage] Could not set image sizes', error);
        }

        return node;
    }

    /**
     * Given a class list this method extracts the alignment CSS class.
     *
     * @param {String} classNames the CSS class words
     *
     * @returns {String} the alignment CSS class
     */
    static extractAlignmentClassName(classNames) {
        if (isEmpty(classNames)) {
            return;
        }

        return classNames.split(' ').find(c => ASSET_ALIGNMENT_CLASSES.includes(c));
    }

    /**
     * Given an URL this method returns the asset's type.
     *
     * @param {String} url the url string to extract the type from
     *
     * @returns {String} the type of the asset
     */
    static extractAssetTypeFromUrl(url) {
        if (url.startsWith(BstgImage.baseAssetUrl) || url.startsWith(ASSET_PROTOCOL)) {
            return 'internal';
        } else {
            return 'external';
        }
    }

    /**
     * Given an HTML image node, this method rebuilds the associated model.
     *
     * @param {HTMLImageElement} node the element to extract the model from
     *
     * @returns {Object} the backstage model linked to the asset
     */
    static extractAssetModelFromNode(node) {
        const src = node.getAttribute('src');
        const srcset = node.getAttribute('srcset');
        const asset = src
            .replace(`${BstgImage.baseAssetUrl}/`, '')
            .replace(ASSET_PROTOCOL, '');

        return {
            assetType: BstgImage.extractAssetTypeFromUrl(src),
            align: BstgImage.extractAlignmentClassName(node.getAttribute('class')),
            asset, srcset
        };
    }
}

BstgImage.baseAssetUrl = '';
