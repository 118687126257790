const WorkerType = window.SharedWorker ? SharedWorker : Worker;

export default {

    props: {
        event: {
            type: Object,
            required: true
        },

        worker: {
            type: WorkerType,
            required: true
        },

        scope: {
            type: String,
            required: true
        },

        pageWorker: {
            type: WorkerType,
            default: null
        },

        pageWorkerScope: {
            type: MessagePort,
            default: null
        },

        assetId: {
            type: String,
            default: null
        },

        fileTransform: {
            type: Function,
            default: null
        },

        attachmentNamesGenerator: {
            type: Function,
            default: null
        },

        fpType: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            workerScope: null,
            innerWorker: this.worker
        };
    },

    methods: {

        initWorker() {
            if (this.pageWorker) {
                this.innerWorker = this.pageWorker;
                this.workerScope = this.pageWorkerScope;

            } else {
                console.debug('[worker-starter] Initializing the worker');

                /** @type {WorkerOptions} */
                const workerOptions = {
                    name: `spotme-${this.name}-file-upload`,
                    credentials: 'include'
                };

                // Safari (at time of writing) does not support SharedWorkers, hence
                // we provide here a simple Worker instead.
                if (!window.SharedWorker && window.Worker) {

                    this.innerWorker = new Worker(`${window.BSTG.extLibs}/uploader${window.BSTG.scriptSuffix}`, workerOptions);
                    this.workerScope = this.innerWorker;

                } else if (window.SharedWorker) {

                    this.innerWorker = new SharedWorker(`${window.BSTG.extLibs}/uploader${window.BSTG.scriptSuffix}`, workerOptions);
                    this.workerScope = this.innerWorker.port;
                }

                if (!this.innerWorker) {
                    console.error('[worker-starter] Workers not supported.');
                } else {
                    this.innerWorker.onerror = (event) => console.error('[worker-starter] Could not initialize uploader worker.', event);
                    // needed for .addEventListener('message') to be triggered in child components
                    this.workerScope.onmessage = () => {};
                }
            }

            this.workerScope.addEventListener('message', this.handleWorkerMessage);
        },

        terminate(reason) {
            if (this.pageWorkerScope) {
                this.$emit('terminate', reason);
                return;
            }

            console.info('[CloudStorageFileUploader] Terminating the worker (%s)', reason);
            this.workerScope.postMessage({ command: 'kill' });

            if (this.workerScope instanceof MessagePort) {
                this.workerScope.close();
            } else {
                this.workerScope.terminate();
            }
            this.workerScope = null;
            this.innerWorker = null;
        },

        /**
         * Sends the files to the upload worker
         *
         * @param {FileList} files
         * @param {object} [params={}]
         * @param {object[]} [paramsArray=[]]
         */
        startUpload(files, params = {}, paramsArray = []) {
            const worker = this.innerWorker instanceof Worker ? this.innerWorker : this.innerWorker.port;

            if (typeof this.fileTransform === 'function') {
                files = this.fileTransform(files);
            }

            if (this.fpType) {
                params.fpType = this.fpType;
            }

            if (this.assetId) {
                params.id = this.assetId;
            }

            if (typeof this.attachmentNamesGenerator === 'function') {
                for (let i = 0; i < files.length; ++i) {
                    paramsArray[i] = {
                        ...paramsArray[i],
                        attachmentName: this.attachmentNamesGenerator(files[i])
                    };
                }
            }

            const payload = {
                command: 'upload',
                scope: this.scope,
                event: this.event,
                files,
                params,
                paramsArray
            };

            worker.postMessage(payload);

            if (typeof this.onAfterStart === 'function') {
                this.onAfterStart(payload);
            }
        }
    }
};
