/**
 * Throws an error if any argument isn't a string
 *
 * @param  {...any} args
 * @throws {Error}
 */
export function assertString(...args) {
    for (const str in args) {
        if (typeof args[str] !== 'string') {
            throw new Error(`${args[str]} is not a string`);
        }
    }
}

/**
 * Throws an error if any argument isn't a function
 *
 * @param  {...any} args
 * @throws {Error}
 */
export function assertFunction(...args) {
    for (const fn in args) {
        if (typeof args[fn] !== 'function') {
            throw new Error(`${args[fn]} is not a function`);
        }
    }
}

/**
 * Throws an error if any argument isn't an object
 *
 * @param  {...any} args
 * @throws {Error}
 */
export function assertObject(...args) {
    for (const obj in args) {
        if (typeof args[obj] !== 'object' || !isNaN(args[obj].length)) {
            throw new Error(`${args[obj]} is not an object`);
        }
    }
}
