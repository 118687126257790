// Components
import SessionGroups from 'shared/ui/components/form-components/registrations/SessionGroups.vue';

export default {
    components: { SessionGroups },

    props: {
        params: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            submitting: false,
            groups: [],
        };
    },

    async created() {
        await this.load();
    },

    methods: {
        async load() {
            throw new Error('must be implemented in the receiving component');
        },

        addNewGroup() {
            this.groups.forEach(group => delete group.new);
            this.groups.push({
                title: '',
                fp_ext_ids: [],
                new: true
            });

            this.$utils.dom.scrollToId('#session-reg-bottom');
        },

        async saveGroups(groups) {
            try {
                console.info('[groups.mixin] Saving groups', groups);
                this.submitting = true;
                await this.save(groups);

                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('session_check_in.messages.save_success').toString(),
                    type: 'success',
                    duration: 5000
                });

                await this.load();
            } catch (error) {
                console.error('[SessionInclusions] Could not save exclusion groups', error);

                this.$notify({
                    group: 'flashes',
                    title: this.$i18n.t('general.error_occurred').toString(),
                    text: this.$i18n.t('general.try_later').toString(),
                    type: 'error'
                });
            } finally {
                this.submitting = false;
            }
        },

        async save() {
            throw new Error('must be implemented in the receiving component');
        }
    }
};
