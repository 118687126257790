// Utils
import Quill from 'quill';

// Constants

/**
 * Attribute target
 * @const {String} ATTRIBUTE_STRICT_CONTEXTUAL_ESCAPING
 */
const ATTRIBUTE_STRICT_CONTEXTUAL_ESCAPING = 'sce';

/**
 * Backstage block $sce attribute value
 * @const {String} BSTG_BLOCK_STRICT_CONTEXTUAL_ESCAPING
 */
const BSTG_BLOCK_STRICT_CONTEXTUAL_ESCAPING = '__BSTG__';

/**
 * Backstage block HTML tag name
 * @const {String} BSTG_BLOCK_HTML_TAGNAME
 */
const BSTG_BLOCK_HTML_TAGNAME = 'DIV';


const Block = Quill.import('blots/block');

/**
 * This class is used to customize backstage blocks.
 */
export default class BstgBlock extends Block {
    constructor(domNode) {
        super(domNode);
        domNode.setAttribute(ATTRIBUTE_STRICT_CONTEXTUAL_ESCAPING, BSTG_BLOCK_STRICT_CONTEXTUAL_ESCAPING);
    }

    /**
     * Removes new lines and empty blocks
     *
     * @param {HTMLElement} element
     */
    static trimTrailingNewLines(element) {
        if (!(element instanceof HTMLElement)) {
            return;
        }

        const blocks = element.querySelectorAll('div');
        if (blocks.length) {
            for (const block of Array.from(blocks).reverse()) {
                if (block.innerText === '' && !block.innerHTML.includes('img')) {
                    element.removeChild(block);
                } else {
                    break;
                }
            }
        }
    }
}

BstgBlock.tagName = BSTG_BLOCK_HTML_TAGNAME;
