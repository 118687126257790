/**
 * This class is used for extending normal File
 * with extra functionalities and handy methods and field
 * that we can used for backstage.
 */
export default class PickedFile /* extends File */ {
    /**
     * Creates an extended version of File
     *
     * @param {File|Blob|Uint8Array[]} bits An Array of ArrayBuffer, ArrayBufferView, Blob, USVString objects, or a mix of any of such objects, that will be put inside the File. USVString objects are encoded as UTF-8.
     * @param {String} [name=file.name] A USVString representing the file name or the path to the file.
     * @param {Object} [options] An options object containing optional attributes for the file.
     * @param {String} [options.type] A DOMString representing the MIME type of the content that will be put into the file. Defaults to a value of "".
     * @param {Number} [options.lastModified] A number representing the number of milliseconds between the Unix time epoch and when the file was last modified. Defaults to a value of Date.now().
     * @param {String} [id] an optional ID to use for identify the file
     */
    constructor(bits, name = bits.name, options = { type: bits.type }, id) {
        const ary = bits instanceof Blob ? [bits] : bits;

        this.file = {};

        try {
            this.file = bits.file || new File(ary, name, options);
        } catch (e) {
            // This fails when `new File(...)` is called in IE 11...
            // ...yeah, no file API on IE 11
            this.file = new Blob(ary, options);
        }

        this.size = this.file.size || 0;

        this.name = name;
        this.type = bits.type;

        this.id = id || bits.id || `${bits.name}${bits.lastModified}`;
        this.validated = bits.validated === true || false;
        this.fresh = bits.hasOwnProperty('fresh') ? bits.fresh : true;
        this.editable = Boolean(this.size);
        this._edit = bits._edit === true || false;
        this.fitsRatio = false;

        try {
            this.url = bits.url || URL.createObjectURL(bits);
        } catch (e) {
            // provided bits are not suitable for url construction
        }
    }

    static toFileList(pickedFiles) {
        if (pickedFiles && Array.isArray(pickedFiles) && pickedFiles.every(file => file instanceof PickedFile)) {
            const dt = new DataTransfer();
            pickedFiles.forEach(file => dt.items.add(file.file));
            return dt.files;
        }
    }
}
