const NOT_SUPPORTED_PREFIX = 'no-';
const SUPPORTED_SUFFIX = '-supported';

/**
 * Checks if the desired property is supported by the browser.
 *
 * @param {String} prop the CSS property to check
 * @param {String} value the value of the property to check
 *
 * @return {Boolean} true if the property is supported, false otherwise.
 *
 * @private
 */
function cssPropertyValueSupported(prop, value) {
    const d = document.createElement('div');

    d.style[prop] = value;
    return d.style[prop] === value;
}

/**
 * Checks if `display` property value is supported and returns the associated CSS class.
 *
 * @return {String} the CSS class that suggests the support for the `display` value.
 *
 * @private
 */
function displaySupported(display) {
    let cssClass = `${display}${SUPPORTED_SUFFIX}`;

    if (!cssPropertyValueSupported('display', display)) {
        cssClass = `${NOT_SUPPORTED_PREFIX}${cssClass}`;
    }

    return cssClass;
}


/**
 * This directive checks for browser's CSS supported rules by adding classes
 * to the element it was used on.
 *
 * If the technology is *not* supported it adds a `no-` prefix to specific rule.
 *
 * @example
 * <div v-css-support>...</div>
 *
 * // Becomes
 * <div class="no-grid-supported">...</div>
 *
 * // Or
 * <div class="grid-supported">...</div>
 *
 * @category ui.utils.directive
 * @module CSSSupport
 */
export default {

    bind: (el) => {
        const classes = [];

        classes.push(displaySupported('grid'));
        classes.push(displaySupported('flex'));
        el.className += ` ${classes.join(' ')}`;
    }
};
