// Constants
import { VALID_HEX_COLOR_REGEX } from 'libs/utils/constants';

export function register(extend, i18n) {
    // Validates not editing in image picker
    extend('image_cropping_completed', {
        message: field => i18n.t('validations.image_cropping_completed', [field]).toString(),
        validate: value => Array.from((value || [])).filter(f => f._edit).length === 0
    });

    extend('hex_color', {
        message: field => i18n.t('validations.hex_color', [field]).toString(),
        validate: value => VALID_HEX_COLOR_REGEX.test(value)
    });
}

