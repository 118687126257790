// Utils
import { cloneDeep, isEmpty, isString, isObject } from 'lodash';

const READONLY_DEFAULTS = ['category_label', 'category_multiselect', 'category_values', 'enable_category'];

export default {
    computed: {
        org() {
            return this.organization || this.form?.organization;
        }
    },

    data() {
        return {
            timezones: [],
            servers: [],
            servers_locations: [],
            container_apps: [],
            exclude_readonly: false,
            types: {
                event_format: null,
                audience_type: null,
                event_scope: null,
                expected_attendance: '49'
            },
            form: {
                country: null,
                timezone: null,
                servers_location: null,
                privacy_documents: [],
                container_app: null,
                show_activation_codes: false, // This must stay false during the workspace creation
                force_anonymization: false,
                show_access_as: false,
                enable_category: false,
                category_label: null,
                category_values: [],
                category_multiselect: false,
                event_classification: null,
                event_custom_classification: null
            }
        };
    },

    watch: {
        org: {
            immediate: true,
            async handler() {
                if (!this.org) {
                    return;
                }
                await this.initializeApps();
            }
        }
    },

    async created() {
        if (!this.org.workspace_defaults) {
            this.org.workspace_defaults = {};
        }

        const readonlyFields = this.exclude_readonly ? READONLY_DEFAULTS : [];
        for (const field of Object.keys(this.org.workspace_defaults)) {
            if (isEmpty(this.form[field]) && !readonlyFields.includes(field)) {
                this.form[field] = cloneDeep(this.org.workspace_defaults[field]);
            }
        }

        readonlyFields.forEach(f => delete this.form[f]);

        // Timezones
        this.timezones = this.$utils.time.getAllZones();

        // Servers
        const orgName = this.$services.org.getNameFromId(this.org._id);

        this.servers = await this.$services.server.getOrgServers(orgName);
        for (const key of Object.keys(this.servers)) {
            const server = { label: this.servers[key], value: key };

            this.servers_locations.push(server);

            if (server.value === this.form.servers_location) {
                this.form.servers_location = server;
            }
        }
        if (!isObject(this.form.servers_location)) {
            this.form.servers_location = null;
        }
    },

    methods: {
        async initializeApps() {
            let apps = await this.$services.branding.getAllBrandings();
            let allApps = apps;

            if (this.org.allow_non_unique_email) {
                apps = apps.filter(app => app.allow_non_unique_email);
            }

            for (const app of apps) {
                app.label = `${app.springboard_name} (${app.fp_ext_id})`;
            }

            this.container_apps = apps;

            if (isObject(this.form.container_app)) {
                this.form.container_app = this.form.container_app?.fp_ext_id;
            }

            if (isString(this.form.container_app) && this.form.container_app.length) {
                const app = apps.find(app => app.fp_ext_id === this.form.container_app);
                const isDefaultAppHidden = allApps.some(app => app.fp_ext_id === this.form.container_app);
                if (app) {
                    this.form.container_app = app;
                } else if (this.org.allow_non_unique_email && isDefaultAppHidden) {
                    console.info(`[Branding app]: Default app (${this.form.container_app}) found, but it does not have group email enabled`);
                    this.form.container_app = undefined;
                } else {
                    console.info(`[Branding app]: Default app (${this.form.container_app}) not found, removing option to prevent user from changing it`);
                    // User do not have access to the preselected app.
                    // Instead of displaying a blank field, we pre-set the container_app an do not let user change it.
                    // To do so, we clear the list of available apps.
                    this.container_apps = [];
                    this.form.container_app = { fp_ext_id: this.form.container_app };
                }
            }
        }
    }
};
