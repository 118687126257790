

import { compact, clone, uniqBy, cloneDeep } from 'lodash';

export default {
    props: {
        addAtBeginning: {
            type: Boolean,
            default: false
        },

        items: {
            type: Array,
            default: () => []
        },

        limitSelectionTo: {
            type: Number,
            default: -1
        },

        preSelectedItems: {
            type: Array,
            default: () => []
        },

        rowClass: {
            type: String,
            default: null
        },

        selectable: {
            type: Boolean,
            default: false
        },

        showEmptyTable: {
            type: Boolean,
            default: true
        },

        singleSelectable: {
            type: Boolean,
            default: false
        },

        trackBy: {
            type: String,
            default: 'id'
        }
    },

    data() {
        return {
            animating: false,
            indeterminates: [],
            selectedItems: cloneDeep(this.preSelectedItems),
        };
    },

    computed: {
        /** @returns {boolean} */
        allSelected() {
            return this.currentPageItemIds.every(i => this.selectedItemsIds.includes(i));
        },

        /** @returns {string[]} */
        currentPageItemIds() {
            return this.items.map(i => i[this.trackBy]);
        },

        selectedItemsIds: {
            /** @returns {string[]} */
            get() {
                return this.selectedItems.map(item => item[this.trackBy]);
            },

            /** @param {string[]} value */
            set(value) {
                this.toggleContent(this.items.find(i => i[this.trackBy] === value));
            }
        }
    },

    watch: {
        items() {
            const previouslySelected = clone(this.selectedItemsIds);
            for (const [i, oldItem] of this.selectedItems.entries()) {
                if (previouslySelected.includes(oldItem[this.trackBy])) {
                    const freshItem = this.items.find(i => i[this.trackBy] === oldItem[this.trackBy]);
                    if (freshItem) {
                        this.$set(this.selectedItems, i, freshItem);
                    }
                }
            }
        },

        selectedItems() {
            /** @type {HTMLInputElement} */
            // @ts-ignore
            const bulkSelector = this.$refs.bulkToggler;
            if (bulkSelector) {
                bulkSelector.indeterminate = this.selectedItems.length && !this.allSelected;
            }
            this.$emit('selected-items', this.selectedItems);
        }
    },

    methods: {
        /**
         * Checks if the selection is valid
         *
         * @param {number} quantity
         *
         * @returns {boolean}
         */
        isSelectionValid(quantity) {
            if (this.limitSelectionTo > 0) {
                if (quantity > this.limitSelectionTo) {
                    this.$emit('overflow');
                    console.warn('[SmartTable] Tried to select more items than allowed %d/%d', quantity, this.limitSelectionTo);
                    return false;
                }
            }

            return true;
        },

        /**
         * Selects or unselects all the items loaded so far
         */
        toggleAll() {
            if (this.allSelected) {
                this.selectedItems = this.selectedItems.filter(i => !this.currentPageItemIds.includes(i[this.trackBy]));
            } else {
                // If we have addAtBeginning, we do a double reverse to
                // - preserve the order of already selected items
                // - still add the new items at the beginning.
                const selectedItems = this.addAtBeginning ? [...this.selectedItems].reverse() : this.selectedItems;
                const items = compact(uniqBy([...selectedItems, ...this.items], this.trackBy));
                if (this.isSelectionValid(items.length)) {
                    this.selectedItems = this.addAtBeginning ? items.reverse() : items;
                }
            }

            this.indeterminates = [];
        },

        /**
         * Unselects all the items loaded so far
         *
         * @public
         */
        unselectAll() {
            this.selectedItems = [];
        },

        /**
         * Toggles the specified content.
         *
         * @param {Object} content the content to select
         */
        toggleContent(content) {
            this.clearIndeterminate(content);
            if (!this.selectable && !this.singleSelectable) return;

            if (this.selectable) {
                const index = this.selectedItems.findIndex(i => content[this.trackBy] === i[this.trackBy]);

                if (index === -1) {
                    if (this.isSelectionValid(this.selectedItems.length + 1)) {
                        if (this.addAtBeginning) {
                            this.selectedItems.unshift(content);
                        } else {
                            this.selectedItems.push(content);
                        }
                    }
                } else {
                    this.selectedItems.splice(index, 1);
                }
            } else if (this.singleSelectable) {
                this.selectedItems = [content];
            }
        },

        /**
         * Checks if the given item is selected
         *
         * @param {object} item
         *
         * @returns {boolean}
         */
        isSelected(item) {
            return this.selectedItemsIds.includes(item[this.trackBy]);
        },

        /**
         * Checks if the given item verses in a indeterminate state
         *
         * @param {object} item
         *
         * @returns {boolean}
         */
        isIndeterminate(item) {
            return this.indeterminates.includes(item[this.trackBy]);
        },

        /**
         * Removes the given item from the indeterminates set
         *
         * @param {object} item
         */
        clearIndeterminate(item) {
            this.indeterminates = this.indeterminates.filter(i => i !== item[this.trackBy]);
        }
    }
};
