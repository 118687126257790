// Main Vue runtime
import Vue from 'vue';

// Utils
import { createLocales } from 'ui/core/locales';
import { createStore } from './core/store';
import { registerValidations } from 'shared/ui/validators/common';

import Utils from 'ui/core/utils';
import Services from 'ui/core/services';
import Plugins from 'ui/core/plugins';
import AngularRouter from 'ui/plugins/angular-router';

// Apps root
import Page from './pages/Page.vue';
import MainMenu from './components/MainMenu.vue';
import Noty from 'shared/ui/components/Noty.vue';
import Checklist from './components/Checklist.vue';

// Core plugins registration
Vue.use(Utils, { globalHandle: window.BSTG });
Vue.use(Plugins);

const store = createStore(Vue);
const i18n = createLocales(Vue);

const baseOptions = { i18n, store };

/**
 * StartVue is called from `VueWrapperCtrl` Angular Controller when a
 * page that wraps Vue is loaded.
 */
window.StartVue = (pageComponent, $route, routeParams, ngRootScope, $location, subComponentProps) => {
    console.info('[UI] (Re)starting UI', subComponentProps);

    Vue.use(AngularRouter, { routeParams, ngRootScope, $location });
    Vue.use(Services, { globalHandle: window.BSTG });

    registerValidations(i18n, window.BSTG.services);

    const routeDescriptor = $route.current.$$route;
    const blueprintMetadata = routeDescriptor.blueprintMetadata;
    const currentRoute = $route.current ? $route.current.$$route : null;
    const props = {
        user: ngRootScope.user,
        event: ngRootScope.event,
        blueprint: blueprintMetadata,
        currentRoute,
        component: pageComponent
    };

    let subHandlers;
    if (subComponentProps?.handlers) {
        subHandlers = subComponentProps.handlers;
        delete subComponentProps.handlers;
    }
    return new Vue({
        el: '#backstage-app, .vue-widget',
        name: 'Backstage',
        data() {
            return {
                // We send additional properties here so that we can pass props to
                // child components. All extra props passed through `props` are swollowed by
                // the strict props declaration in Page.vue

                // Since we might have a list of dynamic properties (depending on the component)
                // we want to render, we pass them through `subProps`
                subProps: subComponentProps,
                subHandlers
            };
        },
        beforeCreate() {
            this.$store.dispatch('liveSession/setServices', { services: this.$services, app: 'Backstage' });
        },
        render: h => h(Page, { props }),
        extend: (options) => Vue.extend({ ...baseOptions, ...options }),
        ...baseOptions
    });
};

/**
 * BuildMenu is called from `VueMainMenu` Angular Controller the first time
 * the page is loaded.
 */
window.BuildMenu = async (routeParams, ngRootScope, $location, modules, $route) => {
    console.info('[BackstageMenu] init');

    Vue.use(AngularRouter, { routeParams, ngRootScope, $location });
    Vue.use(Services, { globalHandle: window.BSTG });

    registerValidations(i18n, window.BSTG.services);

    const currentRoute = $route.current ? ($route.current.$$route || {}) : {};
    const props = { user: ngRootScope.user, event: ngRootScope.event, modules, appName: window.BSTG.shortAppName, currentRoute };

    window.BSTG.menu = new Vue({
        el: '#main-navigator',
        name: 'BackstageMenu',
        render: h => h(MainMenu, { props }),
        extend: (options) => Vue.extend({ ...baseOptions, ...options }),
        ...baseOptions
    });

    if (ngRootScope.event && !ngRootScope.hideChecklist) {
        window.BSTG.checklist = new Vue({
            el: '#checklist',
            name: 'BackstageChecklist',
            render: h => h(Checklist, { props: { eventId: ngRootScope.event.id } }),
            i18n
        });
    }

    window.initNotifications();
};

window.initNotifications = () => {
    // This is needed because we do not have (yet) a unique instance of Vue,
    // but at some stage it will be gone
    if (!window.BSTG.noty) {
        window.BSTG.noty = new Vue({
            el: '#noty-app',
            name: 'Notifier',
            render: h => h(Noty),
            i18n
        });
    }
};

window.initApp();
