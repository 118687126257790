// Utils
import { memoize } from 'lodash';

// Constants
const MINIMUM_PATH_LENGTH = 2;
const MINIMUM_CONTAINER_NAME_LENGTH = 2;

const validateBrandingUrl = (i18n, services) => memoize(async (value, [eid, brand]) => {
    try {
        if (!eid && !brand) throw new Error('Must specify an event ID or a branding');
        if (!value || value.length < MINIMUM_PATH_LENGTH) return i18n.t('validations.url_path_too_short', [MINIMUM_PATH_LENGTH]);

        const { pax, branding } = services;
        let exists;

        if (pax && eid) {
            exists = await pax.registrationUrlExists(eid, value);
        } else if (branding && brand) {
            exists = await branding.registrationUrlExists(brand, value);
        } else {
            throw new Error('No suitable service to verify validity has been found.');
        }

        if (exists) {
            return i18n.t('validations.reg_url_exists').toString();
        }

        return !exists;

    } catch (error) {
        console.error('[Validators] Could not validate URL path', error.message);

        return i18n.t('validations.url_server_error');
    }
});

function validateContainerName(i18n, services) {
    return async (value) => {
        try {
            if (!value || value.length < MINIMUM_CONTAINER_NAME_LENGTH) return i18n.t('validations.container_name_too_short', [MINIMUM_CONTAINER_NAME_LENGTH]);

            const exists = await services.branding.brandExists(value);
            if (exists) {
                return i18n.t('validations.container_exists').toString();
            }
            return !exists;

        } catch (error) {
            console.error('[Validators] Could not validate container name', error);

            return i18n.t('validations.container_server_error');
        }
    };
}

export function register(extend, i18n, services) {
    if (services.pax || services.branding) {
        extend('unique_branding_url', validateBrandingUrl(i18n, services));
    }

    if (services.branding) {
        extend('unique_container', validateContainerName(i18n, services));
    }
}
