import { get as _get } from 'lodash';

const insert = (buffer, max) => item => {
    buffer.unshift(item);

    if (buffer.length > max) {
        buffer.pop();
    }
};

const append = (buffer, max) => item => {
    buffer.push(item);

    if (buffer.length > max) {
        buffer.shift();
    }
};

const remove = buffer => predicate => {
    let i = buffer.length;
    const removed = [];
    while (i--) {
        if (predicate(buffer[i], i)) {
            removed.push(buffer.splice(i, 1)[0]);
        }
    }

    return removed;
};

const update = buffer => (predicate, updateHandler) => {
    for (const i in buffer) {
        if (predicate(buffer[i], i)) {
            buffer[i] = updateHandler(buffer[i]);
        }
    }
};

const get = buffer => i =>
    isNaN(i)
        ? [...buffer]
        : _get(buffer, i, null);

const clear = buffer => () =>
    buffer.length = 0;

class MaxBuffer {
    /**
     * @param {number} max default is 100
     */
    constructor(max = 100) {
        const _buffer = [];

        this.insert = insert(_buffer, max);
        this.append = append(_buffer, max);
        this.get = get(_buffer);
        this.clear = clear(_buffer);
        this.remove = remove(_buffer);
        this.update = update(_buffer);

        Object.defineProperties(this, {
            length: {
                get: () => _buffer.length
            },
            maxLength: {
                get: () => max
            }
        });
    }
}

export {
    MaxBuffer
};
