/**
 * This mixin is used to provide the action methods for a content
 *
 * @category content-hub.mixins
 */
import { get } from 'lodash';
import { downloadFile } from 'libs/utils/helpers/download';


export default {

    methods: {
        /**
         * Change the published status of a single content
         *
         * @param {Object} content the content being (un)published
         */
        async togglePublish(content) {
            const publish = !content.published;
            const messageKey = publish ? 'published' : 'unpublished';
            const countChange = publish ? 1 : -1;
            let result, message, messageType;
            try {
                result = await this.$services.content.updateContent(this.contentHub.id, content.id, {
                    published: publish
                });
            } catch (err) {
                message = `contents.messages.${messageKey}_error`;
                messageType = 'error';
            }
            if (result) {
                this.updatePublishCount(countChange);
                content.published = publish;

                message = `contents.messages.${messageKey}`;
                messageType = 'success';
            }
            if (message) {
                const subject = content.title;
                this.$notify({
                    group: 'flashes',
                    clean: true
                });
                this.$notify({
                    group: 'flashes',
                    text: `${subject} ${this.$i18n.t(message).toString()}`,
                    type: messageType,
                    duration: 5000
                });
            }
        },

        /**
         * Given content item this method returns its type
         *
         * @param {object} item the item to get the file type of
         *
         * @returns {string} the content item file type
         */
        getTypeClass(item) {
            return this.$services.content.getTypeClass(item);
        },

        /**
         * Download the asset
         */
        download(content) {
            const eid = get(this.contentHub, 'id') || get(this.params.event, 'id');
            let fileName = '';
            // exception for live stream VOD where the attachment name is `{{liveStreamId}}.mp4`
            if (content.fp_type === 'content-video' && content.source_doc_id) {
                fileName = `${content.source_doc_id}.mp4`;
            }
            const downloadUrl = this.$services.contentHub.getDownloadUrl(eid, content.id, fileName);
            downloadFile(downloadUrl);
        },

        getFileNameFromUrl(url) {
            const urlParts = url.split('/');
            return urlParts.pop();
        }
    }
};
