export default {
    props: {
        event: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
            default: () => ({})
        },

        saveSpy: {
            type: Number,
            default: 0
        },

        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            active: true,
            previousSpy: this.saveSpy
        };
    },

    computed: {
        innerValue: {
            /** @returns {object} */
            get() {
                return this.value;
            },

            /** @param {object} value */
            set(value) {
                this.$emit('input', value);
            }
        }
    },

    watch: {
        async saveSpy(value) {
            if (this.active && value !== this.previousSpy) {
                if (await this.$refs.form.validate()) {
                    await this.saveChanges();
                    this.$refs.form.reset();
                    this.previousSpy = value;
                }
            }
        }
    },

    activated() {
        this.active = true;
        this.previousSpy = this.saveSpy;
    },

    deactivated() {
        this.active = false;
    },

    methods: {
        /**
         * Saves every changes occurred in the component
         */
        async saveChanges(updateMetadata = false) {
            this.$emit('save', updateMetadata);
        },

        /**
         * Sets the value on the inner object in order to avoid
         * direct tampering of the `value` prop.
         *
         * @param {string} property the property to set/update
         * @param {any} value the value to set
         */
        setValue(property, value) {
            this.$set(this.innerValue, property, value);
            this.$emit('input', this.innerValue);
        }
    }
};
