import { get } from 'lodash';

export default {

    data() {
        return {
            error: null,
            jobProgressUrl: null,
            jobProgressPath: this.$const.CREATE_WORKSPACE_QUEUE_JOB_RESULT_PATH,
            progress: 0,
            trackingId: null,
        };
    },

    methods: {

        /**
         * start the webinar creation on backend
         *
         * @param {Object} data the payload needed to create the workspace
         * @param {Object} files the files needed to create the workspace
         *
         * @returns {Promise}
         */
        async startCreation(data, files) {
            console.info('[CreateWebinar] Collected data:', data);

            await this.startTracking(data);

            const rootNode = await this.$services.server.getRootForGroup(data.serverGroup);
            data.node = rootNode ? rootNode : 'unknown';

            const { jobId, error } = await this.registerJob(data, files);

            if (error || !jobId) {
                // Something went wrong, stop here.
                console.error("[CreateWebinar] Can't register the job.", error);
                this.error = error;
                return;
            }

            this.jobProgressUrl = this.$utils.url.getBackstageURL(rootNode, this.jobProgressPath);

            try {
                const minutesToTimeout = 15;
                const interval = 1500;
                const { eid, live_stream_id } = await this.$services.jobs.waitFor({
                    jobId,
                    statusPath: this.jobProgressUrl,
                    resultPath: this.jobProgressUrl,
                    updateUIProgress: this.updateProgress,
                    interval,
                    minutesToTimeout
                });

                const webinarUrl = this.$services.webinar.getWebinarUrl({
                    node: rootNode,
                    id: eid,
                    live_stream_id
                });

                // Everything gone right, redirect the user to the newly created event.
                await this.stopTracking(eid);
                this.$router.redirect(webinarUrl);
            } catch (error) {
                console.error('[CreateWebinar] Could not create webinar', error);
                this.$services.crashReporting.captureException(error);
                await this.setError((error || {}).error || 'wizards.create_workspace.errors.job_creation_failed');
            }
        },

        /**
         * Stores the create webinar job on the backend job runner, if something goes wrong
         * during this process shows an error and exits.
         *
         * @private
         *
         * @param {Object} data the payload needed to create the workspace
         * @param {Object} files the files needed to create the workspace
         *
         * @returns {Promise<Object>} the job registration response
         */
        async registerJob(data, files) {
            const response = await this.$services.webinar.createWebinar(data, files);
            const { error, message } = response;

            if (!error) {
                return response;
            }

            const msg = get(message, 'error') || error;

            return { error: msg };
        },

        /**
         * Updates the creation process progress.
         *
         * @private
         *
         * @param {Number} progress the current progress
         */
        updateProgress(progress) {
            this.progress = progress;
        },

        /**
         * Starts the workspace creation process tracking
         *
         * @private
         *
         * @param {Object} data the wizard collected data
         */
        async startTracking(data) {
            const payload = {
                node: data.node,
            };

            this.trackingId = this.$services.track.startTracking('webinar_creation', payload);
        },

        /**
         * Interrupts the workspace creation process tracking
         *
         * @private
         *
         * @param {String} eid the just created event ID
         * @param {String} [error] an optional error message
         */
        async stopTracking(eid, error) {
            const payload = {
                eid: eid,
                ok: true,
            };

            if (error) {
                payload.ok = false;
                payload.error = error;
            }

            await this.$services.track.endTracking(this.trackingId, payload);
        },

        /**
         * Sets the error and displays it to the user.
         *
         * @private
         *
         * @param {String} error the eventual occurred error.
         */
        async setError(error) {
            this.error = error;

            await this.stopTracking(null, this.error);
        },

        getInteractivityFromData(formData) {
            const data = get(formData, 'interactivityOptions', formData);
            return {
                clapping: get(data, 'clappingEnabled'),
                who_is_watching: get(data, 'whoIsWatchingEnabled'),
                polls_enabled: get(data, 'pollsEnabled'),
                qna: get(data, 'qnaEnabled'),
                qna_moderation_enabled: get(data, 'qnaModerationEnabled'),
                qna_anonymous_enabled: get(data, 'qnaAnonymousEnabled'),
                interprefy_project_id: get(data, 'interprefyProjectId'),
                interprefy_enabled: get(data, 'interprefyEnabled')
            };
        }
    },
};
