/** @module HtmlEditorConstants */

// Links

/**
 * Internal link protocol
 * @const {String} INTERNAL_LINK_PROTOCOL
 */
export const INTERNAL_LINK_PROTOCOL = 'open-document://';

/**
 * External link target
 * @const {String} EXTERNAL_LINK_TARGET
 */
export const EXTERNAL_LINK_TARGET = '_blank';

/**
 * Link type external
 * @const {String} LINK_TYPE_EXTERNAL
 */
export const LINK_TYPE_EXTERNAL = 'external';

/**
 * Link type internal
 * @const {String} LINK_TYPE_INTERNAL
 */
export const LINK_TYPE_INTERNAL = 'internal';

// Attributes

/**
 * Attribute anchor
 * @const {String} ATTRIBUTE_ANCHOR
 */
export const ATTRIBUTE_ANCHOR = 'href';

/**
 * Attribute class
 * @const {String} ATTRIBUTE_CLASS
 */
export const ATTRIBUTE_CLASS = 'class';

/**
 * Attribute target
 * @const {String} ATTRIBUTE_TARGET
 */
export const ATTRIBUTE_TARGET = 'target';

// CSS Classes

/**
 * Internal link classname
 * @const {String} CLASSNAME_INTERNAL_LINK
 */
export const CLASSNAME_INTERNAL_LINK = 'open-document-link';

/**
 * Backstage list classname
 * @const {String} CLASSNAME_BSTG_LIST
 */
export const CLASSNAME_BSTG_LIST = 'ql-list';
