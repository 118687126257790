import * as assertions from 'libs/utils/assertions';
import * as assets from 'libs/utils/assets';
import * as attachments from 'libs/utils/attachments';
import * as browser from 'libs/utils/browser';
import * as calendar from 'libs/utils/calendar';
import * as charts from 'libs/utils/charts';
import * as collections from 'libs/utils/collections';
import * as constants from 'libs/utils/constants';
import * as controls from 'libs/utils/controls';
import * as dom from 'libs/utils/dom';
import * as image from 'libs/utils/image';
import * as locales from 'libs/utils/locales';
import * as maxBuffer from 'libs/utils/max-buffer';
import * as metadata from 'libs/utils/metadata';
import * as modals from 'libs/utils/modals';
import * as modules from 'libs/utils/modules';
import * as numbers from 'libs/utils/numbers';
import * as objects from 'libs/utils/objects';
import * as os from 'libs/utils/os';
import * as pax from 'libs/utils/pax';
import * as queue from 'libs/utils/queue';
import * as serialization from 'libs/utils/serialization';
import * as strings from 'libs/utils/string';
import * as tables from 'libs/utils/tables';
import * as time from 'libs/utils/time';
import * as timer from 'libs/utils/timer';
import * as uiTheme from 'libs/utils/ui-theme';
import * as url from 'libs/utils/url';
import * as workspaces from 'libs/utils/workspaces';

import 'libs/utils/typedefs';

export const utils = {
    assertions,
    assets,
    attachments,
    browser,
    calendar,
    charts,
    collections,
    controls,
    dom,
    image,
    locales,
    maxBuffer,
    metadata,
    modals,
    modules,
    numbers,
    objects,
    os,
    pax,
    queue,
    serialization,
    strings,
    tables,
    time,
    timer,
    uiTheme,
    url,
    workspaces
};

export default {
    install(Vue, options) {
        if (options && options.extend) {
            Object.assign(utils, options.extend);
        }

        /**
         * Vue plugin that wraps all system utils.
         */
        Vue.prototype.$utils = utils;
        Vue.prototype.$const = constants;

        if (window.BSTG) {
            window.BSTG.utils = utils;
        }
    }
};
