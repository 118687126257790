export class InteractivityOptions {
    constructor({
        clappingEnabled = false,
        whoIsWatchingEnabled = false,
        pollsEnabled = false,
        qnaEnabled = false,
        qnaModerationEnabled = false,
        qnaAnonymousEnabled = false,
        interprefyProjectId = null,
        interprefyEnabled = false
    } = {}) {
        /** @type {boolean} */
        this.clappingEnabled = clappingEnabled;

        /** @type {boolean} */
        this.whoIsWatchingEnabled = whoIsWatchingEnabled;

        /** @type {boolean} */
        this.pollsEnabled = pollsEnabled;

        /** @type {boolean} */
        this.qnaEnabled = qnaEnabled;

        /** @type {boolean} */
        this.qnaModerationEnabled = qnaModerationEnabled;

        /** @type {boolean} */
        this.qnaAnonymousEnabled = qnaAnonymousEnabled;

        /** @type {string} */
        this.interprefyProjectId = interprefyProjectId;

        /** @type {boolean} */
        this.interprefyEnabled = interprefyEnabled;
    }
}

export class Webinar {
    constructor({
        orgId = undefined,
        name = undefined,
        branding = undefined,
        description = undefined,
        startTime = undefined,
        duration = undefined,
        timezone = undefined,
        serversLocation = undefined,
        brandColor = undefined,
        interactivityOptions = new InteractivityOptions(),
        registrationPageFields = [],
        background = undefined,
        icon = undefined,
        banner = undefined
    }) {
        /** @type {string} */
        this.orgId = orgId;

        /** @type {string} */
        this.name = name;

        /** @type {string} */
        this.branding = branding;

        /** @type {string} */
        this.description = description;

        /** @type {object} */
        this.startTime = startTime;

        /** @type {number} */
        this.duration = duration;

        /** @type {string} */
        this.timezone = timezone;

        /** @type {string} */
        this.serversLocation = serversLocation;

        /** @type {string} */
        this.brandColor = brandColor;

        /** @type {InteractivityOptions} */
        this.interactivityOptions = interactivityOptions;

        /** @type {object[]} */
        this.registrationPageFields = registrationPageFields;

        /** @type {object} */
        this.background = background;

        /** @type {object} */
        this.icon = icon;

        /** @type {object} */
        this.banner = banner;
    }
}
