/** @module Helpers:Image */

// Utils
import Cropper from 'cropperjs';

/**
 * Loads an instance of CropperJS starting from the given image tag
 *
 * @param {HTMLImageElement} img the image to load in the Cropper
 * @param {Object} [config] the CropperJS configuration object
 *
 * @returns {Promise<Cropper>} the promise of an initialized CropperJS instance
 */
export async function loadCropper(img, config) {
    if (!img.src) {
        throw new Error('[helpers/image] Image element must define a `src` attribute');
    }

    return new Promise(resolve => {
        const image = new Image();

        image.addEventListener('load', () => {
            const cropper = new Cropper(img, Object.assign({
                aspectRatio: 1,
                viewMode: 1,
                autoCropArea: 1,
                background: false
            }, config, {
                ready: () => {
                    resolve(cropper);
                }
            }));
        }, false);

        image.src = img.src;
    });
}

/**
 * Transforms the canvas content into a blob object.
 *
 * @param {HTMLCanvasElement} canvas the canvas to transfor into blob
 * @param {String} type the image mime type
 *
 * @returns {Promise.<Blob>} the promise of a blob representation of the canvas
 */
export function canvasToBlob(canvas, type) {
    return new Promise(resolve => {
        /**
         * For some reason, in the test event the `toBlob` function
         * is not awaited to be resolved, resulting in tests timeout.
         *
         * To avoid this we fallback to the polyfill (that, by the way,
         * is there for IE).
         */
        if (canvas.toBlob && window.BSTG.env !== 'test') {
            return canvas.toBlob(resolve, type);
        }

        const dataUrl = canvas.toDataURL(type);
        const binStr = atob(dataUrl.split(',')[1]);
        const arr = new Uint8Array(binStr.length);

        for (let i = 0; i < binStr.length; i++) {
            arr[i] = binStr.charCodeAt(i);
        }

        resolve(new Blob([arr], { type }));
    });
}

/**
 * @typedef {Object} ImageDimensions
 *
 * @property {Number} width the image width
 * @property {Number} height the image height
 */

/**
 * @param {String} imageUrl the URL to the image
 *
 * @returns {Promise<ImageDimensions>} the dimensions of the image
 */
export function getImageDimensions(imageUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();

        // eslint-disable-next-line
        image.addEventListener('load', function() {
            const dimensions = {
                width: this.width,
                height: this.height
            };
            return resolve(dimensions);
        });

        image.addEventListener('error', () => {
            return reject(new Error(`Unable to load image from: ${imageUrl}`));
        });

        image.src = imageUrl;
    });
}
