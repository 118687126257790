export default {
    methods: {
        /**
         * Opens a prompt dialog.
         *
         * @param {String} title the title to prompt to the user
         * @param {String} text the text to prompt to the user
         * @param {String} okButtonText the text for the positive answer
         * @param {Function} action the action to perform in positive answer
         */
        prompt(title, text, okButtonText, action) {
            this.$modal.show('dialog', {
                title,
                text,
                buttons: [
                    {
                        title: this.$i18n.t('general.cancel'),
                        default: true,
                        class: 'btn'
                    },
                    {
                        title: okButtonText,
                        handler: () => { action(); this.$modal.hide('dialog'); },
                        class: 'btn btn-danger'
                    }
                ]
            });
        },

        /**
         * Performs the given action and shows related notifications.
         *
         * @param {Function} action the action to perform
         * @param {String} translationKey the key to use for i18n texts
         */
        async performAction(action, translationsKey) {
            try {
                await action(this.organization._id, this.email);

                this.$notify({
                    group: 'flashes',
                    text: this.t(`${translationsKey}_success`).toString(),
                    type: 'success',
                });
            } catch (error) {
                this.$notify({
                    group: 'flashes',
                    text: this.t(`${translationsKey}_failure`, [error.message]).toString(),
                    type: 'error'
                });
            }
        }
    }
};
