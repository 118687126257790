/**
 * This module provides utility functions for working with the Fabric.js library.
 * @module fabricUtils
 */

import * as fabric from 'fabric';
import { isArray, omit } from 'lodash';
import { clean } from 'libs/utils/objects';
import { v4 as uuid } from 'uuid';

import { SnappyGroup } from './fabric/snappy-group';
import { SnappyText } from './fabric/snappy-text';
import { SnappyImage } from './fabric/snappy-image';

/**
 * The corner style for fabric objects.
 * @constant
 * @type {string}
 */
const CORNER_STYLE = 'circle';

/**
 * The default properties for text objects.
 * @constant
 * @type {object}
 */
const TEXTS_DEFAULTS = {
    angle: 0,
    content: undefined,
    cornerStyle: CORNER_STYLE,
    fill: '#000000',
    fontFaceId: undefined,
    fontFamily: 'system-ui',
    fontSize: undefined,
    fontStyle: 'normal',
    fontWeight: 400,
    height: undefined,
    id: undefined,
    left: undefined,
    linethrough: false,
    text: undefined,
    textAlign: 'left',
    top: undefined,
    width: undefined
};

/**
 * The default properties for SVG objects.
 * @constant
 * @type {object}
 */
const SVG_DEFAULTS = {
    backgroundColor: '#EFEFEF',
    cornerStyle: CORNER_STYLE,
    height: undefined,
    id: undefined,
    left: undefined,
    top: undefined,
    width: undefined,
};

/**
 * The default properties for IMAGE objects.
 * @constant
 * @type {object}
 */
const IMAGE_DEFAULTS = {
    cornerStyle: CORNER_STYLE,
    height: undefined,
    id: undefined,
    left: undefined,
    top: undefined,
    width: undefined,
};

/**
 * The map of object types to their default properties.
 * @constant
 * @type {object}
 */
const DEFAULTS_MAP = {
    'text': TEXTS_DEFAULTS,
    'svg': SVG_DEFAULTS,
    'image': IMAGE_DEFAULTS
};

/**
 * The default properties for the canvas boundaries.
 * @constant
 * @type {object}
 */
const BOUNDARIES_DEFAULTS = {
    fill: 'transparent',
    stroke: '#E3E6E8',
    selectable: false
};

/**
 * Creates a new fabric canvas.
 *
 * @param {HTMLCanvasElement} canvas - The HTML canvas element to create the canvas on.
 * @param {object} sizes - The width and height of the canvas.
 * @param {object} [eventHandlers] - Optional event handlers for canvas events.
 * @param {function} [eventHandlers.cleared] - The event handler for the selection cleared event.
 * @param {function} [eventHandlers.modified] - The event handler for the object modified event.
 *
 * @returns {fabric.Canvas} The newly created fabric canvas.
 */
export function newCanvas(canvas, { width, height, backgroundImage }, eventHandlers = {}) {
    const c = new fabric.Canvas(canvas, { width, height, backgroundImage });

    c.on('mouse:wheel', (opt) => {
        const delta = opt.e.deltaY;
        let zoom = c.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;

        c.zoomToPoint({ x: width / 2, y: height / 2 }, zoom);
        c.setZoom(zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
    });

    if (typeof eventHandlers.cleared === 'function') {
        c.on('selection:cleared', (event) => {
            const { deselected: objects } = event;
            eventHandlers.cleared(extractConfig(objects));
        });
    }

    if (typeof eventHandlers.modified === 'function') {
        c.on('object:modified', (event) => {
            if (event.target instanceof fabric.ActiveSelection) {
                const objects = c.getActiveObject().getObjects();
                eventHandlers.modified(extractConfig(objects).forEach(o => {
                    o.top += event.target.top;
                    o.left += event.target.left;
                }));
            }
        });
    }

    console.warn('[utils/helpers/fabric] Remove C when ready');
    window.C = c;
    return c;
}

/**
 * Creates a new fabric rectangle representing the boundaries of the canvas.
 *
 * @param {object} sizes - The width and height of the canvas.
 *
 * @returns {fabric.Rect} The fabric rectangle representing the canvas boundaries.
 */
export function canvasBoundaries({ width, height }) {
    return new fabric.Rect({ ...BOUNDARIES_DEFAULTS, width, height });
}

/**
 * Builds a fabric image object from the given URL.
 *
 * @param {string} url - The URL of the image.
 *
 * @returns {Promise<import('fabric').FabricImage>} A promise that resolves with the fabric image object.
 */
export async function buildBackground(url) {
    const backgroundImage = await fabric.FabricImage.fromURL(url);
    backgroundImage.set({
        caching: true,
        /**
         * To break the aspect ratio and fit the canvas:
         * scaleX: width / backgroundImage.width,
         * scaleY: height / backgroundImage.height,
        */
        selectable: false
    });

    return backgroundImage;
}

/**
 * Creates a new fabric image object from the given image URL.
 *
 * @param {string} url - The URL of the image.
 * @param {object} config - The configuration for the image object.
 * @param {function} changeCb - The callback function to be called when the object is modified.
 *
 * @returns {Promise<SnappyImage>} A promise that resolves with the fabric image object.
 */
export async function loadImage(url, config, changeCb) {
    const opts = {
        ...IMAGE_DEFAULTS,
        ...omit(config, ['height', 'width'])
    };

    const image = await SnappyImage.fromURL(url, {}, opts);
    image.set({
        caching: true,
        selectable: false
    });

    if (Number.isFinite(config.height)) {
        image.scaleToHeight(config.height);
    }

    if (Number.isFinite(config.width)) {
        image.scaleToWidth(config.width);
    }

    setId(image, config);

    image.on('modified', () => changeCb(extractConfig(image, 'image')));

    return image;
}

/**
 * Creates a new fabric group object from the given SVG URL.
 *
 * @param {string} url - The URL of the SVG.
 * @param {object} config - The configuration for the group object.
 * @param {function} changeCb - The callback function to be called when the object is modified.
 *
 * @returns {Promise<SnappyGroup>} A promise that resolves with the fabric group object.
 */
export async function newSvgImage(url, config, changeCb) {
    const { objects, options } = await fabric.loadSVGFromURL(url);
    const opts = {
        ...SVG_DEFAULTS,
        ...options,
        ...omit(config, ['height', 'width'])
    };
    const bg = new SnappyGroup(objects, clean(opts));

    bg.scaleToHeight(config.height);
    bg.scaleToWidth(config.width);

    setId(bg, config);

    bg.on('modified', () => changeCb(extractConfig(bg, 'svg')));

    return bg;
}

/**
 * Creates a new fabric text object.
 *
 * @param {string} content - The content of the text object.
 * @param {object} config - The configuration for the text object.
 * @param {function} changeCb - The callback function to be called when the object is modified.
 *
 * @returns {Promise<SnappyText>} A promise that resolves with the fabric text object.
 */
export async function newTextBox(content, config, changeCb) {
    const options = {
        ...TEXTS_DEFAULTS,
        ...omit(config, ['height'])
    };

    const textBox = new SnappyText(content, clean(options));

    if (Number.isFinite(config.width)) {
        textBox.scaleToWidth(config.width);
    }

    setId(textBox, config);

    textBox.on('modified', () => changeCb(extractConfig(textBox, 'text')));

    return textBox;
}

/**
 * Sets the ID of the fabric object if it doesn't already have one.
 *
 * @param {fabric.Object} object - The fabric object.
 * @param {object} config - The configuration for the object.
 */
export function setId(object, config) {
    if (!object.get('id') && !config.id) {
        object.set('id', uuid());
    }
}

/**
 * Extracts the configuration properties from the fabric object.
 *
 * @param {Array<fabric.Object>|fabric.Object} object - The fabric object or an array of fabric objects.
 * @param {string} [type] - The type of the object ('text', 'svg' or 'image').
 *
 * @returns {object} The extracted configuration properties.
 */
export function extractConfig(object, type) {
    if (isArray(object)) {
        const ary = [];
        for (const obj of object) {
            const t = type || obj instanceof SnappyText ? 'text' : 'svg';
            ary.push(extractConfig(obj, t));
        }

        return ary;
    }

    const config = {};
    for (const [k, v] of Object.entries(DEFAULTS_MAP[type])) {
        const value = object.get(k);
        if (value && value !== v) {
            config[k] = value;
        }
    }

    if (Number.isFinite(config.height)) {
        config.height = object.getScaledHeight();
    }

    if (Number.isFinite(config.width)) {
        config.width = object.getScaledWidth();
    }

    if (type === 'svg' || type === 'image') {
        delete config.fill;
    }

    return clean(config);
}

/**
 * Zooms the canvas to fit the background image.
 *
 * @param {fabric.Canvas} canvas - The fabric canvas.
 */
export function zoomToFit(canvas, startingScale = .9) {
    if (!canvas || canvas.getObjects().length < 1) {
        return;
    }

    canvas.setZoom(1);

    const everything = new fabric.Group(canvas.getObjects());

    const x = (everything.left + (everything.width / 2)) - (canvas.width / 2);
    const y = (everything.top + (everything.height / 2)) - (canvas.height / 2);

    canvas.absolutePan({ x, y });

    const heightDist = canvas.getHeight() - everything.height;
    const widthDist = canvas.getWidth() - everything.width;

    let everythingDimension = 0;
    let canvasDimension = 0;

    if (heightDist < widthDist) {
        everythingDimension = everything.height;
        canvasDimension = canvas.getHeight();
    } else {
        everythingDimension = everything.width;
        canvasDimension = canvas.getWidth();
    }

    const zoom = (canvasDimension / everythingDimension) * startingScale;

    canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, zoom);
    canvas.renderAll();
}
