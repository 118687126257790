// Utils
import dictree from 'dictree';
import { cloneDeep, compact, get, isArray, isEmpty, isNil, isObject, omit, toPairs } from 'lodash';
import { humanizeType } from 'libs/utils/string';
import { getEventSupportedLocalesForKindOptions } from 'libs/utils/locales';
import { sortByOrder } from 'libs/utils/collections';

// Constants

/** @const {string[]} PUBLIC_FORBIDDEN_KINDS list of publicly forbidden kinds */
const PUBLIC_FORBIDDEN_KINDS = [
    'custom',
    'display-only',
    'file',
    'i18n',
    'nested-object',
    'password',
    'video-call'
];

/** @const {RegExp[]} PUBLIC_FORBIDDEN_FIELDS list of publicly forbidden fields */
const PUBLIC_FORBIDDEN_FIELDS = [/^fp_.*/, /^_.*/];

/** @const {RegExp[]} PUBLIC_ALLOWED_FIELDS list of publicly allowed fields */
const PUBLIC_ALLOWED_FIELDS = ['_registered_sessions', 'fp_status'];

/** @const {string[]} INVALID_FIELD_NAMES list of invalid field names */
const INVALID_FIELD_NAMES = [
    '_id',
    'id',
    'is_team_member',
];

/** @const {string[]} FIELDS_THAT_MAY_NOT_BE_PRIVATE list of fields that may not be private */
const FIELDS_THAT_MAY_NOT_BE_PRIVATE = [
    'mailertarget1',
    'mailertarget2',
    'mailertarget3',
];

/** @const {string[]} OVERRIDE_METADATA_BIT_ID_PREFIX prefix for the override metadata bit id */
export const OVERRIDE_METADATA_BIT_ID_PREFIX = 'bstg-metadata-bit-';

/** @const {string[]} OVERRIDE_METADATA_BIT_PRIORITY priority for the override metadata bit */
export const OVERRIDE_METADATA_BIT_PRIORITY = 'highest';

/** @const {string[]} OVERRIDE_METADATA_BIT_OWNER owner for the override metadata bit */
export const OVERRIDE_METADATA_BIT_OWNER = 'private';

/** @const {string[]} OVERRIDE_METADATA_FIELDS_TO_OMIT fields to omit from the override metadata bit */
export const OVERRIDE_METADATA_FIELDS_TO_OMIT = [
    'field',
    'selectedChoice',
    'toBeDeleted',
    'isDeleted',
    'addKind',
    'dirty',
    'type'
];

/** @const {string[]} REPRESENTATIONS list of representations */
export const REPRESENTATIONS = [
    '_representation',
    '_representation_line2',
    '_representation_line3',
    '_representation_image',
];

/**
 * Builds metadata for a given type, with options to merge dynamic extensions and filter private fields.
 *
 * @param {Object} metadata - The metadata object containing various types.
 * @param {string} fpType - The type of metadata to build.
 * @param {boolean} [mergeDynamicExtensions=false] - Whether to merge dynamic extensions into the metadata.
 * @param {boolean} [filterPrivate=false] - Whether to filter out private fields from the metadata.
 * @param {Array<string>} [filterPrivateExceptions=[]] - List of private fields that should not be filtered out.
 *
 * @returns {Object} The constructed metadata object for the specified type.
 */
export function buildMetadataForType(metadata, fpType, mergeDynamicExtensions = false, filterPrivate = false, filterPrivateExceptions = []) {
    if (!metadata?.[fpType]) {
        return {};
    }

    const base = cloneDeep(metadata[fpType] || {});

    // dynamic extensions mergey surgery
    if (mergeDynamicExtensions) {
        const dynamicExtFields = metadata[fpType]._dynamic_ext || {};

        Object.keys(dynamicExtFields).forEach(field => {
            if (!isObject(dynamicExtFields[field])) return;
            dynamicExtFields[field].is_dynamic_ext = true;
            base[`_${field}`] = dynamicExtFields[field];
        });

        delete base._dynamic_ext;
    }

    for (const [key, value] of Object.entries(base)) {
        if (isNil(key) || isNil(value)) {
            delete base[key];
            continue;
        }

        if (filterPrivate && !filterPrivateExceptions.includes(key) && !isFieldPublic(key, value)) {
            delete base[key];
        }

        if (isObject(value)) {
            value.name = value.name || key;
        }
    }

    return base;
}

/**
 * Finds all the fields that yield the specified flag.
 * Note that `flagPath` can specify a nested field.
 *
 * @param {Object[]} fieldsList an array of field descriptors
 * @param {String} flagPath object field path (dot notation)
 *
 * @return {String[]} a list of fields names that matches the flag
 */
export function findFieldsWithFlag(fieldsList, flagPath) {
    let fields = fieldsList.filter(field => get(field, flagPath));
    fields = compact(fields);
    return fields.map(f => f.field);
}

/**
 * Converts the metadata object into an ordered list of fields
 *
 * @param {FieldDescriptor|FieldDescriptor[]} mdHash the metadata hash
 *
 * @return {Array} a compacted version of the metadata fields
 */
export function convertMetadataHashToOrderedList(mdHash) {
    if (!isObject(mdHash)) {
        return [];
    }
    if (isArray(mdHash)) {
        return mdHash;
    }

    const mdList = compact(
        toPairs(mdHash)
            .map(pair => {
                // omit null and special fields
                // @ts-ignore
                if (!isObject(pair[1]) || (pair[0].startsWith('_') && !pair[1].is_dynamic_ext)) {
                    return null;
                }
                return Object.assign(pair[1], { field: pair[0] });
            })
    );

    return sortByOrder(mdList, 'label');
}

/**
 * Picks the public fields of the specified fp_type'd metadata based
 * on the kinds argument
 *
 * @param {FieldDescriptor[]} fpTypedMetadata the metadata of a specific fp_type
 * @param {string[]} [kinds] an array of strings representing the valid kinds
 *
 * @returns {object[]} a list that contains the speficied kinds for the give fp_type metadata
 *
 * @private
 */
export function pickDescriptorsByKind(fpTypedMetadata, kinds = []) {
    const descriptors = [];
    for (const [key, value] of Object.entries(fpTypedMetadata)) {
        if (key.startsWith('_') || key.startsWith('fp_') || !isObject(value)) {
            continue;
        }

        value.kind = value.kind || 'text';
        if (kinds.length && !kinds.includes(value.kind)) {
            continue;
        }

        value.name = key;
        descriptors.push(value);
    }

    return descriptors;
}

/**
 * Decorates locale fields in the metadata object with supported locales for a given event.
 *
 * @param {Object} event - The event object.
 * @param {Array|Object} metadata - The metadata object containing fields.
 * @param {boolean} [includeEmptyState=false] - Flag indicating whether to include an empty state option.
 * @param {Object} [emptyState={ value: '', label: 'None' }] - The empty state option object.
 */
export function decorateLocaleFields(event, metadata, includeEmptyState = false, emptyState = { value: '', label: 'None' }) {
    const fields = isArray(metadata) ? metadata : Object.values(metadata);
    for (const field of fields) {
        if (field.kind === 'locale') {
            field.kind_options = {
                values: getEventSupportedLocalesForKindOptions(event, includeEmptyState, emptyState)
            };
        }
    }
}

/**
 * Filters duplicate targets or exceptions fields from the given list.
 * Only allows one targets or exceptions field to pass through because the single widget caters for both.
 *
 * @param {Array} fieldsList - The list of fields to filter.
 *
 * @returns {Array} - The filtered list of fields.
 */
export function filterDuplicateTargetsExceptionsFields(fieldsList) {
    // only let one targets or exceptions filter through because the single widget caters for both
    let _isFirstTargetsExceptionsField = true;

    return fieldsList.filter(descriptor => {
        if (descriptor.kind !== 'external' || descriptor.kind_options && descriptor.kind_options.type !== 'targets-exceptions') {
            return true;
        }
        const ret = _isFirstTargetsExceptionsField;

        _isFirstTargetsExceptionsField = false;
        return ret;
    });
}

/**
 * Take a given metadata object and returns the list of all types that can be configured/used.
 *
 * @param {Object} metadata
 * @param {Object} args
 * @param {boolean} [args.hideForMetadata]
 * @param {boolean} [args.hideForPicker]
 *
 * @returns {{id:string , label:string}[]}
 */
export function getMetadataTypeList(metadata, { hideForMetadata = false, hideForPicker = false }) {
    const types = [];
    for (const type in metadata) {
        const typeMetadata = metadata[type];
        if (!isObject(typeMetadata) || hideForMetadata && typeMetadata._hide_from_metadata_editor || hideForPicker && typeMetadata._hide_from_fp_picker) {
            continue;
        }
        const label = humanizeType(typeMetadata, type, true);
        // Empty _type_representation are not to be used
        if (label) {
            types.push({ id: type, label });
        }
    }
    return types;
}

/**
 * Checks if a given field name is valid.
 *
 * A valid field name:
 * - Is not included in the INVALID_FIELD_NAMES array.
 * - Does not start with 'fp_'.
 * - Does not start with an underscore ('_').
 * - Does not start with a numeric character.
 * - Contains only alphanumeric characters, underscores, or hyphens.
 *
 * @param {string} fieldName - The field name to validate.
 *
 * @returns {boolean} True if the field name is valid, false otherwise.
 */
export function isValidFieldName(fieldName) {
    return !(
        INVALID_FIELD_NAMES.includes(fieldName)
        || fieldName.startsWith('fp_')
        || fieldName.startsWith('_')
        || !isNaN(parseInt(fieldName[0], 10))
        || !/^[\w-]*$/.test(fieldName)
    );
}

/**
 * Checks if a given field name should not be private.
 *
 * @param {string} fieldName - The name of the field to check.
 *
 * @returns {boolean} - Returns true if the field name should not be private, otherwise false.
 */
export function shouldNotBePrivate(fieldName) {
    return FIELDS_THAT_MAY_NOT_BE_PRIVATE.includes(fieldName);
}

/**
 * Generates an override bit from the difference between new and existing metadata.
 *
 * @param {string} eventId - The event ID.
 * @param {string} fpType - The fingerprint type.
 * @param {Object} type - The type definition object.
 * @param {Object} existingMetadata - The existing metadata object.
 * @param {Object} existingOverrideBit - The existing override bit object.
 * @param {Object} representations - The representations object.
 *
 * @returns {Object} The merged override bit object.
 */
export function getOverrideBitFromDiff(eventId, fpType, type, existingMetadata, existingOverrideBit, representations) {
    const _id = `${OVERRIDE_METADATA_BIT_ID_PREFIX}${fpType}`;
    const baseOverrideBit = {
        _id,
        fp_ext_id: _id,
        fp_bit_priority: OVERRIDE_METADATA_BIT_PRIORITY,
        fp_owner: OVERRIDE_METADATA_BIT_OWNER
    };

    if (!isObject(existingMetadata[fpType])) {
        return baseOverrideBit;
    }

    const bitFpType = {};
    const addedFields = [];
    Object.entries(type)
        .sort((a, b) => a[1].order > b[1].order ? 1 : -1)
        .forEach(([field, definition], index) => {
            if (definition.toBeDeleted) {
                // deleted fields are set as null
                bitFpType[field] = null;
            } else if (isObject(definition)) {
                // omit fields and set order as index
                bitFpType[field] = omit(
                    { ...definition, order: index },
                    OVERRIDE_METADATA_FIELDS_TO_OMIT,
                );
            }
            addedFields.push(field);
        });

    // add nulls back into the bit manually. this avoids rogue prunes when values disappear on subsequent runs
    for (const [field, definition] of Object.entries(existingMetadata[fpType] || {})) {
        if (definition === null && !addedFields.includes(field)) {
            bitFpType[field] = null;
        }
    }

    const newFullOverrideBit = {
        [fpType]: { ...bitFpType, ...representations },
    };

    // don't save an empty hash that might make the bit-compiler blat everything
    if (isEmpty(newFullOverrideBit[fpType])) { delete newFullOverrideBit[fpType]; }

    // diff between new and existing removing keys starting with _ that are not representations
    const fieldsChangesDict = cloneDeep(dictree.diff(newFullOverrideBit[fpType], existingMetadata[fpType] || {}));
    for (const key of Object.keys(fieldsChangesDict)) {
        if (isFieldTechnical(key)) {
            delete fieldsChangesDict[key];
        }
    }

    const fullChangesDict = {
        ...baseOverrideBit,
        [fpType]: fieldsChangesDict,
    };

    // the diffed object cannot be null or contain an empty object. this will break stuff!
    // putting something here will prevent a wipe of the metadata if everything ends up being the same between overrides when diffed!
    // unfortunately this is necessary to get around the crappiness of this situation
    if (isObject(fullChangesDict[fpType]) && isEmpty(fullChangesDict[fpType])) {
        fullChangesDict[fpType]._ignore_this = true;
    }

    /**
     * Removes fields that should not be kept
     * @param {Record<string,unknown>} dict
     */
    const cleanUpDict = dict => {
        for (const [field, definition] of Object.entries(dict)) {
            if (definition) {
                delete dict[field]._registrations;
                if (addedFields.includes(field)) {
                    delete dict[field]._to_prune;
                }
                if (isObject(definition.kind_options)) {
                    // never prune kind_options, this is not needed and will break stuff
                    delete dict[field].kind_options._to_prune;
                }
            }
        }
    };

    // ABORT with changes dict if no existing override (create one)
    if (isEmpty(existingOverrideBit)) {
        cleanUpDict(fullChangesDict[fpType]);
        return fullChangesDict;
    }

    const mergedChanges = cloneDeep(dictree.merge([existingOverrideBit, fullChangesDict]), existingOverrideBit);
    // This method is NOT compatible with dynamic extensions, and will simply ignore them
    cleanUpDict(mergedChanges[fpType]);
    return mergedChanges;
}

/**
 * Checks if a given field is considered technical.
 * A field is considered technical if it starts with an underscore ('_')
 * and is not included in the REPRESENTATIONS array.
 *
 * @param {string} field - The field name to check.
 *
 * @returns {boolean} - Returns true if the field is technical, otherwise false.
 */
export function isFieldTechnical(field) {
    return field.startsWith('_') && !REPRESENTATIONS.includes(field);
}

/**
 * Generates a JSON schema based on the provided type metadata and sorted metadata.
 *
 * @param {Object} typeMetadata - The metadata object containing type information.
 * @param {Array} sortedMetdata - An array of metadata fields, sorted as needed.
 * @param {string} fpType - The fallback type to use if type representation is not available.
 *
 * @returns {Object} The generated JSON schema.
 *
 * @see https://ajv.js.org/json-schema.html
 */
export function getTypeSchema(typeMetadata, sortedMetdata, fpType) {
    const required = [];
    const schema = {
        title: typeMetadata._type_representation || fpType,
        description: typeMetadata._description,
        $comment: typeMetadata._description,
        properties: {},
        required
    };

    sortedMetdata.forEach(field => {
        const key = field.field;
        if (key.startsWith('_')) return;

        const type = kindToJsonSchemaType(field);
        if (!type) return;

        schema.properties[key] = {
            type
        };

        if (field.example) {
            schema.properties[key].examples = [field.example];
        }

        const isRequired = field?.validations?.required;
        if (isRequired) {
            schema.required = schema.required || [];
            schema.required.push(key);
        }
    });

    return schema;
}

/**
 * Converts a field's kind to a corresponding JSON schema type.
 *
 * @param {Object} field - The field object containing the kind and optional kind options.
 * @param {string} field.kind - The kind of the field (e.g., 'timestamp', 'external', 'nested-object', etc.).
 * @param {Object} [field.kind_options] - Optional kind options for the field.
 * @param {boolean} [field.kind_options.single_doc] - Indicates if the external kind is a single document.
 *
 * @returns {Array<string>|null} - An array of JSON schema types corresponding to the field's kind, or null if the kind is not recognized.
 *
 * @private
 */
function kindToJsonSchemaType(field) {
    switch (field.kind) {
        case 'timestamp':
            return ['number'];

        case 'external':
            return ['null', field.kind_options?.single_doc ? 'string' : 'array', 'object'];

        case 'nested-object':
            return ['object'];

        case 'list':
            return ['array'];

        case 'boolean':
            return ['boolean'];

        case 'number':
            return ['number'];

        case 'text':
        case 'text-multiline':
        case 'colour':
        case 'email':
        case 'html':
        case 'password':
        case 'choice':
        case 'choice-list':
            return ['string'];

        case undefined:
            return ['string'];

        default:
            return null;
    }
}

/**
 * Checks if the given field can be used for public operations
 *
 * @param {string} fieldName the name of the metadata field
 * @param {object} fieldDefinition the definition of the field
 *
 * @returns {boolean} whether the field can be used publicly
 *
 * @private
 */
function isFieldPublic(fieldName, fieldDefinition) {
    const isPublic = PUBLIC_ALLOWED_FIELDS.includes(fieldName);

    let isForbidden = PUBLIC_FORBIDDEN_FIELDS.some(reg => reg.test(fieldName));
    if (isObject(fieldDefinition)) {
        isForbidden = isForbidden || PUBLIC_FORBIDDEN_KINDS.some(kind => fieldDefinition.kind === kind);
    }

    return isPublic || !isForbidden;
}

