import BaseService from './base-service';
import { get, keyBy } from 'lodash';

// constants
import { API_BASE_PATH } from 'libs/utils/constants';
import { SESSION_TYPES } from 'libs/services/live-stream';


/**
 * Mapping of available live display background types
 * @const {Object} BACKGROUND_TYPES
 */
export const BACKGROUND_TYPES = {
    IMAGE: 'image',
    COLOR: 'color',
};

/**
 * API path for get live stream live displays. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_DISPLAYS_BASE_URL
 * @private
 */
export const LIVE_DISPLAYS_BASE_URL = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/live-displays`;

/**
 * API path for get/post live stream live display. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_DISPLAY_BASE_URL
 * @private
 */
export const LIVE_DISPLAY_BASE_URL = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/live-display`;

/**
 * API path for updating live stream live display document. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{liveDisplayId}}`.
 * @const {string} LIVE_DISPLAY_RESOURCE_URL
 * @private
 */
export const LIVE_DISPLAY_RESOURCE_URL = `${LIVE_DISPLAY_BASE_URL}/{{liveDisplayId}}`;

/**
 * API path for updating live stream live display background image. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_DISPLAY_BACKGROUND_IMAGE_URL
 * @private
 */
export const LIVE_DISPLAY_BACKGROUND_IMAGE_URL = `${LIVE_DISPLAY_BASE_URL}/{{liveDisplayId}}/image`;


export default class LiveDisplayService extends BaseService {

    get BACKGROUND_TYPES() {
        return BACKGROUND_TYPES;
    }

    /**
     * Get the list of live-display documents for given live-stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object[]>} live stream documents
     */
    async getDocuments(eventId, liveStreamId) {
        const liveDisplaysUrl = LIVE_DISPLAYS_BASE_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data: liveDisplayDocuments } = await this.get(liveDisplaysUrl).catch((err) => {
            console.error('[LiveDisplayService] error while fetching live display docs', err);
            return { data: [] };
        });

        return liveDisplayDocuments;
    }

    /**
     * Gets the default live-display document for given live-stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {String} name the name of the display
     * @returns {Promise<Object>} live stream documents
     */
    async getDisplayByName(eventId, liveStreamId, name = 'Main Display') {
        const liveDisplayDocuments = await this.getDocuments(eventId, liveStreamId)
            .catch(() => {}) || [];

        const liveDisplayHash = keyBy(liveDisplayDocuments, 'name') || {};
        return liveDisplayHash[name] || {};
    }

    /**
     * create live display document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {FormData} formData payload
     */
    async createDoc(eventId, liveStreamId, formData) {

        const url = LIVE_DISPLAY_BASE_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        return this.post(url, formData);
    }

    /**
     * update live display document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {String} liveDisplayId live display ID
     * @param {FormData} formData payload
     */
    async updateDoc(eventId, liveStreamId, liveDisplayId, formData) {
        const url = LIVE_DISPLAY_RESOURCE_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{liveDisplayId}}', liveDisplayId);

        return this.put(url, formData);
    }

    /**
     * create or update live display document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {Object} liveDisplay live display document
     */
    async createOrUpdateDoc(eventId, liveStreamId, liveDisplay) {
        const update = !!liveDisplay._id;

        const image = get(liveDisplay, 'image.0.file');
        const formData = await this.formImageBodyData(image);
        formData.set('payload', JSON.stringify(liveDisplay));

        if (update) {
            return this.updateDoc(eventId, liveStreamId, liveDisplay._id, formData);
        } else {
            return this.createDoc(eventId, liveStreamId, formData);
        }
    }

    /**
     * deletes live display document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {String} liveDisplayId live display ID
     *
     * @return {Promise<Object>}
     */
    async deleteDoc(eventId, liveStreamId, liveDisplayId) {
        const url = LIVE_DISPLAY_RESOURCE_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{liveDisplayId}}', liveDisplayId);

        return this.delete(url);
    }

    /**
     * Default live display document
     * @param {Object} overwrites
     * @returns {Object} mutated live display object
     */
    prepareDefaultLiveDisplay(overwrites = {}) {
        return {
            show_live_stream: true,
            show_interactivity: true,
            show_slides: true,
            show_claps: true,
            location: SESSION_TYPES.IN_PERSON,
            background_type: BACKGROUND_TYPES.COLOR,
            background_color: '#000000',
            image: null,
            ...overwrites
        };
    }

    /**
     * Mutates live display document in place to apply live-display background inference
     * and prepare the document for settings editor or live display component
     *
     * @param {Object} liveDisplay live display object
     * @param {String} eventId workspace id
     * @param {String} [liveStreamId] live stream ID
     * @returns {Object} mutated live display object
     */
    prepareLiveDisplayOptions(liveDisplay, eventId, liveStreamId = null) {
        if (liveDisplay.background_type === BACKGROUND_TYPES.IMAGE) {
            // live display background set to image? populate image picker init object,
            // and image_url object for comparison
            const liveDisplayBackgroundImageUrl = this.getLiveDisplayBackgroundUrl(
                eventId,
                liveDisplay.parent_doc_id || liveStreamId,
                liveDisplay._id
            );
            liveDisplay.image = [liveDisplayBackgroundImageUrl];
            liveDisplay.image_url = liveDisplayBackgroundImageUrl;
        }

        return liveDisplay;
    }

    /**
     * Form HTTP form body upload for file
     *
     * @param {File} file
     * @returns {Promise<FormData>}
     */
    async formImageBodyData(file) {
        const token = await this.getCsrfToken('');
        const bodyFormData = new FormData();

        if (file) {
            bodyFormData.set('file', file);
        }

        bodyFormData.set('_csrf', token);

        return bodyFormData;
    }

    /**
     * Interpolates live display background URL
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {String} liveDisplayId live display ID
     * @returns {String}
     */
    getLiveDisplayBackgroundUrl(eventId, liveStreamId, liveDisplayId) {
        return LIVE_DISPLAY_BACKGROUND_IMAGE_URL
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{liveDisplayId}}', liveDisplayId);
    }
}
