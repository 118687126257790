import { get, has } from 'lodash';
import { getAttachmentMetadata } from 'libs/utils/attachments';

export default class WebinarTemplate {
    constructor(payload = {}) {

        /** @type {string?} */
        this._id = payload._id;

        /** @type {string} */
        this.orgId = payload.bs_owner_id || '';

        /** @type {string} */
        this.name = payload.name || '';

        /** @type {string} */
        this.branded_app = payload.branded_app;

        /** @type {string} */
        this.description = payload.description || '';

        /** @type {boolean} */
        this.clappingEnabled = has(payload, 'clapping') ? payload.clapping : true;

        /** @type {boolean} */
        this.whoIsWatchingEnabled = payload.who_is_watching_enabled || false;

        /** @type {boolean} */
        this.pollsEnabled = has(payload, 'polls_enabled') ? payload.polls_enabled : true;

        /** @type {boolean} */
        this.qnaEnabled = has(payload, 'qna_enabled') ? payload.qna_enabled : true;

        /** @type {boolean} */
        this.qnaModerationEnabled = has(payload, 'qna_moderation_enabled') ? payload.qna_moderation_enabled : true;

        /** @type {boolean} */
        this.qnaAnonymousEnabled = has(payload, 'qna_anonymous_enabled') ? payload.qna_anonymous_enabled : true;

        /** @type {boolean} */
        this.interprefyEnabled = payload.interprefy_enabled || false;

        /** @type {string?} */
        this.interprefyProjectId = payload.interprefy_project_id;

        /** @type {object?} */
        this.banner = getAttachmentMetadata(payload, 'banner');

        /** @type {string?} */
        this.brandColor = payload.brand_color;

        /** @type {object?} */
        this.icon = getAttachmentMetadata(payload, 'icon');

        /** @type {object?} */
        this.background = getAttachmentMetadata(payload, 'background');

        /** @type {object?} */
        this.integrations = payload.integrations || {};

        /** @type {object[]} */
        this.registrationPageFields = get(payload, 'registration_page_fields', []);

        /** @type {boolean?} */
        this.automatedEmails = !get(payload, 'disable_automated_emails');
    }


    /**
     * maps model to snake case
     *
     * @param {WebinarTemplate} model
     * @returns {object}
     */
    static mapToSnakeCase(model) {
        return {
            _id: model._id,
            bs_owner_id: model.orgId,
            name: model.name,
            branded_app: model.branded_app,
            description: model.description,
            clapping: model.clappingEnabled,
            who_is_watching_enabled: model.whoIsWatchingEnabled,
            polls_enabled: model.pollsEnabled,
            qna_enabled: model.qnaEnabled,
            qna_moderation_enabled: model.qnaModerationEnabled,
            qna_anonymous_enabled: model.qnaAnonymousEnabled,
            interprefy_enabled: model.interprefyEnabled,
            interprefy_project_id: model.interprefyProjectId,
            banner: model.banner,
            icon: model.icon,
            brand_color: model.brandColor,
            background: model.background,
            integrations: model.integrations,
            registration_page_fields: get(model, 'registrationPageFields', []),
            disable_automated_emails: !model.automatedEmails
        };
    }
}
