/** @module Modals */

/**
 * Modals sizes
 *
 * @enum {String}
 */
const ModalSize = { large: 'large', medium: 'medium', small: 'small' };

/**
 * Modal content styles
 */
const MODAL_CONTENT_STYLES = ['narrow'];

/**
 * Button styles
 */
export const BUTTONS = {
    INFO: 'btn btn-primary',
    SUCCESS: 'btn btn-success',
    DANGER: 'btn btn-danger'
};

/**
 * Given an element this method sets the size to the modal it resides in.
 *
 * @param {Element} element an element inside a modal
 * @param {ModalSize} size the size
 *
 * @returns {DOMTokenList|undefined} the modal classes list or undefined if no modal is found.
 */
export function setModalSize(element, size) {
    if (!element || !size) {
        return;
    }

    const modal = element.closest('.v--modal-box');

    if (!modal) {
        return;
    }

    for (const s of Object.values(ModalSize)) {
        modal.classList.remove(s);
    }

    modal.classList.add(size);

    return modal.classList;
}

/**
 * Given an element this method sets the large size to the closest modal.
 *
 * @param {Element} element an element inside a modal
 *
 * @returns {DOMTokenList} the modal classes list
 */
export function toLargeModal(element) {
    return setModalSize(element, ModalSize.large);
}

/**
 * Given an element this method sets the medium size to the closest modal.
 *
 * @param {Element} element an element inside a modal
 *
 * @returns {DOMTokenList} the modal classes list
 */
export function toMediumModal(element) {
    return setModalSize(element, ModalSize.medium);
}

/**
 * Given an element this method sets the small size to the closest modal.
 *
 * @param {Element} element an element inside a modal
 *
 * @returns {DOMTokenList} the modal classes list
 */
export function toSmallModal(element) {
    return setModalSize(element, ModalSize.small);
}

/**
 * Given an element this method sets the style of the modal content.
 *
 * @param {Element} element an element inside a modal
 * @param {string} style the content style
 *
 * @returns {DOMTokenList|undefined} the modal content classes list or undefined if no modal is found.
 */
export function setModalContentStyle(element, style) {
    if (!element) {
        return;
    }

    const modal = element.closest('.v--modal-box');

    if (!modal) {
        return;
    }

    const body = modal.querySelector('.modal-body');

    if (!body) {
        return;
    }

    for (const s of MODAL_CONTENT_STYLES) {
        body.classList.remove(s);
    }

    if (style && MODAL_CONTENT_STYLES.includes(style)) {
        body.classList.add(style);
    }

    return body.classList;
}

/**
 * return modal button params
 *
 * @param {string} title
 * @param {string} style
 * @param {function} handler
 * @param {boolean} isDefault
 * @param {boolean} closeOnClick
 * @returns {object}
 */
export function button(
    title,
    style,
    handler = () => null,
    isDefault = false,
    closeOnClick = true
) {
    return {
        title,
        default: isDefault,
        handler,
        class: style,
        closeOnClick
    };
}
