export default {
    /**
     *  Vue Router <router-link> shim component
     *  simulates rendering, API and behaviour
     *  routes using BSTG UI AngularJS router
     */
    name: 'RouterLink',

    props: {
        to: {
            type: Object,
            default: () => ({}),
        },
        tag: {
            type: String,
            default: 'a'
        },
    },

    methods: {
        guard(e) {
            if (e.defaultPrevented) return;

            /**
             * vue-router v2.8.1
             * (c) 2017 Evan You
             * @license MIT
             *
             * Copied to emulate router-link behaviour
             *
             * */
            // don't redirect with control keys
            if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return;
            // don't redirect when preventDefault called
            if (e.defaultPrevented) return;
            // don't redirect on right click
            if (e.button !== undefined && e.button !== 0) return;
            // don't redirect if `target="_blank"`
            if (e.currentTarget && e.currentTarget.getAttribute) {
                const target = e.currentTarget.getAttribute('target');
                if (/\b_blank\b/i.test(target)) return;
            }

            if (e.preventDefault) {
                e.preventDefault();
            }

            return true;

        },

        click(e) {
            if (this.guard(e)) {
                // angularjs router path push
                if (this.to.path) {
                    this.$router.push(this.to.path);
                }
                // TODO: other idiomatic angluarjs route object actions
            }
        }
    },

    render(h) {

        const data = {};
        const on = { click: this.click };

        if (this.tag === 'a') {
            data.on = on;
        }

        if (this.to.path) {
            data.attrs = { href: this.to.path };
        }

        return h(this.tag, data, this.$slots.default);
    }

};
