// Utils
import Quill from 'quill';

// Constants
import {
    ATTRIBUTE_CLASS,
    CLASSNAME_BSTG_LIST
} from './commons';

const List = Quill.import('formats/list');

/**
 * This class is used to customize backstage lists.
 */
export default class BstgList extends List {
    constructor(domNode) {
        super(domNode);
        domNode.setAttribute(ATTRIBUTE_CLASS, CLASSNAME_BSTG_LIST);
    }
}
