/** @module Image */

/**
 * Determines if the given image URL leads to a valid image
 *
 * @param {String} imageUrl the URL to the image
 *
 * @returns {Promise<boolean>} whether the image exists or not;
 */
export function imageExists(imageUrl) {
    return new Promise(resolve => {
        const image = new Image();

        image.addEventListener('load', () => resolve(true));
        image.addEventListener('error', () => resolve(false));

        image.src = imageUrl;
    });
}
