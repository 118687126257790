import { cloneDeep } from 'lodash';
import { generateSetters, generateGetters } from 'libs/utils/store';

const initialState = {
    videoDurationSecs: null
};

export default () => ({
    namespaced: true,
    state: cloneDeep(initialState),
    mutations: generateSetters(initialState),
    getters: generateGetters(initialState),
});
