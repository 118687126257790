/** @module Cookies */

import { nowUnixTimestamp } from 'libs/utils/time';

// How many seconds before the expiration timestamp should we renew the cookies
const RENEW_COOKIES_BEFORE_EXPIRATION = 30; // seconds

/**
 * Creates a timer that runs a handler 30s before the expires date
 *
 * @param {Number} expires in ms
 * @param {Function} handler
 * @return {Function} the cancel function
 */
export function setupExpirationTimeout(expires, handler) {
    if (!expires) {
        return;
    }

    const now = Date.now();
    const timeout = Math.max(expires * 1000 - now - RENEW_COOKIES_BEFORE_EXPIRATION * 1000, 0);

    console.log(
        '[Cookies] cookies will be renewed at',
        new Date(now + timeout)
    );
    // Refresh cookies when the URL is about to expire (within 30 seconds)
    return setTimeout(
        handler,
        timeout
    );
}

/**
 * Checks if a cookie is still valid based on its expiration timestamp
 * @param {Number} cookieExpirationTimestamp
 * @return {Boolean}
 */
export function isCookieStillValid(cookieExpirationTimestamp) {
    if (!Number.isFinite(cookieExpirationTimestamp)) {
        return false;
    }

    // expirationTimeout will refresh the url 30s before it expires, we need to allow it to succeed
    // if url is still valid, no need to re-sign otherwise we'll get a glitch in the video
    const expiration = cookieExpirationTimestamp - RENEW_COOKIES_BEFORE_EXPIRATION - 1;

    return nowUnixTimestamp() < expiration;
}
