// Utils
import * as d3 from 'd3';

/**
 * Builds a bar chart with the given data
 *
 * @param {object[]} data the data for the chart
 * @param {object} options
 * @param {function} [options.x] given d in data, returns the (temporal) x-value
 * @param {function} [options.y] given d in data, returns the (quantitative) y-value
 * @param {function} [options.title] given d in data, returns the title text
 * @param {number} [options.marginTop = 0] top margin, in pixels
 * @param {number} [options.marginRight=16] right margin, in pixels
 * @param {number} [options.marginBottom=24] bottom margin, in pixels
 * @param {number} [options.marginLeft=24] left margin, in pixels
 * @param {number} [options.width=640] outer width, in pixels
 * @param {number} [options.height=400] outer height, in pixels
 * @param {array} [options.xDomain] [xmin, xmax]
 * @param {number[]} [options.xRange] [left, right]
 * @param {function} [options.yType] the y-scale type
 * @param {array} [options.yDomain] [ymin, ymax]
 * @param {number[]} [options.yRange] [bottom, top]
 * @param {number} [options.xPadding=0.2] amount of x-range to reserve to separate bars
 * @param {function} [options.yFormat] a format specifier string for the y-axis
 * @param {string} [options.yLabel] a label for the y-axis
 * @param {string} [options.color=currentColor] stroke color of line
 * @param {boolean} [options.withTooltips=true] whether to display a tooltip on the line
 *
 * @returns {SVGElement} the chart in SVG format
 *
 * @copyright 2021 Observable, Inc.
 * @license ISC
 * @see https://observablehq.com/@d3/bar-chart
 * @modified SpotMe 12.2022
 */
export function BarChart(data, {
    x = (d, i) => i, // given d in data, returns the (ordinal) x-value
    y = d => d, // given d in data, returns the (quantitative) y-value
    title, // given d in data, returns the title text
    marginTop = 0, // the top margin, in pixels
    marginRight = 0, // the right margin, in pixels
    marginBottom = 24, // the bottom margin, in pixels
    marginLeft = 24, // the left margin, in pixels
    width = 640, // the outer width of the chart, in pixels
    height = 400, // the outer height of the chart, in pixels
    xDomain, // an array of (ordinal) x-values
    xRange = [marginLeft, width - marginRight], // [left, right]
    yType = d3.scaleLinear, // y-scale type
    yDomain, // [ymin, ymax]
    yRange = [height - marginBottom, marginTop], // [bottom, top]
    xPadding = 0.2, // amount of x-range to reserve to separate bars
    yFormat, // a format specifier string for the y-axis
    yLabel, // a label for the y-axis
    color = 'currentColor', // bar fill color
    withTooltips = true
} = {}) {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);

    // Compute default domains, and unique the x-domain.
    if (xDomain === undefined) xDomain = X;
    if (yDomain === undefined) yDomain = [0, d3.max(Y)];
    xDomain = new d3.InternSet(xDomain);

    // Omit any data not present in the x-domain.
    const I = d3.range(X.length).filter(i => xDomain.has(X[i]));

    // Construct scales, axes, and formats.
    const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = yType(yDomain, yRange);
    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
    const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);

    // Compute titles.
    if (title === undefined) {
        title = i => `${X[i]}\n${Y[i]}`;
    } else {
        const O = d3.map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
    }

    const svg = d3.create('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 12)
        .style('-webkit-tap-highlight-color', 'transparent')
        .style('overflow', 'visible');

    svg.append('g')
        .attr('transform', `translate(${marginLeft},0)`)
        .call(yAxis)
        .call(g => g.select('.domain').remove())
        .call(g => g.selectAll('.tick line').clone()
            .attr('x2', width - marginLeft - marginRight)
            .attr('stroke-opacity', 0.1))
        .call(g => g.append('text')
            .attr('x', -marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel));

    svg.append('g')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .call(xAxis);

    const rx = 8;
    const ry = 8;
    const bar = svg.append('g')
        .attr('fill', color)
        .selectAll('bar')
        .data(I)
        .enter()
        .append('path')
        .style('fill', color)
        .attr('d', i => `
M${xScale(X[i])},${yScale(Y[i]) + ry}
a${rx},${ry} 0 0 1 ${rx},${-ry}
h${xScale.bandwidth() - 2 * rx}
a${rx},${ry} 0 0 1 ${rx},${ry}
v${height - yScale(Y[i]) - ry - marginBottom}
h${-(xScale.bandwidth())}Z
        `);

    let tooltip;
    if (withTooltips) {
        svg
            .on('pointerenter pointermove', pointermoved)
            .on('pointerleave', pointerleft)
            .on('touchstart', event => event.preventDefault(), { passive: true });

        tooltip = svg.append('g')
            .attr('class', 'point-tooltip')
            .style('pointer-events', 'none')
            .attr('opacity', 0);
    }

    function pointermoved(event) {
        const p = d3.pointer(event);
        const i = Math.round(p[0] / xScale.step()) - 1;
        if (i < 0) return;

        tooltip.style('opacity', 1);
        tooltip.attr('transform', `translate(${xScale(X[i])},${yScale(Y[i])})`);
        const barWidth = bar.node().getBBox().width;
        const rect = tooltip.selectAll('rect')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('fill', '#2d3339');

        const text = tooltip.selectAll('text')
            .attr('fill', 'white')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('text')
            .call(text => text
                .selectAll('tspan')
                .data(`${title(i)}`.split(/\n/))
                .join('tspan')
                .attr('x', barWidth / 2)
                .attr('y', (_, i) => `${i * 1.1}em`)
                .attr('font-weight', (_, i) => i ? null : 'bold')
                .text(d => d));

        const { x, y, width: w, height: h } = text.node().getBBox();
        text.attr('transform', `translate(${w / -2},${15 - y})`);
        rect.attr('transform', `translate(${w / -2 - 8})`)
            .attr('x', x)
            .attr('y', 8)
            .attr('height', h + 16)
            .attr('width', w + 16);
        // svg.property('value', O[i]).dispatch('input', { bubbles: true });
    }

    function pointerleft() {
        tooltip.style('opacity', null);
        svg.node().value = null;
        svg.dispatch('input', { bubbles: true });
    }

    return svg.node();
}
