export default {
    props: {
        cell: {
            type: Object,
            required: true
        },

        item: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        }
    }
};
