// Utils
import Quill from 'quill';
import { isObject } from 'lodash';

// Constants
import {
    INTERNAL_LINK_PROTOCOL,
    ATTRIBUTE_ANCHOR,
    ATTRIBUTE_CLASS,
    ATTRIBUTE_TARGET,
    CLASSNAME_INTERNAL_LINK,
    EXTERNAL_LINK_TARGET,
    LINK_TYPE_EXTERNAL,
    LINK_TYPE_INTERNAL
} from './commons';

const Link = Quill.import('formats/link');

export class ProtocolPrependedLink extends Link {
    /** @override */
    static create(value) {
        const withProtocol = ProtocolPrependedLink.prependProtocol(value);
        return super.create(withProtocol);
    }

    /** @override */
    format(name, value) {
        const withProtocol = ProtocolPrependedLink.prependProtocol(value);
        super.format(name, withProtocol);
    }

    static prependProtocol(url, protocol = 'https') {
        if (!url || isObject(url) || url.includes('://') || url.startsWith('mailto:') || url.startsWith('tel:')) {
            return url;
        }
        return `${protocol}://${url}`;
    }
}

/**
 * This class is used to manage backstage links in the HTML editor.
 */
export class BstgLink extends ProtocolPrependedLink {
    /** @override */
    static create(value) {
        const node = super.create(value);

        BstgLink.setOrigin(node, value);

        return node;
    }

    /** @override */
    static sanitize(url) {
        if (isObject(url)) {
            return BstgLink.encodeInternalUrl(url);
        }

        return super.sanitize(url);
    }

    /** @override */
    static formats(node) {
        let href = node.getAttribute(ATTRIBUTE_ANCHOR);

        if (BstgLink.isInternalUrl(href)) {
            href = BstgLink.decodeInternalUrl(href);
        }

        // This might happen when transforming an internal link to an external one.
        if (isObject(href) && href.linkType === LINK_TYPE_EXTERNAL) {
            href = href.url;
        }

        return href;
    }

    /** @override */
    format(name, value) {
        super.format(name, value);

        if (name !== this.statics.blotName || !value) {
            return;
        }

        BstgLink.setOrigin(this.domNode, value);
    }

    /**
     * Determines if the given url is an internal link or not.
     *
     * @param {String} url the url to check
     *
     * @returns {Boolean} whether the given url is internal or not
     */
    static isInternalUrl(url = '') {
        return url.startsWith(INTERNAL_LINK_PROTOCOL);
    }

    /**
     * Transforms the given document into a valid backstage URL.
     *
     * @param {Object} doc the document to create the URL from
     *
     * @returns {String} the internal link URL
     */
    static encodeInternalUrl(doc) {
        return `${INTERNAL_LINK_PROTOCOL}${encodeURIComponent(JSON.stringify(doc))}`;
    }

    /**
     * Transform an internal URL into its original backstage document.
     *
     * @param {String} url the url to parse
     *
     * @returns {Object} the object from which the URL was originally encoded
     */
    static decodeInternalUrl(url) {
        try {
            return JSON.parse(decodeURIComponent(url).replace(INTERNAL_LINK_PROTOCOL, '') || '{}');
        } catch (e) {
            return {};
        }
    }

    /**
     * Given an HTML anchor node and a link object this method sets the proper
     * internal URL link
     *
     * @param {HTMLAnchorElement} node the html node to set the link to
     * @param {Object} link the document object to link
     *
     * @returns {HTMLAnchorElement} the given node (for chaining)
     */
    static setInternalLink(node, link) {
        node.setAttribute(ATTRIBUTE_ANCHOR, BstgLink.encodeInternalUrl(link));
        node.setAttribute(ATTRIBUTE_CLASS, CLASSNAME_INTERNAL_LINK);
        node.removeAttribute(ATTRIBUTE_TARGET);

        return node;
    }

    /**
     * Given an HTML anchor node and a link object this method sets the proper
     * external URL link
     *
     * @param {HTMLAnchorElement} node the html node to set the link to
     * @param {Object} link the document object to link
     *
     * @returns {HTMLAnchorElement} the given node (for chaining)
     */
    static setExternalLink(node, link) {
        node.setAttribute(ATTRIBUTE_ANCHOR, link);
        node.setAttribute(ATTRIBUTE_TARGET, EXTERNAL_LINK_TARGET);

        const className = node.getAttribute(ATTRIBUTE_CLASS);

        if (className) {
            node.setAttribute(ATTRIBUTE_CLASS, className.replace(CLASSNAME_INTERNAL_LINK, ''));
        }

        return node;
    }

    /**
     * Given a set of values and an HTML node, this method sets the proper URL link.
     *
     * @param {HTMLAnchorElement} node the link element
     * @param {Object} value the value(s) to set
     */
    static setOrigin(node, value) {
        value = BstgLink.normalizeValue(value);

        if (value.linkType === LINK_TYPE_INTERNAL) {
            BstgLink.setInternalLink(node, value.doc);
        } else if (value.linkType === LINK_TYPE_EXTERNAL) {
            BstgLink.setExternalLink(node, value.url);
        }
    }

    /**
     * Given a model value this method normalizes it in order to prepare
     * it to be persisted.
     *
     * @param {Object} value the model to normalize
     *
     * @returns {Object} the normalized value
     */
    static normalizeValue(value) {
        if (isObject(value)) {
            // Depending on the package and on legacy documents,
            // we might have stored the `value` in different ways,
            // therefore we need to normalize the given value to a common
            // object in order for the links to work properly.
            if (value.doc && (value.doc.doc || value.doc.linkType)) {
                value = value.doc;
            }

            // Internal links comes with a `doc` property that points
            // to an internal document.
            if (value.fp_type) {
                value = {
                    linkType: LINK_TYPE_INTERNAL,
                    doc: value
                };
            }
        } else {
            // External links are simple objects with a type and an
            // `url` property.
            value = {
                linkType: LINK_TYPE_EXTERNAL,
                url: value
            };
        }

        return value;
    }
}

// Redefine whitelisted protocols
BstgLink.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', INTERNAL_LINK_PROTOCOL];
