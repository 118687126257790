import Alert from 'shared/ui/components/Alert.vue';

const ALERT_MODAL_NAME = 'alert-modal';

/**
 * This plugins provide a facility to create (and dismiss) common alerts.
 *
 * @example
 * vm.$alert.show('i18n.message.key')
 *
 * @global
 * @exports $alert
 */
export default {

    /**
     * Opens a modal that displays the given message.
     *
     * @param {String} message a i18n message key
     * @param {String} localizedMessage a plain text already translated message.
     * @param {Object} [opts] the options for the alert
     * @param {String} [opts.title] the alert title
     * @param {Boolean} [opts.clickToClose] whether to dispose the alert on click outside
     * @param {Array<String>} [opts.classes] alert additional classes
     * @param {Array<String>} [opts.buttonClasses] additional classes for the button
     * @param {Function} [opts.beforeOpen] callback called while alert is still invisible, but was added to the DOM
     * @param {Function} [opts.opened] callback called after alert became visible or started transition
     * @param {Function} [opts.beforeClose] callback called before alert is going to be closed. Can be stopped from the event listener calling event.stop() (example: you are creating a text editor, and want to stop closing and ask the user to correct mistakes if the text is not valid)
     * @param {Function} [opts.closed] callback called right before alert is destroyed
     */
    show(message, localizedMessage, opts = {}) {
        const noOp = () => {};

        this.$modal.show(Alert, {
            message,
            localizedMessage,
            title: opts.title,
            classes: opts.classes,
            buttonClasses: opts.buttonClasses
        }, {
            name: ALERT_MODAL_NAME,
            height: 'auto',
            clickToClose: opts.clickToClose
        }, {
            'before-open': opts.beforeOpen || noOp,
            'opened': opts.opened || noOp,
            'before-close': opts.beforeClose || noOp,
            'closed': opts.closed || noOp
        });
    },

    /**
     * Hides all alert modals.
     */
    hide() {
        this.$modal.hide(ALERT_MODAL_NAME);
    },

    install(Vue) {
        Vue.prototype.$alert = this;

        // Register the modal component.
        Vue.component('Alert', Alert);

        // Mount alert plugin when registering a new component.
        Vue.mixin({
            beforeCreate() {
                this.$alert.show = this.$alert.show.bind(this);
                this.$alert.hide = this.$alert.hide.bind(this);

                this.$on('alert', (msg, locMsg, opts) => this.$alert.show(msg, locMsg, opts));
            }
        });
    }
};
