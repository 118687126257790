import { get } from 'lodash';

export default {
    methods: {
        getOrgId(user) {
            const hasPermission = org => this.$services.user
                .hasPermission('global_actions.create_webinar', {
                    org_id: org._id
                });
            const organizations = user.orgs.filter(org =>
                org.show_studio_express && hasPermission(org)
            );

            return get(organizations, '0._id', null);
        },

        /**
         * Returns the user Templates, alphabetically sorted, and filtered by user's orgs.
         */
        async getTemplates() {
            try {
                const user = this.$services.user.getCurrentUser();
                const orgId = this.getOrgId(user);

                return await this.$services.webinar.getTemplates(orgId);
            } catch (error) {
                this.error = get(error, 'response.data.error') || error.message;
            }
        }
    },
};
