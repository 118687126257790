// Plugins
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import VueLazyload from 'vue-lazyload';
import VueCarousel from 'vue-carousel';
import VueCookies from 'vue-cookies';
import * as VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';
import Notifications from 'vue-notification';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';

// Our custom plugins
import Alert from 'shared/ui/plugins/alert';
import Tracker from 'ui/plugins/tracker';
import ModalsExt from 'shared/ui/plugins/modals-ext';

// Directives
import Browsers from 'ui/utils/directives/browsers';
import CSSSupport from 'ui/utils/directives/css-supports';

// Global components
import Loader from 'shared/ui/components/Loader.vue';
import MetaControl from 'shared/ui/components/form-components/MetaControl.vue';
import ProgressBar from 'shared/ui/components/ProgressBar.vue';
import ProgressRing from 'shared/ui/components/ProgressRing.vue';
import RouterLinkNg from 'shared/ui/components/RouterLinkNg';
import SecondaryToolbar from 'shared/ui/components/form-components/SecondaryToolbar.vue';
import CreateWorkspace from 'ui/components/wizards/CreateWorkspace.vue';

const GLOBAL_COMPONENTS = {
    'loader': Loader,
    'progress-bar': ProgressBar,
    'progress-ring': ProgressRing,
    'create-workspace': CreateWorkspace,
    'meta-control': MetaControl,
    'multiselect': Multiselect,
    'secondary-toolbar': SecondaryToolbar,
    'validation-provider': VeeValidate.ValidationProvider,
    'validation-observer': VeeValidate.ValidationObserver,
    'router-link': RouterLinkNg,
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.use(VueCookies, { expires: app.prototype.$const.DEFAULT_COOKIES_EXPIRATION_PERIOD });
        app.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });
        app.use(VTooltip, { defaultContainer: 'body', defaultHtml: false });
        app.use(Notifications);
        app.use(VueLazyload);
        app.use(VueCarousel);
        app.use(VueClipboard);
        app.use(Alert);
        app.use(Tracker);
        app.use(ModalsExt);
        app.use(PortalVue);

        app.directive('browser', Browsers);
        app.directive('css-support', CSSSupport);

        // Global registration of components
        for (const componentName of Object.keys(GLOBAL_COMPONENTS)) {
            app.component(componentName, GLOBAL_COMPONENTS[componentName]);
        }
    }
};
