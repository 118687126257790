/** @module Helpers:Datepicker */

import moment from 'moment-timezone';

/**
 * from datepicker output to date in timezone. The datepicker outputs the time
 * in the user timezone
 *
 * @param {object} output the date picker output
 * @param {string} timezone the timezone
 *
 * @returns {Number} the unix timestamp of the given date
 */
export function datePickerOutputToUnix(output, timezone) {
    return moment.tz(moment(output).format('YYYY-MM-DDTHH:mm'), timezone).unix();
}
