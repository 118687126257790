// Utils
import { addDocumentClass, removeDocumentClass } from 'libs/utils/dom';
import { isFunction } from 'lodash';

// Constants
const MODAL_OPEN_CLASS = 'modal-open';

/**
 * Since there's no option to create globals before/after callbacks, we
 * provide this feature with this decorator.
 *
 * This plugin overloads the core modal plugin `show` method by adding extra
 * global callbacks.
 *
 * NOTE: it must be `use`d after the main VModal `use`
 *
 * @private
 */
export default {
    install(Vue) {

        function handleBeforeOpen() {
            addDocumentClass(MODAL_OPEN_CLASS);
        }

        function handleBeforeClose() {
            if (document.querySelectorAll('#modals-container .v--modal-box').length === 1) {
                removeDocumentClass(MODAL_OPEN_CLASS);
            }
        }

        const originalModalShow = Vue.prototype.$modal.show;
        const overloadedModalShow = (modal, props, params, events = {}) => {
            const beforeOpen = events['before-open'];
            const beforeClose = events['before-close'];

            events['before-open'] = event => {
                if (isFunction(beforeOpen)) {
                    beforeOpen(event);
                }
                handleBeforeOpen();
            };

            events['before-close'] = event => {
                if (isFunction(beforeClose)) {
                    beforeClose(event);
                }
                handleBeforeClose();
            };

            return originalModalShow(modal, props, params, events);
        };

        Vue.prototype.$modal.show = overloadedModalShow;
    }
};
