/**
 * Utils to add an event listener to a DOM element, and return a function to remove it.
 * @param {Element | Document | Window} element
 * @param {String} event
 * @param {Function} handler
 * @returns {Function}
 */
export function addEventListener(element, event, handler) {
    element.addEventListener(event, handler);
    return () => element.removeEventListener(event, handler);
}
