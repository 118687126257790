// Utils
import * as d3 from 'd3';

/**
 * Builds a stacked bars chart with the given data
 *
 * @param {object[]} data the data for the chart
 * @param {object} options
 * @param {function} [options.x] given d in data, returns the (temporal) x-value
 * @param {function} [options.y] given d in data, returns the (quantitative) y-value
 * @param {function} [options.z] given d in data, returns the (categorical) z-value
 * @param {function} [options.j] given d in data, returns the (percentage) j-value
 * @param {function} [options.title] given d in data, returns the title text
 * @param {number} [options.marginTop = 0] top margin, in pixels
 * @param {number} [options.marginRight=16] right margin, in pixels
 * @param {number} [options.marginBottom=24] bottom margin, in pixels
 * @param {number} [options.marginLeft=24] left margin, in pixels
 * @param {number} [options.width=640] outer width, in pixels
 * @param {number} [options.height=400] outer height, in pixels
 * @param {function} [options.xType] the x-scale type
 * @param {array|Set} [options.xDomain] [xmin, xmax]
 * @param {number[]} [options.xRange] [left, right]
 * @param {number} [options.xPadding=0.1] amount of x-range to reserve to separate bars
 * @param {function} [options.yType] the y-scale type
 * @param {array} [options.yDomain] [ymin, ymax]
 * @param {number[]} [options.yRange] [bottom, top]
 * @param {string[]} [options.zDomain] array of z-values
 * @param {function} [options.offset = d3.stackOffsetDiverging], stack offset method
 * @param {function} [options.order = d3.stackOrderNone] stack order method
 * @param {function} [options.yFormat] a format specifier string for the y-axis
 * @param {string} [options.yLabel] a label for the y-axis
 * @param {strings[]} [options.colors = d3.schemeTableau10] array of colors
 * @param {number} [options.borderRadius = 8] bars border radius
 * @param {boolean|function} [options.withTooltips=true] whether to display a tooltip on the line
 * @param {number} [options.upper] the upper tier value
 * @param {number} [options.lower] the lower tier balue
 *
 * @returns {SVGElement} the chart in SVG format
 *
 * @copyright 2021 Observable, Inc.
 * @license ISC
 * @see https://observablehq.com/@d3/stacked-bar-chart
 * @modified SpotMe 01.2023
 */
export function StackedBarChart(data, {
    x = (d, i) => i, // given d in data, returns the (ordinal) x-value
    y = d => d, // given d in data, returns the (quantitative) y-value
    z = () => 1, // given d in data, returns the (categorical) z-value
    j,
    title, // given d in data, returns the title text
    marginTop = 0, // top margin, in pixels
    marginRight = 0, // right margin, in pixels
    marginBottom = 24, // bottom margin, in pixels
    marginLeft = 24, // left margin, in pixels
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    xDomain, // array of x-values
    xRange = [marginLeft, width - marginRight], // [left, right]
    yType = d3.scaleLinear, // type of y-scale
    yDomain, // [ymin, ymax]
    yRange = [height - marginBottom, marginTop], // [bottom, top]
    zDomain, // array of z-values
    offset = d3.stackOffsetDiverging, // stack offset method
    order = d3.stackOrderNone, // stack order method
    yFormat, // a format specifier string for the y-axis
    yLabel, // a label for the y-axis
    colors = d3.schemeTableau10, // array of colors,
    borderRadius = 8,
    withTooltips = true,
    upper,
    lower
} = {}) {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const Z = d3.map(data, z);
    const J = j ? d3.map(data, j) : [];
    const O = d3.map(data, d => d);

    // Compute default x- and z-domains, and unique them.
    if (xDomain === undefined) xDomain = X;
    if (zDomain === undefined) zDomain = Z;
    xDomain = new d3.InternSet(xDomain);
    zDomain = new d3.InternSet(zDomain);

    // Omit any data not present in the x- and z-domains.
    const I = d3.range(X.length).filter(i => xDomain.has(X[i]) && zDomain.has(Z[i]));

    // Compute a nested array of series where each series is [[y1, y2], [y1, y2],
    // [y1, y2], …] representing the y-extent of each stacked rect. In addition,
    // each tuple has an i (index) property so that we can refer back to the
    // original data point (data[i]). This code assumes that there is only one
    // data point for a given unique x- and z-value.
    const series = d3.stack()
        .keys(zDomain)
        .value(([, I], z) => Y[I.get(z)])
        .order(order)
        .offset(offset)(d3.rollup(I, ([i]) => i, i => X[i], i => Z[i]))
        .map(s => s.map(d => Object.assign(d, { i: d.data[1].get(s.key) })));

    // Compute the default y-domain. Note: diverging stacks can be negative.
    if (yDomain === undefined) yDomain = [0, d3.max(Y)];

    // xDomain can be an array or a set
    const xDomainLength = Array.isArray(xDomain) ? xDomain.length : xDomain.size;

    // xPadding depending on xDomain length
    const xPadding = xDomainLength < 10 ? 0.5 : (xDomainLength < 20 ? 0.3 : 0.1);

    // Construct scales, axes, and formats.
    const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = yType(yDomain, yRange);
    const color = d3.scaleOrdinal(zDomain, colors);
    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
    const yAxis = d3.axisLeft(yScale).ticks(Math.min(height / 60, d3.max(Y)), yFormat);

    if (xDomainLength > 28) {
        // only show every second label on the x axis
        xAxis.tickValues(xScale.domain().filter((_, i) => i % 2));
    }

    // Compute titles.
    if (title === undefined) {
        const formatValue = yScale.tickFormat(100, yFormat);
        title = i => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}`;
    } else {
        const T = title;
        title = i => T(O[i], i, data);
    }

    const svg = d3.create('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 12)
        .style('-webkit-tap-highlight-color', 'transparent')
        .style('overflow', 'visible');

    svg.append('defs')
        .selectAll('clipPath')
        .data(series)
        .join('clipPath')
        .attr('id', (_, i) => `round-corners-${i}`)
        .selectAll('rect')
        .data(d => d)
        .join('rect')
        .attr('rx', borderRadius)
        .attr('x', ({ i }) => xScale(X[i]))
        .attr('y', ([y1, y2]) => yScale(0) - Math.abs(yScale(y1) - yScale(y2)))
        .attr('height', ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2) + borderRadius))
        .attr('width', xScale.bandwidth());

    svg.append('g')
        .attr('transform', `translate(${marginLeft},0)`)
        .call(yAxis)
        .call(g => g.select('.domain').remove())
        .call(g => g.selectAll('.tick line').clone()
            .attr('x2', width - marginLeft - marginRight)
            .attr('stroke-opacity', 0.1))
        .call(g => g.append('text')
            .attr('x', marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel));

    const bar = svg.append('g')
        .selectAll('g')
        .data(series)
        .join('g')
        .attr('clip-path', 'url(#round-corners-0')
        .attr('fill', ([{ i }]) => color(Z[i]));

    bar.selectAll('rect')
        .data(d => d)
        .join('rect')
        .attr('class', ({ i }) => {
            if (J[i] >= upper) {
                return 'upper';
            }
            if (J[i] <= lower) {
                return 'lower';
            }
        })
        .attr('x', ({ i }) => xScale(X[i]))
        .attr('y', ([y1, y2]) => yScale(0) - Math.abs(yScale(y1) - yScale(y2)))
        .attr('height', ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2)))
        .attr('width', xScale.bandwidth());

    if (title) {
        const getTitleX = ({ i }) => xScale(X[i]) + xScale.bandwidth() / 2;
        const getTitleY = ({ 0: y1, 1: y2, i }) => {
            const h = Math.abs(yScale(y1) - yScale(y2));
            return yScale(Y[i]) + h / 2 ;
        };
        const shouldRotate = xDomainLength > 25;

        bar.selectAll('text')
            .data(d => d)
            .join('text')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('dominant-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('transform', d => {
                if (!shouldRotate) { return ''; }
                const x = getTitleX(d);
                const y = getTitleY(d);
                return `rotate(-90, ${x}, ${y})`;
            })
            .attr('x', getTitleX)
            .attr('y', getTitleY)
            .text(({ 0: y1, 1: y2, i }) => {
                const h = Math.abs(yScale(y1) - yScale(y2));
                if ((h > 16 && !shouldRotate) || (h > 36 && shouldRotate)) {
                    return title(i);
                }
            });
    }

    svg.append('rect')
        .attr('x', marginLeft)
        .attr('y', height - marginBottom)
        .attr('fill', 'transparent')
        .attr('height', borderRadius * 2)
        .attr('width', width - marginLeft - marginRight);

    svg.append('g')
        .attr('transform', `translate(0,${yScale(0)})`)
        .call(xAxis);

    let tooltip, tooltipText;
    if (withTooltips) {
        svg
            .on('pointerenter pointermove', pointermoved)
            .on('pointerleave', pointerleft)
            .on('touchstart', event => event.preventDefault(), { passive: true });

        tooltip = svg.append('g')
            .attr('class', 'point-tooltip')
            .style('pointer-events', 'none')
            .attr('opacity', 0);

        tooltipText = typeof withTooltips === 'function' ? withTooltips : title;
    }

    function pointermoved(event) {
        const p = d3.pointer(event);
        const i = Math.round(p[0] / xScale.step()) - 1;
        if (i < 0) return;

        tooltip.style('opacity', 1);
        tooltip.attr('transform', `translate(${xScale(X[i])},${yScale(0)})`);
        const rect = tooltip.selectAll('rect')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('fill', '#2d3339');

        const text = tooltip.selectAll('text')
            .attr('fill', 'white')
            // eslint-disable-next-line no-sparse-arrays
            .data([,])
            .join('text')
            .call(text => text
                .selectAll('tspan')
                .data(`${tooltipText(O[i], i, data)}`.split(/[\n|\|]/))
                .join('tspan')
                .attr('x', (_, i) => i % 2 === 1 ? 0 : null)
                .attr('y', (_, i) => {
                    if (i === 0) return null;
                    return i % 2 === 1 ? `${(i / 2) * 1.4 + .7}em` : null;
                })
                .attr('font-weight', (_, i) => i % 2 === 1 ? null : 'bold')
                .text(d => d));

        const { x, y, width: w, height: h } = text.node().getBBox();
        const barWidth = xScale.bandwidth();

        text.attr('transform', `translate(${(barWidth - w) / 2},${15 - y})`);
        rect.attr('transform', `translate(${(barWidth - w) / 2 - 8})`)
            .attr('x', x)
            .attr('y', 8)
            .attr('height', h + 16)
            .attr('width', w + 16);
    }

    function pointerleft() {
        tooltip.style('opacity', null);
        svg.node().value = null;
        svg.dispatch('input', { bubbles: true });
    }

    return Object.assign(svg.node(), { scales: { color } });
}
