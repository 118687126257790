// Components
import EventBadge from 'ui/components/EventBadge.vue';
import Card from 'shared/ui/components/Card.vue';

/**
 * Given a workspace this component renders the proper card, being it a template or an event.
 *
 * @vue-prop {Object} workspace the workspace to render the card for
 * @vue-prop {String} uidPrefix the prefix to use for analytics targeting
 *
 * @category ui.mixins.cards
 * @exports WorkspaceCard
 */
export default {
    components: {
        EventBadge,
        Card,
    },

    props: {
        workspace: {
            type: Object,
            required: true
        },

        uidPrefix: {
            type: String,
            required: true
        },

        selected: {
            type: Boolean,
            default: false
        },

        selectable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const { start_time, end_time } = (this.workspace.description || this.workspace);
        const time = this.workspace.current_stage === 'past' ? end_time : start_time;

        return {
            link: this.$services.event.getEventUrl(this.workspace),
            title: this.$utils.workspaces.getWorkspaceTitle(this.workspace, this.$i18n.locale),
            uid: `${this.uidPrefix}-${this.workspace.id || this.workspace._id}`,
            subtitle: this.$i18n.t(`events.stages.${this.workspace.current_stage}`, [this.$utils.time.timeFromNow(time)])
        };
    },

    methods: {
        /**
         * Returns the tooltip config
         *
         * @param {String} content the tooltip string to display
         *
         * @return {Object|null} the config
         */
        getTooltipConfig(content) {
            return {
                content: content,
                delay: { show: 2000, hide: 300 }
            };
        }
    }
};
