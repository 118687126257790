import * as constants from 'libs/utils/constants';

// Live Session Stores
import liveStream from 'shared/stores/live-session/live-stream';
import vod from 'shared/stores/live-session/vod';

let storeServices = {};

export default (utils) => {

    return {
        namespaced: true,
        state: {},
        actions: {
            setServices: (_ctx, { services, app }) => {
                storeServices = services;
                console.info('[StudioStore]: biding $services for', app);
            }
        },
        modules: {
            liveStream: liveStream(() => storeServices, constants, utils),
            vod: vod(() => storeServices, constants, utils),
        }
    };
};
