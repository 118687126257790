// Utils
import { get } from 'lodash';
import { interpolateUrl } from 'libs/utils/url';

/**
 * Get the named route from a module.
 *
 * @param {Object} module - The module object.
 * @param {string} name - The name of the route.
 * @param {function} [matcher] the matcher to use to find the route.
 *
 * @returns {string} The route.
 */
export function getNamedRoute(module, name, matcher) {
    const path = `controllers.${name}.route`;
    const routes = get(module, path, '');

    let route = routes;

    if (Array.isArray(routes)) {
        if (typeof matcher === 'function') {
            route = matcher(routes) || routes[0];
        } else {
            // return the first route
            route = routes[0];
        }
    }

    return normalizeRoute(route);
}

/**
 * Replaces dynamic route parameters with placeholders.
 *
 * @param {string} route - The route string with dynamic parameters.
 *
 * @returns {string} The normalized route string with placeholders.
 */
export function normalizeRoute(route) {
    // Sometimes the route parameter for the model ID is named
    // difrently than :modelId, so we need to replace it.
    const specialIdMatcherCases = [':participantId'];

    return route.replaceAll(/:[^/]+/g, match => {
        const idMatcher = ':modelId';

        if (specialIdMatcherCases.includes(match)) {
            match = idMatcher;
        }

        const replacement = match === idMatcher ? '_id' : match.slice(1);
        return `{{${replacement}}}`;
    });
}

/**
 * Get the href for a named route.
 *
 * @param {Object} module - The module object.
 * @param {string} name - The name of the route.
 * @param {Object} replacements - The replacements for the route.
 * @param {function} [matcher] the matcher to use to find the route.
 *
 * @returns {string} The href for the named route.
 */
export function getRouteHref(module, name, replacements, matcher) {
    const namedRoute = getNamedRoute(module, name, matcher);
    return interpolateUrl(namedRoute, replacements);
}
