// Utils
import { get } from 'lodash';

export default {

    data() {
        return {
            defaultFileName: 'file',
            disableSubmit: false,
            downloadPath: null,
            error: null,
            polling: false,
            progress: 0,
            showDownloadButton: false
        };
    },

    watch: {
        downloadPath() {
            this.downloadReport();
        }
    },

    methods: {
        async downloadReport() {
            try {
                await this.$utils.browser.downloadFromUrl(this.downloadPath, this.defaultFileName);
                this.$emit('close');
            } catch (error) {
                console.error('[ExportModal] Could not auto download file', error);
                this.showDownloadButton = true;
            } finally {
                this.disableSubmit = false;
            }
        },

        /**
         * Handles export form errors
         *
         * @param {import('axios').AxiosError} error
         */
        handleError(error) {
            console.error(`[${this.$options.name}] An error occurred`, error);
            this.error = get(error, 'response.data.error', error.message || this.$t('contents.messages.export_failed'));
        },

        /**
         * Tracks the export job
         *
         * @param {object} jobId the background job to track for the progress
         * @param {string} [statusPath] the path to get the task status from
         * @param {string} [resultPath] the path to get the task result from
         */
        async trackJob(jobId, statusPath, resultPath) {
            try {
                console.debug(`[${this.$options.name}] Export task received, start tracking`, jobId);
                this.polling = true;

                const result = await this.$services.jobs.waitFor({
                    jobId, statusPath, resultPath,
                    updateUIProgress: (p) => this.progress = p,
                    rejectsOnErrors: true,
                    interval: 1000,
                    minutesToTimeout: 10
                });

                console.info(`[${this.$options.name}] Export completed`, result);
            } finally {
                this.polling = false;
            }
        }
    }
};
