/** @module Numbers */

/**
 * Ensures that the returned value is a number.
 * In case the given value is not a valid number, the fallback value is returned instead
 *
 * @param {number|string} value the number to ensure
 * @param {number} [fallbackValue=0] an optional fallback value (default 0)
 *
 * @return {number} the given value or the fallback value, never null, never undefined
 */
export function ensureNumberValue(value, fallbackValue = 0) {
    const number = Number.parseFloat(value);
    if (Number.isFinite(number)) return number;

    return ensureNumberValue(fallbackValue);
}

/**
 * calculate the average of an array of numbers
 * any non-numeric values in the array will be ignored
 *
 * @param {number[]} arr
 * @returns {number}
 */
export function averageFromArray(arr) {
    return arr
        .filter(item => !isNaN(item))
        .reduce((acc, curr, i) =>
            acc + (curr - acc) / (i + 1),
        0);
}

/**
 * Finds the quantile in the given array of numbers
 *
 * @param {number[]} arr the array to get the quantil from
 * @param {number} [q=.5] the desired quantile
 *
 * @returns {number} the quantile of the array
 */
export function quantil(arr, q = 0.5) {
    const arrayCopy = [...arr];
    arrayCopy.sort((a, b) => a - b);

    const pos = (arrayCopy.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (arrayCopy[base + 1] !== undefined) {
        return arrayCopy[base] + rest * (arrayCopy[base + 1] - arrayCopy[base]);
    } else {
        return arrayCopy[base];
    }
}

/**
 * Gets the median number in the given array
 *
 * @param {number[]} arr the array to get the median for
 *
 * @returns {number} the median value in the array
 */
export function median(arr) {
    return quantil(arr, .5);
}

/**
 * Format bytes as human-readable text.
 *
 * @param {number} bytes Number of bytes.
 * @param {boolean} [si=false] True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param {number} [dp=1] Number of decimal places to display.
 *
 * @return {string} Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
}

/**
 * Given total and partial this method returns the rounded
 * percentage
 *
 * @param {number} total the full quantity
 * @param {number} partial the current quantity
 *
 * @returns {string} the calculated percentage
 */
export function percentage(total, partial) {
    if (!total || !partial) { return '0%'; }
    return `${Math.round((partial / total) * 100)}%`;
}

/**
 * Given two numbers, this method checks if the first member falls within the
 * range of tolerance of the second member
 *
 * @param {number} a the first member of the check
 * @param {*} b the second member of the check
 * @param {*} [tolerance=10] an optional tolerance for the range check
 *
 * @returns {boolean} true if `a` falls within `tollerance` range from `b`
 */
export function inRange(a, b, tolerance = 10) {
    return Math.abs(a - b) <= tolerance;
}
