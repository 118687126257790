import Vuex from 'vuex';

// Stores
import uploads from 'shared/stores/uploads';
import liveSession from './stores/live-session';
import metadataEditor from './stores/metadata-editor';

/** @param {import('vue').VueConstructor} app */
export function createStore(app) {
    app.use(Vuex);

    const store = new Vuex.Store({
        strict: process.env.NODE_ENV !== 'production',
        devtools: process.env.NODE_ENV !== 'production',
        plugins: ['development', 'dev', 'aws-dev'].includes(process.env.NODE_ENV) ? [Vuex.createLogger()] : [],
        modules: {
            uploads,
            liveSession: liveSession(app.prototype.$utils),
            metadataEditor,
        }
    });

    return store;
}
