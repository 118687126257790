export default `
const previewSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAAAklEQVR4AewaftIAAATCSURBVO3BgY0jRxAEwawG/Xe59AZMQxhheeS9MiL9A0lHg6TVIGk1SFoNklaDpNUgaTVIWg2SVoOk1SBpNUhaDZJWg6TVIGk1SFoNklaDpNUgaTVIWg2SVoOk1SBpNUhaDZJWL35AEn6Dtpwk4aQtn5KEJ7TlJAm/QVveaZC0GiStBkmrQdJqkLR68UFt+YQk3GjLjSSctOUkCU9py0kSntCWT0jCJwySVoOk1SBpNUhaDZJWL75QEp7Qlick4UZbntKWkyTcaMs7JeEJbfkmg6TVIGk1SFoNklaDpNUL/au2nCThRhJO2vJuSThpi3aDpNUgaTVIWg2SVoOk1Qv9Z205ScKNJLxbW3RvkLQaJK0GSatB0mqQtHrxhdryTZLwTm15tySctOUJbfkbDZJWg6TVIGk1SFoNklYvPigJv1lbTpJw0paTJGzacpKEk7a8UxL+TwZJq0HSapC0GiStBkmrFz+gLb9ZW06ScCMJv0VbBIOk1SBpNUhaDZJWg6TVix+QhJO2nCThpC1PSMKNttxoy1OScCMJ75SEk7bcSMJJW06ScNKWdxokrQZJq0HSapC0GiSt0j/4JZJwoy03knCjLU9Iwqe05SQJJ215pyTcaMs7DZJWg6TVIGk1SFoNklYvfkASTtpykoQbbbmRhBttOUnCSVtOknCrLTeScNKWG215QhJutOUkCZ8wSFoNklaDpNUgaTVIWr34RdpyIwknbXlCW2605SQJmySctOWkLTeScNKWkySctOVGW36DQdJqkLQaJK0GSatB0urFL5KEG205ScIT2nKShG+ThJO2nCThpC032nIjCd9kkLQaJK0GSatB0mqQtBokrdI/+DJJOGnLSRJO2nIjCSdtuZGEk7acJGHTlhtJ+IS2/I0GSatB0mqQtBokrQZJqxc/IAnv1JYbSThpy40knLTlKUn4Jm05ScKNttxIwklb3mmQtBokrQZJq0HSapC0evED2vKEJDyhLSdJeKck3GrLSRKe0JYbSThpy0kSntCWTxgkrQZJq0HSapC0GiSt0j94syS8U1tOkvAJbXlKEv5GbbmRhJO2vNMgaTVIWg2SVoOk1SBp9eIHtOUT2vKEJPyt2vKEJLxTWz5hkLQaJK0GSatB0mqQtHrxA5LwG7TlCUm40ZZNW56QhBtJOGnLjbacJOGkLSdJOGnLOw2SVoOk1SBpNUhaDZJWLz6oLZ+QhBttuZGEk7bcSsJJW06S8IS2PCEJJ205ScJJWz5hkLQaJK0GSatB0mqQtHrxhZLwhLa8UxJuJOHbJOGd2vKEJJy05Z0GSatB0mqQtBokrQZJqxd6XFtuJGHTlie05SQJJ225kYSTJJy05UZbPmGQtBokrQZJq0HSapC0eqF/lYSTtrxbEm605SQJJ235DZJw0pZ3GiStBkmrQdJqkLQaJK1efKG2fJO2nCTh3dpykoRPSMJJW06ScKMt32SQtBokrQZJq0HSapC0evFBSfgNknCjLU9Jwjsl4aQt3yQJJ215p0HSapC0GiStBkmrQdIq/QNJR4Ok1SBpNUhaDZJWg6TVIGk1SFoNklaDpNUgaTVIWg2SVoOk1SBpNUhaDZJWg6TVIGk1SFoNklaDpNUgaTVIWv0DiXGMmxHR8xkAAAAASUVORK5CYII='

const getQrCode = src => [
    '<div class="qr-tool" style="margin:auto;text-align:center">',
        '<img src="' + src + '" />',
    '</div>',
].join('')

unlayer.registerTool({
  name: 'qr',
  label: 'User QR',
  icon: 'fa-qrcode',
  supportedDisplayModes: ['email'],
  options: {},
  values: {},
  renderer: {
    Viewer: unlayer.createViewer({
        render() { return getQrCode(previewSrc) },
    }),
    exporters: {
      email: function () { return getQrCode('{{_qr_code_url}}'); },
    },
    head: {
      css: function () {},
      js: function () {},
    },
  },
})`;
