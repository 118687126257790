/**
 * This mixin is used to handle thumbnails
 *
 * @category content-hub.mixins
 * @exports Thumbnail
 */
import { get } from 'lodash';

export default {

    methods: {
        /**
         * Try to upload a thumbnail
         * @param {Object} asset
         * @param {string} eventId
         * @param {string} contentId
         * @returns {Promise<boolean>} True if the upload was done, false otherwise
         */
        async thumbnailUpload(asset, eventId, contentId) {
            const file = get(asset, '0.file', {});
            if (!file.size) {
                return false;
            }
            try {
                console.debug('[ThumbnailMixin] Uploading thumbnail');
                await this.$services.content.saveContentThumbnail(eventId, contentId, file);
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('contents.messages.thumbnail_upload_success').toString(),
                    type: 'success',
                    duration: 5000
                });
                return true;
            } catch (err) {
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('contents.messages.thumbnail_upload_error').toString(),
                    type: 'error',
                    duration: 5000
                });
                return false;
            }
        },

        /**
         * Try to delete a thumbnail
         * @param {string} eventId
         * @param {string} contentId
         * @returns {Promise<boolean>} True if the deletion was done, false otherwise
         */
        async thumbnailDeletion(eventId, contentId) {
            try {
                console.debug('[ThumbnailMixin] Deleting thumbnail');
                this.$services.content.deleteThumbnail(eventId, contentId);
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('contents.messages.thumbnail_deletion_success').toString(),
                    type: 'success',
                    duration: 5000
                });
                return true;
            } catch (err) {
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('contents.messages.thumbnail_deletion_error').toString(),
                    type: 'error',
                    duration: 5000
                });
                return false;
            }
        }
    }
};
