export default {
    props: {
        currentController: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        module: {
            type: Object,
            required: true
        },

        user: {
            type: Object,
            required: true
        },

        registry: {
            type: Object,
            required: true
        },

        settings: {
            type: Object,
            required: true
        }
    },

    watch: {
        settings: {
            deep: true,
            immediate: true,
            handler() {
                this.init();
            }
        }
    },

    methods: {
        init() {
            throw new Error('init method is not implemented');
        }
    }
};
