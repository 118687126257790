export default {
    props: {
        event: {
            type: Object,
            required: true
        },

        action: {
            type: Object,
            default: () => ({})
        },

        item: {
            type: Object,
            default: () => ({})
        }
    }
};
