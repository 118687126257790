export default {
    data() {
        return {
            submenuOpen: false,
            minified: this.$cookies.get(this.$const.MENU_STATE_MINIFIED) === 'true',
            animating: false
        };
    },

    watch: {
        minified: {
            immediate: true,
            handler() { this.$utils.dom.toggleDocumentClass(this.$const.MENU_STATE_MINIFIED, this.minified); }
        }
    },

    methods: {
        /**
         * Restores the main menu minified state from the store.
         */
        restoreMenuState() {
            const cookieMinified = this.$cookies.get(this.$const.MENU_STATE_MINIFIED) === 'true';

            if (cookieMinified !== this.minified) {
                this.toggleMenuState(false);
            }
        },

        /**
         * Persists menu state
         */
        storeMenuState() {
            const expires = this.$utils.time.daysFromNow(2);

            this.$cookies.set(this.$const.MENU_STATE_MINIFIED, this.minified, expires, '/', this.$utils.url.getTopLevelDomain());
        },

        /**
         * Sets the submenus states
         *
         * @param {Boolean} open whether the submenu is open or not
         */
        setSubmenuState(open) {
            this.submenuOpen = open;

            if (this.submenuOpen && this.minified) {
                // Temporary maximise the menu to allow the user to select
                // a submenu item.
                this.toggleMenuState();
            }
        },

        /**
         * Toggles the minified state to the main menu.
         *
         * @param {Boolean} [store=false] whether to persist the menu state
         */
        toggleMenuState(store = false) {
            this.minified = !this.minified;

            this.animating = true;
            setTimeout(() => this.animating = false, 200);

            if (store) {
                this.storeMenuState();
            }
        }
    }
};
