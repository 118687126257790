/** @module Browser */

// Utils
import mimeDb from 'mime-db';

/**
 * @typedef {Object} BrowserInfo
 *
 * @property {String} downloadUrl the URL from which to download the browser
 * @property {String} logo the relative path to the browser's logo
 * @property {String} name the browser's name
 * @property {String} company the browser's company
 */

const browserInfos = {
    'chrome': {
        downloadUrl: 'https://www.google.com/chrome',
        logo: 'supported-browsers/chrome.png',
        name: 'Chrome',
        company: 'Google'
    },
    'edge': {
        downloadUrl: 'https://microsoft.com/edge',
        logo: 'supported-browsers/edge.png',
        name: 'Edge',
        company: 'Microsoft'
    },
    'opera': {
        downloadUrl: 'https://www.opera.com/download',
        logo: 'supported-browsers/opera.png',
        name: 'Opera',
        company: 'Opera'
    },
    'firefox': {
        downloadUrl: 'https://www.mozilla.org/firefox/download',
        logo: 'supported-browsers/firefox.png',
        name: 'Firefox',
        company: 'Mozilla'
    },
    'brave': {
        downloadUrl: 'https://brave.com/download',
        logo: 'supported-browsers/brave.png',
        name: 'Brave',
        company: 'Brave'
    }
};

/**
 * Enum object that includes all the browsers
 */
export const browsers = {
    CHROME: 'chrome',
    EDGE: 'edge',
    OPERA: 'opera',
    FIREFOX: 'firefox',
    BRAVE: 'brave'
};

/**
 * Obtains information about a browser
 *
 * @param {String} browserName name of the browser
 * @returns {BrowserInfo} information on the browser
 */
export function browserInfo(browserName) {
    return browserInfos[browserName];
}

/**
 * Checks wether the browser is based con Chrome or not.
 *
 * Performs the browser checking based on the parsing of the navigator's user agent string.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 *
 * There are other solutions based on feature detection (e.g., the one from StackOverflow
 * referenced below) that aim to prevent the tampering of the user agent string. However,
 * they are more susceptible to break as future browser versions are released.
 *
 * @see https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
 *
 * @returns {boolean} whether the current browser is based con Chrome
 */
export function isChromeBased(userAgent = navigator.userAgent) {
    return userAgent.includes('Chrome/');
}

/**
 * Given an URL this methods instruct the browser to download the
 * content
 *
 * @param {string} url the URL to download the file from
 * @param {string} [filename='file'] optional filename if content disposition header is not present
 */
export async function downloadFromUrl(url, filename = 'file') {
    const response = await fetch(url);
    const cd = response.headers.get('Content-Disposition');

    if (cd?.startsWith('attachment')) {
        // Filename may contain ";" and UTF8 character
        // This regexp account for this.
        // For a full explanation of the regex see here: https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(cd);

        if (matches?.[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    } else {
        const type = response.headers.get('Content-Type');
        if (type) {
            const ext = mimeDb[type]?.extensions?.[0];
            filename = `${filename}.${ext}`;
        }
    }

    const blob = await response.blob();

    downloadBlob(blob, decodeURIComponent(filename));
}

/**
 * Downloads a file starting from its blob content
 *
 * @param {Blob} blob the blob of the file
 * @param {string} filename the name of the file to download
 * @param {boolean} [autodownload=true] when true the download will begin automatically
 *
 * @returns {HTMLElement} the anchor element from where the download starts
 */
export function downloadBlob(blob, filename, autodownload = true) {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement('a');

    a.href = url;
    a.download = filename || 'download';

    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            removeEventListener('click', clickHandler);
        }, 150);
    };

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false);

    if (autodownload) {
        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        a.click();
    }

    // Return the anchor element
    // Useful if you want a reference to the element
    // in order to attach it to the DOM or use it in some other way
    return a;
}
