// Plugins
import Utils from 'shared/ui/plugins/utils';

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app, options) => {
        app.use(Utils);

        options.globalHandle.utils = app.prototype.$utils;
    }
};
